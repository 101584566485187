const Cart = {
    AddToCart: (courseID, updateCount, start) => {
        let chaCartJSON = localStorage.getItem('chaCart');
        let chaCart = [];
        let itemAdded = false;
        let deleteCount = 0;
        if (chaCartJSON !== null) {
            chaCart = JSON.parse(chaCartJSON);
        }
        if (chaCart.indexOf(courseID) === -1) {
            let possibleCoachingCourseID = courseID;
            if (typeof courseID === 'string') {
                if (courseID.indexOf('-') !== -1) {
                    const itemSplit = courseID.split('-');
                    possibleCoachingCourseID = parseInt(itemSplit[0]);
                } else if (courseID.length && courseID.indexOf('s') === courseID.length -1) {
                    possibleCoachingCourseID = parseInt(courseID.substring(0, courseID.length -1));
                }
            }
            for (let i = 0; i < chaCart.length; i ++) {
                const chaItem = chaCart[i];
                let chaItemCourseID = chaItem;
                if (typeof chaItem === 'string') {
                    if (chaItem.indexOf('-') !== -1) {
                        const chaItemSplit = chaItem.split('-');
                        chaItemCourseID = parseInt(chaItemSplit[0]);
                    } else if (chaItem.length && chaItem.indexOf('s') === chaItem.length -1) {
                        chaItemCourseID = parseInt(chaItem.substring(0, chaItem.length -1));
                    }
                }
                if (chaItemCourseID === possibleCoachingCourseID) {
                    start = i;
                    deleteCount = 1;
                }
            }
            if (start !== undefined) {
                chaCart.splice(start, deleteCount, courseID);
            } else {
                chaCart.push(courseID);
            }
            itemAdded = true;
        }
        localStorage.setItem('chaCart', JSON.stringify(chaCart));
        updateCount();
        return itemAdded;
    },
    CartItem: (item, updateCount, cart, setForceUpdate) => {
        let coachingItem = null;
        let courseCoachingID = item.courseID;
        const coachingPackage = item.coachingPackage;
        if (coachingPackage !== null) {
            courseCoachingID = item.courseID + '-' + coachingPackage.id;
            coachingItem = <div className={"cart-item coaching-cart-item"} key={"cart-item-" + courseCoachingID}>
                <img src={process.env.REACT_APP_IMG_PATH + cart.cartData.coachingImage.url} alt={coachingPackage.name} />
                <div className={"remove-item"} onClick={() => {
                    Cart.RemoveFromCart(
                        'coaching-' + courseCoachingID,
                        updateCount,
                        cart
                    );
                    setForceUpdate(Date.now());
                }}>
                    <i className={"fa fa-times"}>&nbsp;</i>
                </div>
                <div className={"item-name"}>
                    <a href = {process.env.REACT_APP_EXTERNAL_PATH + '/coaching'}>{coachingPackage.name}</a>
                    <div className={"number-sessions"}>({coachingPackage.numberSessions} x 45 mins)</div>
                </div>
                <div className={"item-details"}>
                    <div className={"cost"}>Cost: &#36;{coachingPackage.cost}</div>
                </div>
            </div>
        }
        return <div className={'cart-item-container'} key={"cart-item-container-" + item.courseID}>
            <div className={"cart-item"} key={"cart-item-" + item.courseID}>
                <img src={process.env.REACT_APP_IMG_PATH + item.image.url} alt={item.courseName} />
                <div className={"remove-item"} onClick={() => Cart.RemoveFromCart(courseCoachingID, updateCount, cart)}>
                    <i className={"fa fa-times"}>&nbsp;</i>
                </div>
                <div className={"item-name"}>
                    <a href = {process.env.REACT_APP_EXTERNAL_PATH + '/courses/' + item.url}>{item.courseName}</a>
                </div>
                <div className={"item-details"}>
                    <div className={"cost"}>Cost: &#36;{item.cost}</div>
                </div>
            </div>
            {coachingItem}
        </div>
    },
    EmptyCart: (updateCount) => {
        localStorage.removeItem('chaCart');
        updateCount();
    },
    GetCart: () => {
        return localStorage.getItem('chaCart');
    },
    GetCartCount: () => {
        let count = 0;
        let chaCartJSON = localStorage.getItem('chaCart');
        if (chaCartJSON !== null) {
            const chaCart = JSON.parse(chaCartJSON)
            count = chaCart.length;
        }
        return count;
    },
    RemoveFromCart: (courseID, updateCount, cart) => {
        let chaCartJSON = localStorage.getItem('chaCart');
        let chaCart = [];
        if (chaCartJSON !== null) {
            chaCart = JSON.parse(chaCartJSON)
        }
        let itemSplit;
        if (chaCart.indexOf(courseID) === -1) {
            if (typeof courseID === 'string' && courseID.indexOf('coaching-') !== -1) {
                courseID = courseID.substr(9);
                itemSplit = courseID.split('-');
            }
        }
        let start;
        if (chaCart.indexOf(courseID) !== -1) {
            start = chaCart.indexOf(courseID);
            chaCart.splice(start, 1);
            if (itemSplit === undefined) {
                cart.cartData.items.splice(start, 1);
            } else {
                cart.cartData.items[start].coachingPackage = null;
            }
            cart.cartData.total = 0;
            for (let i = 0; i < cart.cartData.items.length; i++) {
                const item = cart.cartData.items[i];
                cart.cartData.total += item.cost;
                if (item.coachingPackage !== null) {
                    cart.cartData.total += item.coachingPackage.cost;
                }
            }
        }
        localStorage.setItem('chaCart', JSON.stringify(chaCart));
        if (itemSplit !== undefined) {
            Cart.AddToCart(parseInt(itemSplit[0]), updateCount, start);
        }
        cart.setCartData(cart.cartData);
        updateCount();
    },
    UpdateCartCount: () => {
        return Cart.GetCartCount();
    }
}

export default Cart;
