import React from 'react';
import { Rolling } from 'react-loading-io';

const Loader = (props) => {
    const size = props.size !== undefined ? props.size : 100;
    const estilo = props.style !== undefined ? props.style : {};
    return (
        <div className={"loader-container"} style={estilo}>
            <Rolling color={"#0d8577"} size={size} />
        </div>
    );
}

export default Loader;