import React from 'react';
import {Sitemap} from "../components/Sitemap";
import Container from "react-bootstrap/Container";
import Metadata, {MetadataObj} from "../components/Metadata";

const NotFound = () => {
    let metadata = MetadataObj();
    metadata.PageTitle = 'Page not found';
    return (
        <Container className={"not-found"}>
            <Metadata metadata={metadata}/>
            <h1>404 - Page not found</h1>
            <h2>Sorry, the page you're looking for has moved or been deleted.</h2>
            <div>If you are looking for Clear Horizon resources and tools they are now located in
                our <a href={process.env.REACT_APP_EXTERNAL_PATH + '/community'}>community platform</a>.</div>
            <div className={"mt-2"}>If you are trying to access course content please be advised that access to course content expires
                after six months. We are happy to provide you with a PDF version.
                Please <a href={process.env.REACT_APP_EXTERNAL_PATH + '/contact-us'}>contact us</a> with your request.
            </div>
            <div className={"mt-2"}>Otherwise, please try one of the links below.</div>
            <Sitemap/>
        </Container>
    );
}

export default NotFound;