import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {loadStripe} from '@stripe/stripe-js';
import {AlertMissingDetails, FetchData} from "../Helpers";
import Loader from "./Loader";
import {Redirect} from "react-router-dom";
import {useAlert} from "react-alert";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CheckoutButton = (props) => {
    const url = btoa(window.location.href);
    const [discount, setDiscount] = useState(false);
    const alert = useAlert();
    const loadCheckout = async () => {
        if (props.discount.discount.cha && parseInt(props.discount.discount.discount) === 100) {
            setDiscount(true);
        } else {
            props.loadingCheckout.setLoadingCheckout(true);
            const stripe = await stripePromise;
            const session = await FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/cart/getsession/'
                + props.chaCart + '/' + url, 'POST', null, props.formData);
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                props.loadingCheckout.setLoadingCheckout(false);
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            }
        }
    };
    if (props.loadingCheckout.loadingCheckout) {
        return <Loader size={62} />;
    } else if (discount) {
        return (
            <Redirect
                to={{
                    pathname: "/discount-success",
                    state: {
                        discount: props.discount.discount,
                        formData: props.formData,
                        chaCart: props.chaCart
                    }
                }}
            />
        )
    } else if (!props.disabled && !props.loadingInvoice.loadingInvoice) {
        return (
            <Button className={"btn btn-primary"} onClick={loadCheckout}>Pay by card</Button>
        );
    } else {
        return (
            <Button className={"btn btn-primary disabled"} onClick={() => AlertMissingDetails(alert)}>Pay by card</Button>
        );
    }
}

export default CheckoutButton;