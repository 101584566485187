import React, {useEffect, useReducer} from "react";
import {
    Switch,
    Route, Redirect, useLocation,
} from "react-router-dom";
import './App.scss';
import {HomePage} from './pages/Home';
import {CoursePage} from './pages/course/Course';
import {FAQsPage} from "./pages/FAQs";
import RegistrationForm from './pages/Register';
import CoachingRegistrationForm from './pages/coaching/Register';
import PreviewPage from './pages/PreviewPage';
import {Menu} from './components/Menu';
import Footer from './components/Footer';
import CartContext from "./contexts/CartContext";
import Cart from "./components/Cart";
import {PaymentSuccessPage} from "./pages/cart/PaymentSuccess";
import {CheckoutPage} from "./pages/cart/Checkout";
import ContactPage from "./pages/Contact";
import {Post} from "./pages/blog/Post";
import {BlogListPage} from "./pages/blog/List";
import {InfoPage} from "./pages/InfoPage";
import {DiscountSuccessPage} from "./pages/cart/DiscountSuccess";
import {OrganisationsPage} from "./pages/Organisations";
import {CoachingPage} from "./pages/coaching/Coaching";
import UpdateDetailsForm from "./pages/UpdateDetails";
import RedirectPage from "./pages/RedirectPage";
import {CourseCalendarPage} from "./pages/course/Calendar";
import ReferralForm from "./pages/Referrals";
import ThankYou from "./pages/ThankYou";


const App = () => {
    const cartCount = Cart.GetCartCount();
    const [count, updateCount] = useReducer(Cart.UpdateCartCount, cartCount);
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 500);
        }
    }, [pathname, hash]);

    return <CartContext.Provider value={[count, updateCount]}>
        <Menu />
        <Switch>
            <Route path="/contact-us">
                <ContactPage />
            </Route>
            <Route path="/register-your-interest">
                <RegistrationForm />
            </Route>
            <Route path="/preview/:contentType">
                <PreviewPage />
            </Route>
            <Route path="/my-courses" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/courses');
                return null;
            }} />
            <Route path="/courses/course-mel-online-full" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/complete-guide-to-measurement-evaluation-and-learning');
                return null;
            }} />
            <Route path="/courses/course-mel-module-1" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/introduction-to-measurement-evaluation-and-learning-mel');
                return null;
            }} />
            <Route path="/courses/course-mel-module-2" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/measuring-impact');
                return null;
            }} />
            <Route path="/courses/course-mel-module-3" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/evaluation');
                return null;
            }} />
            <Route path="/courses/course-mel-module-4" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/increasing-impact-through-learning');
                return null;
            }} />
            <Route path="/courses/course-tocd-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/applied-theory-of-change');
                return null;
            }} />
            <Route path="/courses/developmental-evaluation" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/developmental-evaluation');
                return null;
            }} />
            <Route path="/courses/course-pba-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/evaluating-systems-change-place-based-approaches');
                return null;
            }} />
            <Route path="/courses/course-msc-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/most-significant-change-course');
                return null;
            }} />
            <Route path="/courses/course-pbi-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/using-power-bi-for-impact');
                return null;
            }} />
            <Route path="/courses/:slug">
                <CoursePage />
            </Route>
            <Route path="/course-calendar">
                <CourseCalendarPage />
            </Route>
            <Route path="/for-organisations">
                <OrganisationsPage />
            </Route>
            <Route path="/coaching">
                <CoachingPage />
            </Route>
            <Route path="/register-your-interest-coaching">
                <CoachingRegistrationForm />
            </Route>
            <Route path="/blog/category/:category">
                <BlogListPage />
            </Route>
            <Route path="/blog/tag/:tag">
                <BlogListPage />
            </Route>
            <Route path="/blog/:slug">
                <Post />
            </Route>
            <Route path="/blog">
                <BlogListPage />
            </Route>
            <Route path="/blog-live-preview">
                <BlogListPage />
            </Route>
            <Route path="/checkout">
                <CheckoutPage />
            </Route>
            <Route path="/payment-success/:sessionID">
                <PaymentSuccessPage />
            </Route>
            <Route path="/discount-success">
                {(props) => <DiscountSuccessPage {...props} />}
            </Route>
            <Route path="/faqs">
                <FAQsPage />
            </Route>
            <Route path="/our-approach">
                <InfoPage />
            </Route>
            <Route path="/our-learners">
                <InfoPage />
            </Route>
            <Route path="/our-work">
                <InfoPage />
            </Route>
            <Route path="/experts">
                <InfoPage />
            </Route>
            <Route path="/privacy">
                <InfoPage />
            </Route>
            <Route path="/terms-and-conditions">
                <InfoPage />
            </Route>
            <Route path="/code-of-conduct">
                <InfoPage />
            </Route>
            <Route path="/mentoring">
                <CoachingPage />
            </Route>
            <Route path="/resources/:course/:redirectURL">
                <RedirectPage />
            </Route>
            <Route path="/resources">
                <InfoPage />
            </Route>
            <Route path="/community">
                <InfoPage />
            </Route>
            <Route path="/community-privacy">
                <InfoPage />
            </Route>
            <Route path="/community-code-of-conduct">
                <InfoPage />
            </Route>
            <Route path="/update-your-details">
                <UpdateDetailsForm />
            </Route>
            <Route path="/referrals">
                <ReferralForm />
            </Route>
            <Route path="/thank-you">
                <ThankYou />
            </Route>
            <Route path="/about">
                <Redirect status={301} from="/about" to="/our-approach" />
            </Route>
            <Route path="/sense-making-through-stories-an-introduction-to-most-significant-change">
                <Redirect status={301} from="/sense-making-through-stories-an-introduction-to-most-significant-change"
                          to="/blog/sense-making-through-stories-an-introduction-to-most-significant-change" />
            </Route>
            <Route path="/2020-clarity-the-year-to-get-clear-on-the-change-you-make">
                <Redirect status={301} from="/2020-clarity-the-year-to-get-clear-on-the-change-you-make"
                          to="/blog/2020-clarity-the-year-to-get-clear-on-the-change-you-make" />
            </Route>
            <Route path="/choose-your-own-adventure-navigating-shared-measurement-and-evaluation-in-place-based-approaches">
                <Redirect status={301} from="/choose-your-own-adventure-navigating-shared-measurement-and-evaluation-in-place-based-approaches"
                          to="/blog/choose-your-own-adventure-navigating-shared-measurement-and-evaluation-in-place-based-approaches" />
            </Route>
            <Route path="/the-north-star-for-achieving-community-impact-why-place-based-and-systems-approaches-are-here-to-stay">
                <Redirect status={301} from="/the-north-star-for-achieving-community-impact-why-place-based-and-systems-approaches-are-here-to-stay"
                          to="/blog/the-north-star-for-achieving-community-impact-why-place-based-and-systems-approaches-are-here-to-stay" />
            </Route>
            <Route path="/a-program-logic-can-paint-a-thousand-words">
                <Redirect status={301} from="/a-program-logic-can-paint-a-thousand-words"
                          to="/blog/a-program-logic-can-paint-a-thousand-words" />
            </Route>
            <Route path="/developing-delivering-and-iterating-a-new-learning-product">
                <Redirect status={301} from="/developing-delivering-and-iterating-a-new-learning-product"
                          to="/blog/developing-delivering-and-iterating-a-new-learning-product" />
            </Route>
            <Route path="/change-is-here-and-so-are-the-robots">
                <Redirect status={301} from="/change-is-here-and-so-are-the-robots"
                          to="/blog/change-is-here-and-so-are-the-robots" />
            </Route>
            <Route path="/aes19-international-evaluation-conference-day-3">
                <Redirect status={301} from="/aes19-international-evaluation-conference-day-3"
                          to="/blog/aes19-international-evaluation-conference-day-3" />
            </Route>
            <Route path="/most-significant-change-goes-digital-with-clear-horizon-academy">
                <Redirect status={301} from="/most-significant-change-goes-digital-with-clear-horizon-academy"
                          to="/blog/most-significant-change-goes-digital-with-clear-horizon-academy" />
            </Route>
            <Route path="/cart">
                <Redirect status={301} from="/cart" to="/checkout" />
            </Route>
            <Route path="/clear-horizon">
                <Redirect status={301} from="/clear-horizon" to="/experts" />
            </Route>
            <Route path="/contact">
                <Redirect status={301} from="/contact" to="/contact-us" />
            </Route>
            <Route path="/course-msc-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/most-significant-change-course');
                return null;
            }} />
            <Route path="/course-mel-online-full" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/complete-guide-to-measurement-evaluation-and-learning');
                return null;
            }} />
            <Route path="/course-mel-module-1" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/introduction-to-measurement-evaluation-and-learning-mel');
                return null;
            }} />
            <Route path="/course-mel-module-2" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/measuring-impact');
                return null;
            }} />
            <Route path="/course-mel-module-3" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/evaluation');
                return null;
            }} />
            <Route path="/course-mel-module-4" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/increasing-impact-through-learning');
                return null;
            }} />
            <Route path="/course-pba-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/evaluating-systems-change-place-based-approaches');
                return null;
            }} />
            <Route path="/course-pbi-online" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/using-power-bi-for-impact');
                return null;
            }} />
            <Route path="/developmental-evaluation" component={() => {
                window.location.replace('https://learn.clearhorizon.com.au/course/developmental-evaluation');
                return null;
            }} />
            <Route path="/">
                <HomePage />
            </Route>
        </Switch>
        <Footer />
    </CartContext.Provider>
}

export default App;
