import React, {useState} from 'react';
import Markdown from "./Markdown";
import Modal from "react-bootstrap/Modal";

export const ReadMoreImageHeaderTextSection = (props) => {
    const [showModal, setShowModal] = useState(false);
    let header = props.header !== null && props.header;
    let img = <img src={process.env.REACT_APP_IMG_PATH + props.src} alt={props.header} />
    if (props.link) {
        let target = '';
        if (props.external) {
            target = '_blank';
        }
        header = <a href={props.link} target={target}>{header}</a>
        img = <a href={props.link} target={target}>{img}</a>
    }
    const estilo = props.columns ? {
        width: 'calc(' + Math.round(100 / props.columns) + '% - ' + props.columns * 15 + 'px)'
    } : {};
    return (
        <>
            <div className={"image-section read-more-image-section"} style={estilo}>
                <div className={"top-section"}>
                    <div className={"header-img"}>
                        {img}
                    </div>
                    <h5 className={"text-center"}>{header}</h5>
                    {props.subHeader !== null && <Markdown renderers={{paragraph: 'h6'}} text={props.subHeader}
                                                           className={"image-section-subheader"} />}
                    <Markdown renderers={{paragraph: 'div'}} text={props.introText} />
                </div>
                <hr />
                <div className={"read-more-link"} onClick={() => setShowModal(true)}>Read more</div>
            </div>
            <ReadMoreModal action={{showModal, setShowModal}} text={props.text} img={props.src} header={props.header}
                           subHeader={props.subHeader} />
        </>
    )
}

const ReadMoreModal = (props) => {
    const mdl = {...props.action}
    return (
        <Modal className={"read-more-modal"} show={mdl.showModal} onHide={() => mdl.setShowModal(false)} animation={false}>
            <Modal.Header closeButton>
                &nbsp;
            </Modal.Header>
            <Modal.Body>
                <div className={"left-column"}>
                    <img src={process.env.REACT_APP_IMG_PATH + props.img} alt={props.header} />
                    <h2>{props.header}</h2>
                    {props.subHeader !== null && <Markdown renderers={{paragraph: 'h6'}} text={props.subHeader}
                                                           className={"image-section-subheader"} />}
                </div>
                <div className={"right-column"}>
                    <Markdown renderers={{paragraph: 'div'}} text={props.text} />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ReadMoreImageHeaderTextSection;