import {useLocation} from "react-router-dom";
import React from "react";

export const AlertMissingDetails = (alert) => {
    alert.error('Please ensure all details including course dates are completed', {header: 'Error:'});
}

export const BuildCountriesDropdown = () => {
    const countryArray = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua & Deps',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Rep',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo {Democratic Rep}',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland {Republic}',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea North',
        'Korea South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar, {Burma}',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'St Kitts & Nevis',
        'St Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
    ];
    return BuildDropdown(countryArray);
}

export const BuildDropdown = (dropdownArray) => {
    const countries = dropdownArray.map((item) => { return <option key = {item}>{item}</option> });
    countries.unshift(<option key={"select"} value={""}>Select</option>)
    return countries;
}

export const BuildHowHeardDropdown = () => {
    const howHeardArray = [
        'Search Engine',
        'Facebook Ad',
        'LinkedIn',
        'Referral',
        'Email'
    ];
    return BuildDropdown(howHeardArray);
}

export const BuildOrgTypeDropdown = () => {
    const orgTypeArray = [
        'Aid Agency',
        'Federal Government',
        'Industry Body',
        'International NGO',
        'Large business > 200 staff',
        'Local Government',
        'Local NGO',
        'Managing Contractor',
        'Philanthropic Foundation or Trust',
        'Small to medium business < 200 staff',
        'Social Enterprise',
        'Sole Practitioner',
        'State Government',
        'University',
        'Other'
    ];
    return BuildDropdown(orgTypeArray);
}

export const BuildSectorDropdown = () => {
    const sectorArray = [
        'Agriculture',
        'Climate Change',
        'Communications and Information Technology',
        'Community Engagement',
        'Disability',
        'Disaster Resilience and Emergency Management',
        'Early Childhood',
        'Education',
        'Energy',
        'Environment and Sustainability',
        'Family Violence and Women\'s Rights',
        'Finance and Treasury',
        'First Nations',
        'Gender',
        'Health Services',
        'Infrastructure and Transport',
        'International Development',
        'Law Justice and Community Safety',
        'Mental Health',
        'National Security',
        'Social and Community Services',
        'Social Enterprise',
        'Sport',
        'Systems Change and Place-Based Approaches (PBA)',
        'Training and Employment',
        'Youth Services'
    ];
    return BuildDropdown(sectorArray);
}

export const FetchData = async (url, method = 'GET', token = null, data = null,
                                basicAuth = null) => {
    let headers = new Headers({
        'Content-Type': 'application/json'
    });
    if (token !== null) {
        headers.append('Authorization', 'Bearer ' + token);
    }
    if (basicAuth !== null) {
        headers.append('Authorization', 'Basic ' + basicAuth);
    }
    let options = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        headers: headers
    }
    if (data !== null) {
        options.body = JSON.stringify(data) // body data type must match "Content-Type" header
    }
    let res = await fetch(url, options);
    if (!res.ok) {
        throw FetchException(res.statusText, res.status);
    }
    return res.json();
}

export const FetchException = (message, status) => {
    return {
        message: message,
        status: status
    }
}

export const FormatDateToString = (date) => {
    return new Date(date).toLocaleDateString('en-AU',
        { year: 'numeric', month: 'long', day: 'numeric' }
    );
}

export const FormatDateToDateMonString = (date) => {
    return new Date(date).toLocaleDateString('en-AU',
        { month: 'short', day: 'numeric' }
    );
}

export const MonthDiff = (d1, d2, full) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    if (full) {
        months -= d1.getMonth() + 1;
    } else {
        months -= d1.getMonth();
    }
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export const OpenPage = (link, newTab, external) => {
    const url = external ? link : process.env.REACT_APP_EXTERNAL_PATH + '/' + link;
    if (newTab) {
        window.open(url, '');
    } else {
        window.location.href = url;
    }
}

export const ProcessLink = (url, text = null, external = false) => {
    let link = {
        text: text,
        url: url,
        external: external
    };
    link.target = "";
    if (external === true) {
        link.target = "_blank";
    } else {
        link.url = process.env.REACT_APP_EXTERNAL_PATH+ '/' + url;
    }
    return link;
}

export const ReplaceSiteInfoVar = (propsText, siteInfo) => {
    let text = propsText;
    if (text.indexOf(process.env.REACT_APP_SITE_INFO_MARKER_START) !== -1) {
        const start = text.indexOf(process.env.REACT_APP_SITE_INFO_MARKER_START);
        const end = text.indexOf(process.env.REACT_APP_SITE_INFO_MARKER_END, start);
        const toBeReplaced = text.substring(start, end + process.env.REACT_APP_SITE_INFO_MARKER_END.length);
        const subEnd = toBeReplaced.indexOf(process.env.REACT_APP_SITE_INFO_MARKER_END);
        const siteVar = toBeReplaced.substring(process.env.REACT_APP_SITE_INFO_MARKER_START.length, subEnd);
        text = text.replace(toBeReplaced, siteInfo[siteVar]);
    }
    return text
}

export const CompileUTMData = () => {
    let returnData = {
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmContent: "",
        utmReferrer: "",
    }
    const utmData = GetUTMData();
    if (utmData !== null) {
        returnData.utmSource = utmData.utmSource;
        returnData.utmMedium = utmData.utmMedium;
        returnData.utmCampaign = utmData.utmCampaign;
        returnData.utmContent = utmData.utmContent;
        returnData.utmReferrer = utmData.referrer;
    }
    return returnData;
}

export const DeleteUTMData = () => {
    localStorage.removeItem('chaUTM');
}

export const GetUTMData = () => {
    let chaUTM = localStorage.getItem('chaUTM');
    return chaUTM !== null ? JSON.parse(chaUTM) : null;
}

const SetUTMData = (utmSource, utmMedium, utmCampaign, utmContent, referrer) => {
    localStorage.setItem('chaUTM', JSON.stringify({
        utmSource: utmSource,
        utmMedium: utmMedium,
        utmCampaign: utmCampaign,
        utmContent: utmContent,
        referrer: referrer
    }));
}

export const UpdateUTMData = () => {
    let location = useLocation();
    if (location.search !== "") {
        const query = new URLSearchParams(location.search);
        const utmSource = query.get('utm_source');
        const utmMedium = query.get('utm_medium');
        const utmCampaign = query.get('utm_campaign');
        const utmContent = query.get('utm_content');
        const referrer = process.env.REACT_APP_EXTERNAL_PATH + location.pathname + location.search;
        if (utmSource !== null) {
            let utmData = GetUTMData();
            if (utmData === null || utmData.utmSource !== utmSource || utmData.utmMedium !== utmMedium ||
                utmData.utmCampaign !== utmCampaign || utmData.referrer !== referrer ||
                utmData.utmContent !== utmContent) {
                    SetUTMData(utmSource, utmMedium, utmCampaign, utmContent, referrer);
            }
        }
    }
}
