import React from 'react';
import Container from "react-bootstrap/Container";

const Banner = (props) => {
    let estilo = props.HeroImage !== undefined && props.HeroImage !== null ? {
        background: `url("` + process.env.REACT_APP_IMG_PATH + props.HeroImage.url + `") no-repeat top center`,
        backgroundSize: `cover`
    } : {};
    return (
        <div className={"banner-container"} style={estilo}>
            <Container>
                <div className={"banner-header"}>
                    <h1>{props.Header}</h1>
                </div>
            </Container>
        </div>
    );
}

export default Banner;