import React from 'react';
import {ReplaceSiteInfoVar} from "../Helpers";
import Markdown from "./Markdown";
export const IconTextSection = (props) => {
    const text = ReplaceSiteInfoVar(props.text, props.siteInfo);
    return (
        <div className={props.class}>
            <i className={"fa fa-" + props.icon}>&nbsp;</i>
            <Markdown renderers={{paragraph: 'span'}} text={text} />
        </div>
    )
}

export default IconTextSection;