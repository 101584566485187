import React, {useEffect, useState} from 'react';
import NotFound from "../NotFound";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '../../components/Loader';
import Banner from '../../components/Banner';
import {FetchData} from "../../Helpers";
import Markdown from "../../components/Markdown";
import Metadata, {MetadataObj} from "../../components/Metadata";

export const BlogListPage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {BlogListPageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const BlogListPageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isBlogListLoaded, setIsBlogListLoaded] = useState(false);
    const [blogListItem, setBlogListItem] = useState([]);
    const [blogItems, setBlogItems] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let blogListURL = process.env.REACT_APP_API_PATH + "/blog-overview-pages/";
    let blogItemsURL = process.env.REACT_APP_API_PATH + "/blog-posts/";
    let { token, category, tag } = useParams();
    if (previewMode === true) {
        blogListURL += "preview/" + previewID + '/' + secret;
        blogItemsURL += "list/";
    } else if (category !== undefined) {
        blogListURL += "category/" + category;
        blogItemsURL += "category/" + category + "/";
    } else if (tag !== undefined) {
        blogListURL += "tag/" + tag;
        blogItemsURL += "tag/" + tag + "/";
    } else {
        blogListURL += "findall";
        blogItemsURL += "list/";
    }

    const fetchItems = (length) => {
        let start = blogItems.length > 0 ? blogItems.length : 0;
        if (blogListItem.maxPosts === undefined || start < blogListItem.maxPosts) {
            if (length === undefined) {
                length = blogListItem.ItemsPerPage;
            }
            FetchData(blogItemsURL + start + '/' + length, 'GET').then((result) => {
                if (result.statusCode !== undefined && result.statusCode !== 200) {
                    setNotFound(true);
                } else {
                    setBlogItems(currentList => [...currentList, ...result]);
                }
            });
        }
    };

    useEffect(() => {
        FetchData(blogListURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setBlogListItem(result[0]);
                    } else {
                        setBlogListItem(result);
                    }
                    setIsBlogListLoaded(true);
                    FetchData(blogItemsURL + 0 + '/' + result.ItemsPerPage, 'GET').then((result) => {
                        if (result.statusCode !== undefined && result.statusCode !== 200) {
                            setNotFound(true);
                        } else {
                            setBlogItems(currentList => [...currentList, ...result]);
                        }
                    });
                },
                (error) => {
                    setError(error);
                    setIsBlogListLoaded(true);
                }
            )
    }, [blogListURL, token, blogItemsURL]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isBlogListLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else if (blogListItem.id) {
        if (!blogListItem.id) {
            return;
        }
        let heroHeader = blogListItem.HeroHeader && Banner(blogListItem.HeroHeader);
        let posts = null;
        if (blogItems.length > 0) {
            posts = blogItems.map((post) => {
                const avatar = post.Author.avatar !== null ? process.env.REACT_APP_IMG_PATH + post.Author.avatar.url :
                    process.env.REACT_APP_EXTERNAL_PATH + '/blank_profile.png';
                return <BlogListPost post={post.Post} title={post.Title}
                                     image={process.env.REACT_APP_IMG_PATH + post.HeroHeader.HeroImage.url}
                                     author={post.Author.firstname} category={post.category}
                                     summary={post.Summary} slug={post.Slug} date={post.Date}
                                     commentCount={post.CommentCount} avatar={avatar} key={post.id} />
            });
        }
        const catDisplay = category !== undefined && <div className={"blog-category"}><span className={"category-header"}>Category:</span> <span className={"category-name"}>{category}</span></div>;
        const tagDisplay = tag !== undefined && <div className={"blog-category"}><span className={"category-header"}>Tag:</span> <span className={"category-name"}>{tag}</span></div>;
        let metadata = MetadataObj();
        metadata.PageTitle = 'Blog';
        metadata.PageDescription = 'Blog entries from Clear Horizon Academy.';
        metadata.PageImage = blogListItem.HeroHeader.HeroImage.url !== null ? blogListItem.HeroHeader.HeroImage : "";
        return (
            <>
                <Metadata metadata={metadata} />
                {heroHeader}
                <Container>
                    {catDisplay}
                    {tagDisplay}
                    <InfiniteScroll
                        dataLength={blogItems.length} //This is important field to render the next data
                        next={fetchItems}
                        hasMore={blogListItem.maxPosts > blogItems.length}
                        loader={<Loader />}
                        endMessage={
                            <div className={"no-more-posts"}>No more posts to display</div>
                        }
                    >
                        {posts}
                    </InfiniteScroll>
                </Container>
            </>
        );
    }
}

const BlogListPost = (props) => {
    const comments = props.commentCount === 1 ? 'Comment' : 'Comments';
    return (
        <div className={"blog-list-post"}>
            <div className={"image-tag"}>
                {props.category}
            </div>
            <a href ={"/blog/" + props.slug}><img className={"blog-list-image"} src={props.image} alt={props.title} /></a>
            <img className={"user-image"} alt={props.author} src={props.avatar} />
            <div className={"post-title"}>
                <a href ={"/blog/" + props.slug} className={"blue-link"}>{props.title}</a>
            </div>
            <Markdown className={"post-summary"} text={props.summary} />
            <a href ={"/blog/" + props.slug} className={"read-more"}>Read more &raquo;</a>
            <div className={"post-meta"}>
                {props.date} <span className="dot">&nbsp;</span> {props.commentCount} {comments}
            </div>
        </div>
    );
}
