import React, {useContext, useEffect, useRef, useState} from 'react';
import NotFound from "../NotFound";
import {
    CourseCarouselElement, CourseTestimonialCarouselElement, MentorCarouselElement, ModuleCarouselElement
} from '../../components/Carousel';
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../../components/Loader';
import TextBlock from "../../components/TextBlock";
import Btn from "../../components/Btn";
import IconTextSection from '../../components/IconText';
import {FetchData, FormatDateToString} from "../../Helpers";
import ImageHeaderTextSection from "../../components/ImageHeaderText";
import Cart from "../../components/Cart";
import CartContext from "../../contexts/CartContext";
import Markdown from "../../components/Markdown";
import {useAlert} from "react-alert";
import Video from "../../components/Video";
import Metadata from "../../components/Metadata";
import InfoPopup from "../../components/InfoPopup";

export const CoursePage = (previewMode, previewURL, secret) => {
    return (
        <div className="main">
            {CoursePageComponent(previewMode, previewURL, secret)}
        </div>
    );
};

const CoursePageComponent = (previewMode, previewURL, secret) => {
    const [error, setError] = useState(null);
    const [isCoursePageLoaded, setIsCoursePageLoaded] = useState(false);
    const [courseItem, setCourseItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let coursePageURL = process.env.REACT_APP_API_PATH + "/course-pages/";
    const { token, slug } = useParams();
    if (previewMode === true) {
        coursePageURL += "preview/" + previewURL + '/' + secret;
    } else {
        coursePageURL += "findurl/" + slug;
    }

    useEffect(() => {
        FetchData(coursePageURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setCourseItem(result[0]);
                    } else {
                        setCourseItem(result);
                    }
                    setIsCoursePageLoaded(true);
                },
                (error) => {
                    if (error.status === 404 || error.status === 500) {
                        setNotFound(true);
                    } else {
                        setError(error);
                    }
                    setIsCoursePageLoaded(true);
                }
            )
    }, [coursePageURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isCoursePageLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!courseItem.id) {
            return;
        }
        let courseCarousel = null, mentorCarousel = null, moduleCarousel = null, testimonialCarousel = null;
        if (courseItem.carousel_containers.length > 0) {
            for (let i = 0; i < courseItem.carousel_containers.length; i ++) {
                switch (courseItem.carousel_containers[i].Type) {
                    case "CourseTestimonials":
                        testimonialCarousel = CourseTestimonialCarouselElement(courseItem.carousel_containers[i]);
                        break;
                    case "Mentors":
                        mentorCarousel = MentorCarouselElement(courseItem.carousel_containers[i]);
                        break;
                    case "Modules":
                        moduleCarousel = ModuleCarouselElement(courseItem.carousel_containers[i]);
                        break;
                    default:
                        courseItem.carousel_containers[i].suppressButton = true;
                        courseCarousel = CourseCarouselElement(courseItem.carousel_containers[i]);
                        break;
                }
            }
        }
        let cost = null;
        let startDates = [];
        let enrolmentsOpen = false;
        let selfPaced = false;
        let coachingSection = null;
        if (courseItem.CourseInstance.length) {
            startDates = courseItem.CourseInstance.map(instance => {
                if (cost === null && instance.StripeProduct !== null && instance.StripeProduct.cost) {
                    cost = instance.StripeProduct.cost;
                }
                if (enrolmentsOpen === false && instance.EnrolmentsOpen) {
                    enrolmentsOpen = true;
                }
                if (selfPaced === false && instance.SelfPaced) {
                    selfPaced = true;
                }
                return instance.StartDate;
            });
            coachingSection = <PricingSection packages = {courseItem.coachingPackages} cost={cost}
                                              feedbackAvailable={courseItem.FeedbackAvailable}
                                              courseID = {courseItem.id} courseName={courseItem.CourseName}
                                              shortName={courseItem.ShortName} selfPaced={selfPaced}
                                              brochure={courseItem.Brochure}
                                              coachingPackageLevel={courseItem.CoachingPackageLevel}
                                              silverPackageEnabled={courseItem.SilverPackageEnabled}
                                              silverPackagePrice={courseItem.SilverPackagePrice} />
        }
        return (
            <>
                <Metadata metadata={courseItem.Metadata} />
                <CourseHeaderSectionContainer headerBackground = {courseItem.HeaderBackground}
                                              courseName = {courseItem.CourseName} enrollable={enrolmentsOpen}
                                              subheader = {courseItem.HeaderSectionLeft.Subheader}
                                              headerSectionRight = {courseItem.HeaderSectionRight}
                                              courseID = {courseItem.id} />
                <CourseBlurbSection courseName = {courseItem.CourseName} header = {courseItem.HeaderSectionLeft.Header}
                                    subheader = {courseItem.HeaderSectionLeft.Subheader}
                                    description = {courseItem.HeaderSectionLeft.Description}
                                    discount={courseItem.FifteenPercentDiscountApplies} />
                <CourseOverviewSection overview={courseItem.MainSection.CourseOverview}
                                       imageSections={courseItem.MainSection.ImageHeaderText}
                                       discount={courseItem.FifteenPercentDiscountApplies}
                                       createdBy = {courseItem.HeaderSectionLeft.CreatedBy} />
                <CourseInformationSection courseID = {courseItem.id}
                                          enrollable={enrolmentsOpen} selfPaced={selfPaced}
                                          coachingPackage={courseItem.CoachingPackageLevel}
                                          brochure={courseItem.Brochure}
                                          iconTexts={courseItem.HeaderSectionRight.IconText} mode={courseItem.Mode}
                                          prerequisites={courseItem.Prerequisites} startDate={startDates}
                                          duration={courseItem.DurationWeeks}
                                          hoursOfLearning={courseItem.HoursOfLearning}
                                          virtualSessions={courseItem.VirtualSessions} cost={cost} />
                <WhoThisCourseIsFor imageSections={courseItem.WhoIsThisCourseFor.ImageHeaderText}
                                    text={courseItem.WhoIsThisCourseFor.BodyText} />
                <LearningOutcomesSection learningOutcomes={courseItem.MainSection.LearningOutcomes}
                                         enrollable={enrolmentsOpen} />
                {moduleCarousel}
                <MainSection inclusions={courseItem.MainSection.CourseInclusions}
                             saveSection={courseItem.MainSection.SignUpAndSave} />
                {coachingSection}
                {testimonialCarousel}
                {mentorCarousel}
                <OrangeBannerSection enrollable = {enrolmentsOpen} courseID = {courseItem.id}
                                     coachingPackage={courseItem.CoachingPackageLevel} />
                {courseCarousel}
            </>
        );
    }
}

const CourseHeaderSectionContainer = (props) => {
    let estilo = !!props.headerBackground ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.headerBackground.url + `")`
    } : {};

    const [height, setHeight] = useState(0);
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [setHeight])

    return (
        <div className = "hero-header course-hero-header" style = {estilo} key = {props.courseID}>
            <div className = "background-overlay" ref={ref}>&nbsp;</div>
            <div className="row header-row course-header-row">
                <CourseHeaderImageSection courseName = {props.courseName} height={height} enrollable={props.enrollable}
                                          headerImage = {props.headerSectionRight.HeaderImage}
                                          headerVideo = {props.headerSectionRight.HeaderVideo}
                                          subheader = {props.subheader} />
            </div>
        </div>
    );
}

const CourseBlurbSection = (props) => {
    return props.description ? (
        <div className="container course-header-left-container">
            <div className={"course-header-left"}>
                <div className = "header-details text-left">
                    <TextBlock text={props.description} />
                </div>
            </div>
        </div>
    ) : '';
};

const CourseHeaderImageSection = (props) => {
    let video = <img className={"course-image"} src ={process.env.REACT_APP_IMG_PATH + props.headerImage.url}
                     alt={props.courseName} />;
    const [key, setKey] = useState(1);
    let height = 304;
    if (props.height !== 0) {
        height = props.height - 116;
    }
    video = <Video url={props.headerVideo} videoKey={{key, setKey}} video={video} height={height + "px"}/>;
    return (
        <>
            <div className={"col-12 col-md-5 course-top-right-image-container"}>
                {video}
            </div>
            <div className="col-12 col-md-7 course-header-right">
                <h1>{props.courseName}</h1>
                <h2>{props.subheader}</h2>
                {props.enrollable &&
                    <Btn Link = {'#pricing'} Text = "Enrol Now" />
                }
            </div>
        </>
    );
};

const CourseInformationSection = (props) => {
    const iconItems = props.iconTexts.map((item) => {
        return <IconTextSection class = "course-top-right-section" icon={item.Icon.Icon} text={item.Text}
                                key = {item.id} />;
    });
    const [, updateCount] = useContext(CartContext);
    const alert = useAlert();
    let startDates = 'TBC';
    let startDateText = "Start date";
    if (props.startDate.length) {
        if (props.startDate.length > 1) {
            startDateText = "Start dates";
            let formattedStartDates = [];
            for (let i = 0; i < props.startDate.length; i ++) {
                let date = 'On demand';
                if (!(props.startDate[i] === null && props.selfPaced)) {
                    date = FormatDateToString(props.startDate[i]);
                }
                formattedStartDates.push(date);
            }
            startDates = formattedStartDates.join(',<br />');
        } else {
            if (props.selfPaced === true) {
                startDates = 'On demand'
            } else {
                startDates = FormatDateToString(props.startDate);
            }
        }
    }
    let displayCost = props.coachingPackage !== null ? "from " : "";
    displayCost += "&#36;" + props.cost + " AUD";
    return (
        <div className = "hero-header course-information-section">
            <div className = "row header-row course-header-row">
                <div className={"col-12 course-main-section-left"}>
                    <div className={"course-top-right-info-container"}>
                        <div className={"text-center"}>
                            <h1 className={"course-header-right-h1"}>Course Information</h1>
                        </div>
                        <div className = "course-information">
                            {props.mode !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"chalkboard-teacher"}
                                                 text={"Mode: " + props.mode} />}
                            {props.prerequisites !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"check-square"}
                                                 text={"Pre-requisites: " + props.prerequisites} />}
                            {props.cost !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"credit-card"}
                                                 text={"Cost: " + displayCost} />}
                            {props.duration !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"clock"}
                                                 text={"Duration: " + props.duration} />}
                            {props.hoursOfLearning !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"user-clock"}
                                                 text={"Hours of learning: " + props.hoursOfLearning} />}
                            {props.virtualSessions !== null &&
                                <IconTextSection class = "course-top-right-section" icon={"users"}
                                                 text={"Virtual sessions: " + props.virtualSessions} />}
                            {props.startDate !== [] &&
                                <IconTextSection class = "course-top-right-section" icon={"calendar"}
                                                 text={startDateText + ": " + startDates} />}
                            {iconItems}
                        </div>
                        {props.coachingPackage === null &&
                        <div className={"button-container"}>
                            {props.enrollable &&
                                <Btn onClick = {() => HandleAddToCart(props.courseID, updateCount, alert)} Text = "Add To Cart" />
                            }
                            {!props.enrollable && <Btn Link={"https://info.clearhorizonacademy.com/cha_roi"} OpenInNewTab={true}
                                 Text = "Register Your Interest" />
                            }
                        </div>
                        }
                        {props.coachingPackage !== null &&
                        <div className={"button-container"}>
                            {!props.enrollable && <Btn Link={"https://info.clearhorizonacademy.com/cha_roi"} OpenInNewTab={true}
                                 Text = "Register Your Interest" />
                            }
                            {props.enrollable &&
                                <Btn Link = {'#pricing'} Text = "Enrol Today" />
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const CourseOverviewSection = (props) => {
    const imageSections = props.imageSections.map((section) => {
        return <div key = {"div" + section.id}>
            <ImageHeaderTextSection src={section.Image.url} text={section.Text} header={section.Header}
                                    key = {section.id} />
            <div className={"clearfix"} >&nbsp;</div>
        </div>;
    });
    return (
        <Container className = "course-main-section">
            <div className = "row">
                <div className={"col-12 course-main-section-left"}>
                    <h1>Course Overview</h1>
                    {/*<h5>Created by {props.createdBy}</h5>*/}
                    <TextBlock text={props.overview} />
                    {imageSections}
                    {props.discount !== false &&
                        <div className={"discount"}>
                            AES members, Non-Profits, students and existing Clear Horizon partners and Academy Alumni are
                            eligible for discounts. Click <a href={"/terms-and-conditions#discount-15"}>here</a> for details.
                        </div>
                    }
                </div>
            </div>
        </Container>
    );
}

const HandleAddToCart = (courseID, updateCount, alert) => {
    let added = Cart.AddToCart(courseID, updateCount)
    if (added) {
        alert.success('Course added to cart');
    }
}

const LearningOutcomesSection = (props) => {
    const learningOutcomes = props.learningOutcomes.map((outcome) => {
        return <UnorderedList class = "learning-outcome" text={outcome.Text} key = {outcome.id} />;
    });
    return (
        <Container className = "course-main-section">
            <div className = "row">
                <div className={"col-12 course-main-section-left"}>
                    <div className={"learning-outcomes"}>
                        <h3>Learning Outcomes</h3>
                        <div className={"outcomes-subheader"}>By the end of this course, you will be able to:</div>
                        <div className={"outcomes"}>
                            {learningOutcomes}
                        </div>
                        {props.enrollable &&
                            <div className={'text-center'}>
                                <Btn Link = {'#pricing'} Text = "Enrol Today" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Container>
    );
}

const MainSection = (props) => {
    const courseInclusions = props.inclusions.map((inclusion) => {
        return <div className = "course-inclusion" key = {inclusion.id}>
            <i className={"fa fa-check-circle"}>&nbsp;</i>
            <Markdown renderers={{paragraph: 'span'}} text={inclusion.Text} />
        </div>
    });
    return (
        <Container className = "course-main-section">
            <div className = "row">
                <div className={"col-12"}>
                    <div className={"course-inclusions"}>
                        <h1>Course Inclusions</h1>
                        {courseInclusions}
                    </div>
                    <div className={"find-out-more"}>
                        Want to know if this course is right for you? Check out our <a href = "/faqs">FAQs page</a> or <a href = "/contact-us">contact us</a> to find out more.
                    </div>
                </div>
            </div>
            {props.saveSection !== "" &&
            <div className="row">
                <div className={"col-12 sign-up-and-save"}>
                    <div className={"icon-background"}>
                        <i className={"fa fa-dollar-sign"}>&nbsp;</i>
                    </div>
                    <h1>Sign up today and save!</h1>
                    <Markdown renderers={{paragraph: 'span'}} text={props.saveSection} />
                </div>
            </div>
            }
        </Container>
    );
}

const OrangeBannerSection = (props) => {
    const [, updateCount] = useContext(CartContext);
    const alert = useAlert();
    return (
        <div className={"orange-banner-section course-orange-banner-section"}>
            <Container>
                <h1>Join us to build your skills and create greater impact today!</h1>
                {props.enrollable && props.coachingPackage === null &&
                <Btn class="white-btn" onClick = {() => HandleAddToCart(props.courseID, updateCount, alert)} Text = "Add To Cart" />
                }
                {props.enrollable && props.coachingPackage !== null &&
                <Btn class="white-btn" Link = {'#pricing'} Text = "Purchase Now" />
                }
                <Btn class="white-btn" Link={"https://info.clearhorizonacademy.com/cha_roi"} OpenInNewTab={true}
                     Text = "Register Your Interest" />
            </Container>
        </div>
    )
}

const PricingSection = (props) => {
    const [, updateCount] = useContext(CartContext);
    const alert = useAlert();

    const shortName = props.shortName ? props.shortName : '';
    const compilePricingSection = (pricingArray) => {
        return pricingArray.map((priceSection) => {
            const buttonClass = priceSection.recommended  ? 'btn-primary' : 'btn-primary-inverse';
            const containerClass = priceSection.recommended  ? 'standard' : priceSection.header.toLowerCase();
            return <div className={"col-12 col-lg-4 coaching-package-container pricing-div-" + containerClass}
                        key = {"pricing-div-" + priceSection.header}>
                {priceSection.recommended && <div className={'recommended'}>Recommended</div>}
                <div className={'pricing-header'}>{priceSection.header}</div>
                <div className={'pricing-bubble'}>&#36;{priceSection.cost} AUD</div>
                <div className={'pricing-text-section'}>
                <span>This package grants full access to the {shortName} course, including:
                    <ul>
                        {priceSection.extras}
                    </ul>
                </span>
                </div>
                <div className={'pricing-button'}>
                    <button className={buttonClass} onClick = {() => HandleAddToCart(priceSection.id, updateCount, alert)}>Enrol Now</button>
                </div>
            </div>
        });
    }
    let fivePackageCost = 0, fivePackageID = 0;
    let singleCost = props.silverPackagePrice + props.cost || props.cost;
    let singleID;
    if (props.packages) {
        for (let i = 0; i < props.packages.length; i++) {
            const coursePackage = props.packages[i];
            if (coursePackage.numberSessions === 3) {
                fivePackageCost = coursePackage.cost + props.cost;
                fivePackageID = coursePackage.id
            } else if (props.silverPackagePrice === null) {
                singleCost = coursePackage.cost + props.cost;
                singleID = coursePackage.id
            }
        }
    }
    let basicDotPoints = [
        <li key={1}>Comprehensive course materials, tools, and interactive content</li>,
        <li key={2}>Full access to the Clear Horizon Academy Community</li>
    ];
    if (!props.selfPaced) {
        basicDotPoints.splice(1, 0, <li key={3}>Weekly one-hour group sessions led by an expert mentor</li>);
    }
    let extraDotPoints = [...basicDotPoints];
    const extrasBlurb = <li key={4}>Feedback on your work-based course project throughout the course</li>;
    if (props.feedbackAvailable) {
        extraDotPoints.push(extrasBlurb);
    }
    let multipleExtrasDotPoints = [...extraDotPoints];
    const multipleExtrasBlurb = <li key={5}>Three x 30-minute one-to-one <a href = "/coaching" target={"_blank"} rel={"noreferrer"}>coaching sessions</a> <i
        className={'fa fa-info-circle'} onClick={e => InfoPopup(e, "coaching-package-container", alert, props.coachingPackageLevel)}>
        &nbsp;</i></li>;
    multipleExtrasDotPoints.push(multipleExtrasBlurb);
    if (props.silverPackagePrice === null) {
        extraDotPoints.push(<li key={6}>
            One 45-minute one-to-one <a href = "/coaching" target={"_blank"} rel={"noreferrer"}>coaching session</a> <i
            className={'fa fa-info-circle'} onClick={e => InfoPopup(e, "coaching-package-container", alert, props.coachingPackageLevel)}>&nbsp;</i>
        </li>);
    } else {
        extraDotPoints.push(<li key={7}>
            Weekly office hour to drop-in and get personalised support in an intimate group with your course mentor
        </li>);
    }
    let pricingArray = [
        {
            header: 'Basic',
            extras: basicDotPoints,
            cost: props.cost,
            id: props.courseID
        }
    ];
    if (props.coachingPackageLevel) {
        pricingArray.push({
            header: 'Premium',
            extras: multipleExtrasDotPoints,
            cost: fivePackageCost,
            id: props.courseID + '-' + fivePackageID
        });
    }
    let mobilePricingArray = [...pricingArray];
    if (props.silverPackageEnabled) {
        const standardItem = {
            header: 'Standard',
            extras: extraDotPoints,
            cost: singleCost,
            recommended: true,
            id: props.silverPackagePrice === null ? props.courseID + '-' + singleID : props.courseID + 's'
        };
        pricingArray.splice(1, 0, standardItem);
        mobilePricingArray.push(standardItem);
    } else if (props.coachingPackageLevel) {
        pricingArray[pricingArray.length -1].recommended = true;
    } else {
        pricingArray[0].recommended = true;
    }
    const returnSections = compilePricingSection(pricingArray);
    const mobileSections = compilePricingSection(mobilePricingArray);
    return (
        <div className={"pricing-section"} id={"pricing"}>
            <Container>
                <div className={'header-container'}>
                    <h1>Select your package</h1>
                </div>
                <Markdown renderers={{paragraph: 'span'}} text={props.text} />
                <div className={"row image-section-container mt-4 medium-hide"}>
                    {returnSections}
                </div>
                <div className={"row image-section-container mt-4 medium-show"}>
                    {mobileSections}
                </div>
                <div className={"small-grey mt-3"}><em>
                    Please note: Discounts are calculated on the basic package fee. Refer to <a href={"/terms-and-conditions"} target={"_blank"} rel={"noreferrer"}>T&#38;Cs</a> for full details.
                </em></div>
            </Container>
        </div>
    )
}

const UnorderedList = (props) => {
    return (
        <ul className={props.class}>
            <li>{props.text}</li>
        </ul>
    )
}

const WhoThisCourseIsFor = (props) => {
    const colNo = Math.floor(12 / props.imageSections.length);
    const imageSections = props.imageSections.map((section) => {
        return <div className={"col-12 col-md-" + colNo} key = {"div" + section.id}>
            <ImageHeaderTextSection src={section.Image.url} text={section.Text} header={section.Header} key = {section.id} />
        </div>
    });
    return (
        <div className={"who-this-course-is-for"}>
            <Container>
                <h1>Who is this course for?</h1>
                <Markdown renderers={{paragraph: 'div'}} text={props.text} />
                <div className={"row image-section-container"}>
                    {imageSections}
                </div>
            </Container>
        </div>
    )
}
