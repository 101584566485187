import React, {useContext, useEffect, useState} from 'react';
import Loader from "../../components/Loader";
import {DeleteUTMData, FetchData} from "../../Helpers";
import CartContext from "../../contexts/CartContext";
import Container from "react-bootstrap/Container";
import {GenerateOrderItems} from "./PaymentSuccess";
import {Discount} from "./Checkout";
import Metadata, {MetadataObj} from "../../components/Metadata";
import Markdown from "../../components/Markdown";

export const DiscountSuccessPage = (props) => {
    const postData = {
        discount: props.location.state.discount,
        fData: props.location.state.formData
    };
    const [error, setError] = useState(null);
    const [isSessionLoaded, setIsSessionLoaded] = useState(false);
    const [orderData, setOrderData] = useState(false);
    const [, updateCount] = useContext(CartContext);
    let metadata = MetadataObj();
    metadata.PageTitle = 'Order success';
    useEffect(() => {
        FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/cart/couponenrolment/', 'POST',
            null, {
                discount: props.location.state.discount,
                fData: props.location.state.formData
            })
            .then(
                (result) => {
                    if (result.length !== undefined) {
                        setOrderData(result[0]);
                    } else {
                        setOrderData(result);
                    }
                    DeleteUTMData();
                    setIsSessionLoaded(true);
                    window.scrollTo(0, 0);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsSessionLoaded(true);
                }
            )
    }, [props.location.state]);
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isSessionLoaded) {
        return <Loader style = {{paddingTop: '76px'}} />;
    } else if (orderData) {
        if (orderData.error !== undefined) {
            return (
                <div className="main">
                    <Metadata metadata={metadata} />
                    <Container className={"payment-success"}>
                        <Markdown text={orderData.message} />
                    </Container>
                </div>
            );
        }
        const orderItems = GenerateOrderItems(orderData, updateCount);
        return (
            <div className="main">
                <Metadata metadata={metadata} />
                <Container className={"payment-success"}>
                    <Markdown text={orderData.message} />
                    <div className={"ordered-items"}>
                        <h2>Order details:</h2>
                        {orderItems}
                        <div className={"ordered-item-total"}>
                            Total cost: <span className={"detail"}>&#36;{orderData.totalCost}</span>
                        </div>
                        <div className={"ordered-item-total"}>
                            {postData.discount !== null && <Discount discount={postData.discount} total={orderData.totalCost} />}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
};
