import React, {} from 'react';
import { useLocation, useParams } from "react-router-dom";
import {HomePage} from './Home';
import {Menu} from '../components/Menu';
import Footer from '../components/Footer';
import {CoursesOverviewPage} from "./course/Overview";
import {FAQsPage} from "./FAQs";
import {CoursePage} from "./course/Course";
import ContactPage from "./Contact";
import {Post} from "./blog/Post";
import {BlogListPage} from "./blog/List";
import {InfoPage} from "./InfoPage";
import {OrganisationsPage} from "./Organisations";

const PreviewPage = () => {
    let returnFunc = null;
    const { search } = useLocation();
    let { contentType } = useParams();
    let params = React.useMemo(() => new URLSearchParams(search), [search]);
    switch (contentType) {
        case 'home-pages':
            returnFunc = HomePage(true, params.get('id'), params.get('secret'));
            break;
        case 'courses-overview-page':
            returnFunc = CoursesOverviewPage(true, params.get('id'), params.get('secret'));
            break;
        case 'course-pages':
            returnFunc = CoursePage(true, params.get('URL'), params.get('secret'));
            break;
        case 'contact-pages':
            returnFunc = ContactPage(true, params.get('id'), params.get('secret'));
            break;
        case 'fa-qs-page':
            returnFunc = FAQsPage(true, params.get('id'), params.get('secret'));
            break;
        case 'organisations-pages':
            returnFunc = OrganisationsPage(true, params.get('id'), params.get('secret'));
            break;
        case 'info-page':
            returnFunc = InfoPage(true, params.get('Slug'), params.get('secret'));
            break;
        case 'blog-post':
            returnFunc = Post(true, params.get('Slug'), params.get('secret'));
            break;
        case 'blog-overview-page':
            returnFunc = BlogListPage(true, params.get('id'), params.get('secret'));
            break;
        case 'menu':
            returnFunc = Menu(true, params.get('id'), params.get('secret'));
            break;
        case 'footers':
            returnFunc = Footer(true, params.get('id'), params.get('secret'));
            break;
        default:
            break;
    }
    return (
        <>
            {returnFunc}
        </>
    );
};

export default PreviewPage;