import React from 'react';
import Carousel from 'react-elastic-carousel'
import {OpenPage} from "../Helpers";
import Markdown from "./Markdown";

const CarouselPlayer = (props) => {
    return (
        <Carousel breakPoints={props.breakPoints} isRTL={false} enableAutoPlay={false}  disableArrowsOnEnd={false}
                  autoPlaySpeed={5000} key={props.id}>
            {props.carouselItems}
        </Carousel>
    )
}

export const CoachingBenefitsCarouselElement = (props) => {
    return (
        <div className="carousel coaching-benefits-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <h1>{props.Header}</h1>
                    <div className="our-approach-subheader">{props.SubHeader}</div>
                </div>
                {OurApproachCarouselContainer(props)}
                {props.Button !== null &&
                <div className="button-container">
                    <button className="dark-font-button" onClick={
                        () => OpenPage(props.Button.Link, props.Button.OpenInNewTab, props.Button.ExternalLink || false)
                    }>
                        {props.Button.Text}
                    </button>
                </div>
                }
            </div>
        </div>
    );
};

export const CourseCarouselElement = (props) => {
    let estilo = (props.BackgroundImage !== undefined && props.BackgroundImage !== null) ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.BackgroundImage.url + `")`
    } : {};
    return (
        <div className="carousel course-carousel" style={estilo} key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <h1>{props.Header}</h1>
                    <div className="sub-header">{props.SubHeader}</div>
                </div>
                {CourseCarouselContainer(props)}
                {props.Button !== null &&
                <div className="button-container">
                    <button className="dark-font-button" onClick={
                        () => OpenPage(props.Button.Link, props.Button.OpenInNewTab, props.Button.ExternalLink || false)
                    }>
                        {props.Button.Text}
                    </button>
                </div>
                }
            </div>
        </div>
    );
};

const CourseCarouselSlide = (props) => {
    return (
        <div className = "slide course-slide">
            <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt = {props.caption} />
            <div className="info-container">
                <div className="header-info">
                    <h5>{props.caption}</h5>
                </div>
                <h6>{props.header}</h6>
                <div className="slider-body">{props.body1}</div>
                <div className="slider-body course-dates">{props.body2}</div>
                <a className="slider-link"
                   href={props.external ? props.linkURL : process.env.REACT_APP_EXTERNAL_PATH + props.linkURL}>
                    {props.linkText}
                </a>
            </div>
        </div>
    );
};

const CourseCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((carousel) =>
        {
            return (
                <CourseCarouselSlide img = {carousel.SlideImage.url} caption = {carousel.Caption}
                    header = {carousel.SlideHeader} body1 = {carousel.BodyField1} body2 = {carousel.BodyField2}
                    linkText = {carousel.Link.LinkText} linkURL = {carousel.Link.URL} key={carousel.id}
                                     external={carousel.Link.ExternalLink} />
            )
        });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 462, itemsToShow: 2 },
        { width: 654, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer carouselItems={carouselItems} carouselRef={props.idx} breakPoints={breakPoints} />
    );
}

export const CourseTestimonialCarouselElement = (props) => {
    return (
        <div className="carousel course-testimonial-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <div className="course-testimonials-header">{props.Header}</div>
                </div>
                {CourseTestimonialCarouselContainer(props)}
            </div>
        </div>
    );
};

const CourseTestimonialCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((slide) => {
        return (
            <CourseTestimonialCarouselSlide caption = {slide.Caption}
                                            header = {slide.SlideHeader} text = {slide.SlideText} key={slide.id} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: slidesInView },
        { width: 462, itemsToShow: slidesInView },
        { width: 654, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.carouselRef} />
    );
}

const CourseTestimonialCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <Markdown className={"testimonial"} renderers={{paragraph: 'span'}} text={props.text} />
            <div className="info-container">
                <div className="course-testimonial-header-info">
                    <div className="star-rating">
                        <i className="fa fa-star" aria-hidden="true">&nbsp;</i>&nbsp;
                        <i className="fa fa-star" aria-hidden="true">&nbsp;</i>&nbsp;
                        <i className="fa fa-star" aria-hidden="true">&nbsp;</i>&nbsp;
                        <i className="fa fa-star" aria-hidden="true">&nbsp;</i>&nbsp;
                        <i className="fa fa-star" aria-hidden="true">&nbsp;</i>
                    </div>
                    <div className="heading-7">{props.caption} <em>{props.header}</em></div>
                </div>
            </div>
        </div>
    );
};

export const MentorCarouselElement = (props) => {
    return (
        <div className="carousel course-mentor-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <div className="course-mentor-header">
                        <h1>{props.Header}</h1>
                    </div>
                </div>
                {MentorCarouselContainer(props)}
            </div>
        </div>
    );
};

const MentorCarouselSlide = (props) => {
    return (
        <div className = "slide">
            {props.img !== undefined && <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt = {props.caption} />}
            <div className="info-container">
                <div className="header-info">
                    <h5>{props.caption}</h5>
                    <h6>{props.header}</h6>
                </div>
                <hr />
                <Markdown className={"slider-body"} renderers={{paragraph: 'span'}} text={props.slideText} />
            </div>
        </div>
    );
};

const MentorCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((carousel) =>
    {
        return (
            <MentorCarouselSlide img = {carousel.SlideImage?.url} caption = {carousel.Caption}
                                 header ={carousel.SlideHeader} key={carousel.id} slideText={carousel.SlideText} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 462, itemsToShow: 1 },
        { width: 674, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.carouselRef} />
    );
}

export const ModuleCarouselElement = (props) => {
    return (
        <div className="carousel course-module-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <div className="course-module-header">{props.Header}</div>
                </div>
                {ModuleCarouselContainer(props)}
            </div>
        </div>
    );
};

const ModuleCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt = {props.caption} />
            <div className="info-container">
                <div className="header-info">
                    <div className="caption">{props.caption}</div>
                    <Markdown className={"slider-text"} renderers={{paragraph: 'span'}} text={props.slideText} />
                </div>
                <a className="slider-link" href={process.env.REACT_APP_EXTERNAL_PATH + props.linkURL}>
                    {props.linkText}
                </a>
                <div className="slider-body">{props.body1}</div>
                <div className="slider-body">{props.body2}</div>
                <div className={"slider-footer"}>{props.header}</div>
            </div>
        </div>
    );
};

const ModuleCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((carousel) =>
    {
        return (
            <ModuleCarouselSlide img = {carousel.SlideImage.url} caption = {carousel.Caption}
                                 header ={carousel.SlideHeader} body1 ={carousel.BodyField1}
                                 body2 ={carousel.BodyField2} linkText = {carousel.Link.LinkText}
                                 linkURL = {carousel.Link.URL} key={carousel.id} slideText={carousel.SlideText} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 462, itemsToShow: 1 },
        { width: 674, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.carouselRef} />
    );
}

export const OrganisationsMentorCarouselElement = (props) => {
    let url = null;
    let text = null;
    let external = null;
    let introSlide = null;
    for (let i = 0; i < props.carousel.CarouselSlide.length; i ++) {
        const slide = props.carousel.CarouselSlide[i];
        if (slide.SlideImage === null) {
            introSlide = {
                Header: slide.SlideHeader,
                Text: slide.SlideText,
                Button: {
                    OpenInNewTab: slide.Link.ExternalLink,
                    Text: slide.Link.LinkText,
                    Link: slide.Link.URL
                }
            }
            url = slide.Link.URL;
            text = slide.Link.LinkText;
            external = slide.Link.ExternalLink;
            break;
        }
    }

    return (
        <div className="carousel organisations-mentor-carousel" key={props.id}>
            <div className="container text-center">
                <div className={"header-btn-container orange"}>
                    <button className="header-btn" onClick={() => OpenPage(url, external)}>
                        {text}
                    </button>
                </div>
                <OrganisationsMentorCarouselContainer props={props} introSlide={introSlide} />
            </div>
        </div>
    );
};

const OrganisationsMentorCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt = {props.caption} />
            <div className="info-container">
                <div className="header-info">
                    <div className="caption">{props.caption}</div>
                    <div className="slider-header">{props.header}</div>
                </div>
                <hr />
                <Markdown className={"slider-body"} renderers={{paragraph: 'span'}} text={props.slideText} />
            </div>
        </div>
    );
};

const OrganisationsMentorCarouselContainer = (props) => {
    const carouselItems = props.props.carousel.CarouselSlide.map((carousel) => {
        if (carousel.SlideImage === null) {
            return <SlideBox package={props.introSlide} key={carousel.id} />;
        }
        return (
            <OrganisationsMentorCarouselSlide img = {carousel.SlideImage.url} caption = {carousel.Caption}
                                              header ={carousel.SlideHeader} key={carousel.id} slideText={carousel.SlideText} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.props.carousel.SlidesInView)) ? parseInt(props.props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: slidesInView },
        { width: 462, itemsToShow: slidesInView },
        { width: 654, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.props.carouselRef} />
    );
}

export const OrganisationsPartnerCarouselElement = (props) => {
    return (
        <div className="carousel organisations-partner-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    {props.Header !== "" && <h1>{props.Header}</h1>}
                    <div className="sub-header">{props.SubHeader}</div>
                </div>
                {PartnerCarouselContainer(props)}
            </div>
        </div>
    );
};

export const OrganisationsTestimonialCarouselElement = (props) => {
    let estilo = (props.BackgroundImage !== undefined && props.BackgroundImage !== null) ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.BackgroundImage.url + `")`
    } : {};
    return (
        <div className="carousel organisations-testimonial-carousel" style={estilo} key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    {props.Header !== "" && <h1>{props.Header}</h1>}
                    <div className="sub-header">{props.SubHeader}</div>
                </div>
                {TestimonialCarouselContainer(props)}
            </div>
        </div>
    );
};

export const OurApproachCarouselElement = (props) => {
    return (
        <div className="carousel our-approach-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    <h1>{props.Header}</h1>
                    <div className="our-approach-subheader">{props.SubHeader}</div>
                </div>
                {OurApproachCarouselContainer(props)}
                {props.Button !== null &&
                <div className="button-container">
                    <button className="dark-font-button" onClick={
                        () => OpenPage(props.Button.Link, props.Button.OpenInNewTab, props.Button.ExternalLink || false)
                    }>
                        {props.Button.Text}
                    </button>
                </div>
                }
            </div>
        </div>
    );
};

const OurApproachCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <div className="highlight-container">
                <div className = "highlight-details-container">
                    <img className = "highlight-details-icon" src={process.env.REACT_APP_IMG_PATH + props.icon}
                         alt = {props.caption} />
                    <div className = "highlight-details-header">
                        <h4>{props.caption}</h4>
                    </div>
                    <Markdown className={"highlight-details-detail"} renderers={{paragraph: 'span'}} text={props.slideText} />
                </div>
            </div>
        </div>
    );
};

const OurApproachCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((carousel) => {
        return (
            <OurApproachCarouselSlide icon = {carousel.SlideImage.url} caption = {carousel.Caption} key={carousel.id}
                                      slideText={carousel.SlideText} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 1;
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 462, itemsToShow: 2 },
        { width: 654, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.carouselRef} />
    );
}

export const PartnerCarouselElement = (props) => {
    return (
        <div className="carousel partner-carousel" key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    {props.Header !== "" && <div className="partners-header">{props.Header}</div>}
                    <h1>{props.SubHeader}</h1>
                </div>
                {PartnerCarouselContainer(props)}
            </div>
        </div>
    );
};

const PartnerCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((slide) =>
    {
        return (
            <PartnerCarouselSlide caption = {slide.Caption} img = {slide.SlideImage.url} key={slide.id} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 3;
    const breakPoints = [
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 462, itemsToShow: slidesInView },
        { width: 654, itemsToShow: slidesInView }
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.idx} />
    );
}

const PartnerCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt = {props.caption} />
        </div>
    );
};

export const TestimonialCarouselElement = (props) => {
    let estilo = (props.BackgroundImage !== undefined && props.BackgroundImage !== null) ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.BackgroundImage.url + `")`
    } : {};
    return (
        <div className="carousel testimonial-carousel" style={estilo} key={props.id}>
            <div className="container text-center">
                <div className="header-container">
                    {props.Header !== "" && <div className="testimonials-header">{props.Header}</div>}
                    <h1>{props.SubHeader}</h1>
                </div>
                {TestimonialCarouselContainer(props)}
            </div>
        </div>
    );
};

const TestimonialCarouselContainer = (props) => {
    const carouselItems = props.carousel.CarouselSlide.map((slide) =>
    {
        return (
            <TestimonialCarouselSlide caption = {slide.Caption}
                           header = {slide.SlideHeader} text = {slide.SlideText} key={slide.id} />
        )
    });
    const slidesInView = !isNaN(parseInt(props.carousel.SlidesInView)) ? parseInt(props.carousel.SlidesInView) : 2;
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 462, itemsToShow: 1 },
        { width: 675, itemsToShow: 1 },
        { width: 878, itemsToShow: slidesInView },
    ];
    return (
        <CarouselPlayer breakPoints={breakPoints} carouselItems={carouselItems} carouselRef={props.idx} />
    );
}

const TestimonialCarouselSlide = (props) => {
    return (
        <div className = "slide">
            <Markdown className={"testimonial"} renderers={{paragraph: 'span'}} text={props.text} />
            <div className="info-container">
                <div className="header-info">
                    <div className="heading-8">{props.caption}</div>
                    <h6>{props.header}</h6>
                </div>
                <div className="slider-body">{props.body1}</div>
                <div className="slider-body">{props.body2}</div>
            </div>
        </div>
    );
};

const SlideBox = (props) => {
    return (
        <div className={"package-box"} key={props.package.id}>
            <div className={"box-header"}>{props.package.Header}</div>
            <Markdown renderers={{paragraph: 'span'}} text={props.package.Text} />
        </div>
    )
}

