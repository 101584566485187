import React from 'react';
import {Helmet} from "react-helmet";

const Metadata = (props) => {
    let pageTitle = "";
    let pageDescription = "";
    let siteName = process.env.REACT_APP_DEFAULT_SITENAME;
    let image = "";
    let pageUpdated = Date();
    let favicon64 = process.env.REACT_APP_EXTERNAL_PATH + "/favicon.ico";
    let favicon32 = process.env.REACT_APP_EXTERNAL_PATH + "/logo192.png";
    let favicon192 = process.env.REACT_APP_EXTERNAL_PATH + "/logo192.png";
    let favicon180 = process.env.REACT_APP_EXTERNAL_PATH + "/logo192.png";
    let favicon270 = process.env.REACT_APP_EXTERNAL_PATH + "/logo512.png";
    if (props.metadata !== null) {
        if (props.metadata.PageTitle !== null) {
            pageTitle = props.metadata.PageTitle + " - "
        }
        if (props.metadata.PageDescription !== null) {
            pageDescription = props.metadata.PageDescription
        }
        if (props.metadata.siteName !== null) {
            siteName = props.metadata.siteName
        }
        if (props.metadata.PageImage !== null && props.metadata.PageImage.url !== null) {
            image = process.env.REACT_APP_IMG_PATH + props.metadata.PageImage.url
        }
        if (props.metadata.PageUpdated !== null) {
            pageUpdated = props.metadata.PageUpdated
        }
        if (props.metadata.favicon64 !== null && props.metadata.favicon64.url !== null) {
            favicon64 = process.env.REACT_APP_IMG_PATH + props.metadata.favicon64.url
        }
        if (props.metadata.favicon32 !== null && props.metadata.favicon32.url !== null) {
            favicon32 = process.env.REACT_APP_IMG_PATH + props.metadata.favicon32.url
        }
        if (props.metadata.favicon192 !== null && props.metadata.favicon192.url !== null) {
            favicon192 = process.env.REACT_APP_IMG_PATH + props.metadata.favicon192.url
        }
        if (props.metadata.favicon180 !== null && props.metadata.favicon180.url !== null) {
            favicon180 = process.env.REACT_APP_IMG_PATH + props.metadata.favicon180.url
        }
        if (props.metadata.favicon270 !== null && props.metadata.favicon270.url !== null) {
            favicon270 = process.env.REACT_APP_IMG_PATH + props.metadata.favicon270.url
        }
    }
    return (
        <Helmet>
            <title>{pageTitle} Clear Horizon Academy</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:title" content={pageTitle + siteName} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={process.env.REACT_APP_EXTERNAL_PATH + window.location.pathname} />
            <meta property="og:site_name" content={siteName} />
            <meta property="article:modified_time" content={pageUpdated} />
            <link rel="canonical" href={process.env.REACT_APP_EXTERNAL_PATH + window.location.pathname} />
            <link rel="icon" href={favicon64} />
            <link rel="icon" href={favicon32} sizes="32x32" />
            <link rel="icon" href={favicon192} sizes="192x192" />
            <link rel="apple-touch-icon" href={favicon180} />
            <meta name="msapplication-TileImage" content={favicon270} />
        </Helmet>
    );
}

export const MetadataObj = () => {
    return {
        PageTitle: null,
        PageDescription: null,
        siteName: null,
        PageImage: null,
        PageUpdated: null,
        favicon64: null,
        favicon32: null,
        favicon192: null,
        favicon180: null,
        favicon270: null,
    }
}

export default Metadata;