import React from 'react';
import ReactMarkdown from "react-markdown";

const Markdown = (props) => {
    let allowDangerousHtml = true;
    if (props.allowDangerousHtml === false) {
        allowDangerousHtml = false;
    }
    return (
        <ReactMarkdown className={props.className} transformImageUri={
                            uri => uri.startsWith("http") ? uri : `${process.env.REACT_APP_API_PATH}${uri}`
                        } linkTarget={
                            href => href.indexOf(':ext') !== -1 ? '_blank' : null
                        } transformLinkUri={
                            href => href.indexOf(':ext') === href.length -4 ? href.substr(0, href.length -4)
                                : href
                        } allowDangerousHtml={allowDangerousHtml} renderers={props.renderers}>
            {props.text}
        </ReactMarkdown>
    );
}

export default Markdown;