import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import {DeleteUTMData, FetchData, FormatDateToString} from "../../Helpers";
import CartContext from "../../contexts/CartContext";
import Container from "react-bootstrap/Container";
import Cart from "../../components/Cart";
import {Discount} from "./Checkout";
import Metadata, {MetadataObj} from "../../components/Metadata";
import Markdown from "../../components/Markdown";

export const PaymentSuccessPage = () => {
    const {sessionID} = useParams();
    const [error, setError] = useState(null);
    const [isSessionLoaded, setIsSessionLoaded] = useState(false);
    const [orderData, setOrderData] = useState(false);
    const [, updateCount] = useContext(CartContext);
    let metadata = MetadataObj();
    metadata.PageTitle = 'Payment success';
    useEffect(() => {
        FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/cart/completeenrolment/'
            + sessionID, 'GET')
            .then(
                (result) => {
                    if (result.length !== undefined) {
                        setOrderData(result[0]);
                    } else {
                        setOrderData(result);
                    }
                    DeleteUTMData();
                    setIsSessionLoaded(true);
                    window.scrollTo(0, 0);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsSessionLoaded(true);
                }
            )
    }, [sessionID]);
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isSessionLoaded) {
        return <Loader style = {{paddingTop: '76px'}} />;
    } else if (orderData) {
        if (orderData.error !== undefined) {
            return (
                <div className="main">
                    <Metadata metadata={metadata} />
                    <Container className={"payment-success"}>
                        <Markdown text={orderData.message} />
                    </Container>
                </div>
            );
        }
        const orderItems = GenerateOrderItems(orderData, updateCount);
        return (
            <div className="main">
                <Metadata metadata={metadata} />
                <Container className={"payment-success"}>
                    <Markdown text={orderData.message} />
                    <div className={"ordered-items"}>
                        <h2>Order details:</h2>
                        {orderItems}
                        <div className={"ordered-item-total"}>
                            Overall total: <span className={"detail"}>&#36;{orderData.totalCost}</span>
                        </div>
                        <div className={"ordered-item-total"}> {
                                orderData.discount !== undefined && orderData.discount !== null &&
                                    orderData.discount.discount !== 0 &&
                                        <Discount discount={orderData.discount} total={orderData.totalCost} />
                            }
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
};

export const GenerateOrderItems = (orderData, updateCount) => {
    let orderItems = null;
    if (orderData.items.length > 0) {
        orderItems = orderData.items.map((item) => {
            return <OrderItem item = {item} key={"ordered-item-" + item.courseID} />
        });
        Cart.EmptyCart(updateCount);
    }
    return orderItems;
}

export const OrderItem = (props) => {
    const cost = props.item.fullCost !== undefined ? props.item.fullCost : props.item.cost;
    // const amountPaid = props.item.amountPaid !== undefined ? props.item.amountPaid : props.item.cost;
    let startDate = 'On demand';
    if (props.item.selfPaced !== true) {
        startDate = FormatDateToString(props.item.startDate);
    }
    return <>
        <div className={"ordered-item"}>
            <div className={"ordered-item-name"}>
                <a href = {process.env.REACT_APP_EXTERNAL_PATH + '/courses/' + props.item.url}>{props.item.courseName}</a>
            </div>
        <img src={process.env.REACT_APP_IMG_PATH + props.item.image.url} alt={props.item.courseName} />
            <div className={"ordered-item-details"}>
                <div className={"start-date"}>Start Date: <span className={"detail"}>{startDate}</span></div>
                {props.item.selfPaced !== true &&
                <div className={"duration"}>Duration: <span className={"detail"}>{props.item.duration}</span>
                </div>
                }
                <div className={"cost"}>Cost: <span className={"detail"}>&#36;{cost}</span></div>
                <div className={"cost"}>Quantity: <span className={"detail"}>{props.item.quantity}</span></div>
                <div className={"cost"}>Total Cost: <span className={"detail"}>&#36;{cost * props.item.quantity}</span></div>
            </div>
        </div>
        {props.item.coachingPackage !== undefined &&
        <div className={"ordered-item"}>
            <div className={"ordered-item-name"}>
                {props.item.coachingPackage.name}
            </div>
            <img src={process.env.REACT_APP_IMG_PATH + props.item.coachingImage.url} alt={props.item.coachingPackage.name} />
            <div className={"ordered-item-details"}>
                <div className={"cost"}>Cost: <span className={"detail"}>&#36;{props.item.coachingPackage.cost}</span></div>
                <div className={"cost"}>Quantity: <span className={"detail"}>{props.item.quantity}</span></div>
                <div className={"cost"}>Total Cost: <span
                    className={"detail"}>&#36;{props.item.coachingPackage.cost * props.item.quantity}</span></div>
            </div>
        </div>
        }
    </>
}
