import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Loader from '../components/Loader';
import {API} from '../api/API'
import Metadata, {MetadataObj} from "../components/Metadata";
import {
    CompileUTMData, DeleteUTMData
} from "../Helpers";
import Markdown from "../components/Markdown";

const ReferralForm = () => {
    let metadata = MetadataObj();
    metadata.PageTitle = 'Referrals';
    metadata.PageDescription = 'Refer a learner to Clear Horizon Academy and both of you will receive a discount off a future learning experience.';

    return (
        <div className="main">
            <Metadata metadata={metadata} />
            <div className={"container"}>
                <header>
                    <h1>Referrals</h1>
                </header>
                <div className={"mb-3"}>If you’ve enjoyed learning with Clear Horizon Academy, why not share the experience with others?</div>
                <div className={"mb-3"}>
                    Refer us to your network and we will reward both you and those you refer with a discount off a
                    future learning experience &#128578;
                </div>
                <div className={"mb-3"}>
                    Here’s how it works:
                    <ol className={"mt-3"}>
                        <li>
                            Fill in the form below and add the email addresses of those you’d like to share our courses
                            with. We only use their email addresses for the purpose of providing them with the discount.
                        </li>
                        <li>
                            They will receive an email containing a single-use coupon that will give them 20% off any
                            of our courses.
                        </li>
                        <li>
                            If they purchase a course you will receive a single-use coupon that will give you 30% off
                            any of our courses.
                        </li>
                    </ol>
                </div>
                {FormComponent()}
            </div>
        </div>
    );
};

const FormComponent = () => {
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState('');
    const [tAndCs, setTAndCs] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries())
            const submitSuccess = await API.submitReferralForm(formDataObj);
            if (submitSuccess.success) {
                setSubmitResponseStatus(2);
                setSuccessMessage(submitSuccess.message);
                DeleteUTMData();
                window.scrollTo(0, 0);
            } else {
                setErrorMessage(submitSuccess.message);
                setSubmitResponseStatus(3);
                window.scrollTo(0, 0);
            }
        }
        setValidated(true);
    };

    const checkValid = async (e) => {
        const form = e.currentTarget;
        setDisabled(true);
        if (form.checkValidity() !== false) {
            setDisabled(false);
        }
    };

    const utmData = CompileUTMData();
    if (submitResponseStatus === 1) {
        return <Loader />
    } else if (submitResponseStatus === 2) {
        return <Markdown className={"form-submitted"} text={successMessage} />
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit} onChange={checkValid}>
                {submitResponseStatus === 3 && <Markdown className={"form-submitted"} text={errorMessage} />}
                <Form.Group>
                    <Form.Control type="hidden" name = "utm_source" defaultValue={utmData.utmSource} />
                    <Form.Control type="hidden" name = "utm_medium" defaultValue={utmData.utmMedium} />
                    <Form.Control type="hidden" name = "utm_campaign" defaultValue={utmData.utmCampaign} />
                    <Form.Control type="hidden" name = "utm_content" defaultValue={utmData.utmContent} />
                    <Form.Control type="hidden" name = "utm_referrer" defaultValue={utmData.utmReferrer} />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>First name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="First name" name = "firstName"
                            value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Last name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="Last name" name = "lastName"
                                      value={lastName} onChange={e => setLastName(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Your email address<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Email" required
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "email"
                                      value={email} onChange={e => setEmail(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Email addresses of people you would like to refer (one per line)<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control as="textarea" rows={4} required name = "emailList"
                                      value={emailList} onChange={e => setEmailList(e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="tAndCsCheckbox">
                        <Form.Check type="checkbox" className={"tAndCs"} id={'t-and-c-checkbox'} name = 'tAndCs' required
                                    label={
                                        <span>I confirm I have read and agree to
                                            the <a href='/terms-and-conditions' target='_blank'>terms and
                                                conditions</a> of the Clear Horizon Academy referral program.
                            <span className='required-field'>*</span></span>}
                                    checked={tAndCs} onChange={e => setTAndCs(e.target.checked)} />
                    </Form.Group>
                </Form.Row>
                {!disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary"}>Submit form</Button>
                </div>
                }
                {disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary disabled"}>Submit
                        form</Button>
                </div>
                }
            </Form>
        );
    }
}

export default ReferralForm;