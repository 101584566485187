import React from 'react'

const AlertTemplate = ({ message, options, style, close }) => {

    let messageDisplay = message;
    if (Array.isArray(message)) {
        messageDisplay = message.map(msg => {
            return <span key={msg}>{msg}</span>
        })
    }
    let typeStyle = options.type
    let estilo = {...style};
    let buttonStyle = {};
    let h1Style = {};
    let iStyle = {};
    let spanStyle = {};
    if (options.location !== undefined) {
        typeStyle += " relative";
        estilo = {...style, ...options.location};
        h1Style = {marginRight: "40px", marginLeft: "40px"};
        buttonStyle = {position: "absolute", top: "10px", right: "10px"};
        iStyle = {position: "absolute", top: "10px", left: "10px"};
        spanStyle = {marginRight: "10px", marginLeft: "10px"};
    } else {
        spanStyle = { flex: 2 };
    }
    return (
        <div style={estilo} className={"alert-popup " + typeStyle}>
            {options.type === 'info' && <i className={"fa fa-info-circle"} style={iStyle}>&nbsp;</i>}
            {options.type === 'success' && <i className={"fa fa-check-circle"}>&nbsp;</i>}
            {options.type === 'error' && <i className={"fa fa-exclamation-circle"}>&nbsp;</i>}
            <span style={spanStyle}>{options.header !== undefined &&
                <h1 style={h1Style}>{options.header}</h1>} {messageDisplay}</span>
            <button onClick={close} className={"alert-close"} style={buttonStyle}>
                <i className={"fa fa-times"}>&nbsp;</i>
            </button>
        </div>
    )
}

export default AlertTemplate