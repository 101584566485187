import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Loader from '../../components/Loader';
import {API} from '../../api/API'
import Metadata, {MetadataObj} from "../../components/Metadata";
import {
    CompileUTMData, DeleteUTMData
} from "../../Helpers";
import {useAlert} from "react-alert";
import Markdown from "../../components/Markdown";

const CoachingRegistrationForm = () => {
    let metadata = MetadataObj();
    metadata.PageTitle = 'Register your interest';
    metadata.PageDescription = 'Register your interest with Clear Horizon Academy.';

    return (
        <div className="main">
            <Metadata metadata={metadata} />
            <div className={"container"}>
                <header>
                    <h1>Register your interest in coaching</h1>
                </header>
                {FormComponent()}
            </div>
        </div>
    );
};

const FormComponent = () => {
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const alert = useAlert();

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries())
            const submitSuccess = await API.submitCoachingRegistrationData(formDataObj);
            if (submitSuccess.success) {
                setSubmitResponseStatus(2);
                setSuccessMessage(submitSuccess.message);
                DeleteUTMData();
                window.scrollTo(0, 0);
            } else {
                setErrorMessage(submitSuccess.message);
                setSubmitResponseStatus(3);
                window.scrollTo(0, 0);
            }
        }
        setValidated(true);
    };

    const checkValid = async (e) => {
        const form = e.currentTarget;
        setDisabled(true);
        if (form.checkValidity() !== false) {
            setDisabled(false);
        }
    };

    const alertMissingDetails = () => {
        alert.error('Please ensure all required details are completed', {header: 'Error:'});
    }

    const utmData = CompileUTMData();
    if (submitResponseStatus === 1) {
        return <Loader />
    } else if (submitResponseStatus === 2) {
        return <Markdown className={"form-submitted"} text={successMessage} />
    } else if (submitResponseStatus === 3) {
        return <Markdown className={"form-submitted"} text={errorMessage} />
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit} onChange={checkValid}>
                <Form.Group>
                    <Form.Control type="hidden" name = "utm_source" defaultValue={utmData.utmSource} />
                    <Form.Control type="hidden" name = "utm_medium" defaultValue={utmData.utmMedium} />
                    <Form.Control type="hidden" name = "utm_campaign" defaultValue={utmData.utmCampaign} />
                    <Form.Control type="hidden" name = "utm_content" defaultValue={utmData.utmContent} />
                    <Form.Control type="hidden" name = "utm_referrer" defaultValue={utmData.utmReferrer} />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>First name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="First name" name = "firstName" />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Last name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="Last name" name = "lastName" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Email<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Email" required
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "email" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Phone<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Phone" required name = "phone" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid phone number.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Organisation</Form.Label>
                        <Form.Control type="text" placeholder="Organisation" required name = "organisation" />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Role</Form.Label>
                        <Form.Control type="text" placeholder="Role" required name = "role" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6" controlId="messageField">
                        <Form.Label>What would you like to get out of the coaching?
                            <span className={"required-field"}>*</span></Form.Label>
                        <Form.Control as="textarea" rows={4} name = "message" required />
                    </Form.Group>
                </Form.Row>
                {!disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary"}>Submit form</Button>
                </div>
                }
                {disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary disabled"} onClick = {alertMissingDetails}>Submit
                        form</Button>
                </div>
                }
            </Form>
        );
    }
}

export default CoachingRegistrationForm;