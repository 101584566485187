import React, { useEffect, useState } from 'react';
import NotFound from "../NotFound";
import {CourseCarouselElement, PartnerCarouselElement, TestimonialCarouselElement} from '../../components/Carousel';
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../../components/Loader';
import Banner from '../../components/Banner';
import TextBlock from "../../components/TextBlock";
import Btn from "../../components/Btn";
import {FetchData} from "../../Helpers";
import Metadata from "../../components/Metadata";

export const CoursesOverviewPage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {CoursesOverviewPageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const CoursesOverviewPageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isCOPLoaded, setIsCOPLoaded] = useState(false);
    const [coursesOverviewItem, setCoursesOverviewItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let copURL = process.env.REACT_APP_API_PATH + "/courses-overview-pages/";
    const { token } = useParams();
    if (previewMode === true) {
        copURL += "preview/" + previewID + '/' + secret;
    } else {
        copURL += "finddeep";
    }

    useEffect(() => {
        FetchData(copURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setCoursesOverviewItem(result[0]);
                    } else {
                        setCoursesOverviewItem(result);
                    }
                    setIsCOPLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsCOPLoaded(true);
                }
            )
    }, [copURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isCOPLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!coursesOverviewItem.id) {
            return;
        }
        let heroHeader = coursesOverviewItem.HeroHeader && Banner(coursesOverviewItem.HeroHeader);
        let textBlock = coursesOverviewItem.Text && <TextBlock subHeader = {coursesOverviewItem.SubHeader}
                                                               text = {coursesOverviewItem.Text} />;
        let button1 = coursesOverviewItem.Button1 && Btn(coursesOverviewItem.Button1);
        let button2 = coursesOverviewItem.Button2 && Btn(coursesOverviewItem.Button2);
        let carousels = null;
        if (coursesOverviewItem.carousel_containers.length > 0) {
            carousels = coursesOverviewItem.carousel_containers.map((carousel) =>
                {
                    switch (carousel.Type) {
                        case "Partners":
                            return PartnerCarouselElement(carousel);
                        case "Testimonials":
                            return TestimonialCarouselElement(carousel);
                        default:
                            carousel.suppressButton = true;
                            return CourseCarouselElement(carousel);
                    }
                }
            );
        }
        return (
            <>
                <Metadata metadata={coursesOverviewItem.Metadata} />
                {heroHeader}
                <Container>
                    {textBlock}
                    <div className={"row text-center pb-5"}>
                        <div className={"col-12 col-sm-6"}>
                            {button1}
                        </div>
                        <div className={"col-12 col-sm-6"}>
                            {button2}
                        </div>
                    </div>
                </Container>
                {carousels}
            </>
        );
    }
}
