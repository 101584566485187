import React from 'react';
import Container from "react-bootstrap/Container";
import {Route} from "react-router-dom";

const Pages = [
    {name: 'Courses', url: '/my-courses'},
    {name: 'For organisations', url: '/for-organisations'},
    {name: 'Most Significant Change (MSC) course',
        url: '/courses/course-msc-online'},
    {name: 'The Complete Guide to MEL - Measurement, Evaluation and Learning course',
        url: '/courses/course-mel-online-full'},
    {name: 'Introduction to Measurement, Evaluation and Learning (MEL) course',
        url: '/courses/course-mel-module-1'},
    {name: 'Creating the "M" in MEL (Measurement, Evaluation and Learning) course',
        url: '/courses/course-mel-module-2'},
    {name: 'Putting the "E" in MEL (Measurement, Evaluation and Learning) course',
        url: '/courses/course-mel-module-3'},
    {name: 'Applying the "L" in MEL (Measurement, Evaluation and Learning) course',
        url: '/courses/course-mel-module-4'},
    {name: 'Evaluating Systems Change and Place-Based Approaches course',
        url: '/courses/course-pba-online'},
    {name: 'Using Data Analysis and Visualisation to Drive Social Impact course',
        url: '/courses/course-pbi-online'},
    {name: 'Developmental Evaluation course',
        url: '/courses/developmental-evaluation'},
    {name: 'Frequently Asked Questions (FAQs)', url: '/faqs'},
    {name: 'Our approach', url: '/our-approach'},
    {name: 'Our learners', url: '/our-learners'},
    {name: 'Our experts', url: '/experts'},
    {name: 'Our work', url: '/our-work'},
    {name: 'Resources', url: '/resources'},
    {name: 'Community', url: '/community'},
    {name: 'Register your interest', url: '/register-your-interest'},
    {name: 'Blog', url: '/blog'},
    {name: 'Online learning blog category',
        url: '/blog/category/Online%20learning'},
    {name: 'Evaluation blog category', url: '/blog/category/Evaluation'},
    {name: 'Digital disruption blog category',
        url: '/blog/category/Digital%20disruption'},
    {name: 'Sense-making through stories: an introduction to Most Significant Change blog post',
        url:
            '/blog/sense-making-through-stories-an-introduction-to-most-significant-change'},
    {name: '2020 clarity – the year to get clear on the change you make blog post',
        url: '/blog/2020-clarity-the-year-to-get-clear-on-the-change-you-make'},
    {name: 'Choose your own adventure – navigating shared measurement and evaluation in place-based approaches blog post',
        url:
            '/blog/choose-your-own-adventure-navigating-shared-measurement-and-evaluation-in-place-based-approaches'},
    {name: 'The north star for achieving community impact – why place-based and systems approaches are here to stay blog post',
        url:
            '/blog/the-north-star-for-achieving-community-impact-why-place-based-and-systems-approaches-are-here-to-stay'},
    {name: 'Jen Riley interviews Damien Sweeney on program logics blog post',
        url: '/blog/a-program-logic-can-paint-a-thousand-words'},
    {name: 'Developing, delivering and iterating a new learning product blog post',
        url: '/blog/developing-delivering-and-iterating-a-new-learning-product'},
    {name: 'Change is here and so are the robots blog post',
        url: '/blog/change-is-here-and-so-are-the-robots'},
    {name: 'Australian Evaluation Society – aes19 – International Evaluation Conference – Day 3 Reflection blog post',
        url: '/blog/aes19-international-evaluation-conference-day-3'},
    {name: 'Most Significant Change goes digital with Clear Horizon Academy blog post',
        url:
            '/blog/most-significant-change-goes-digital-with-clear-horizon-academy'},
    {name: 'Privacy policy', url: '/privacy'},
    {name: 'Terms and conditions', url: '/terms-and-conditions'},
    {name: 'Code of conduct', url: '/code-of-conduct'},
    {name: 'Contact us', url: '/contact-us'},
]

export const Sitemap = () => {
    const sitemap = Pages.map((page) => {
        return <li className={"sitemap-link"} key={page.url}>
            <a href={process.env.REACT_APP_EXTERNAL_PATH + page.url}>{page.name}</a>
        </li>
    });
    return (
        <div className={"sitemap"}>
            <Container>
                <ul className={"sitemap-links"}>
                    <li className={"sitemap-link"}>
                        <a href={process.env.REACT_APP_EXTERNAL_PATH}>Home</a>
                    </li>
                    {sitemap}
                </ul>
            </Container>
        </div>
    );
}

const sitemap = Pages.map((page) => {
    return <Route path={page.url} key={page.name}/>
});

export default (
    <Route>
        {sitemap}
    </Route>
)