import React, {useContext, useEffect, useMemo, useState} from 'react';
import Loader from "../../components/Loader";
import {
    BuildCountriesDropdown,
    BuildHowHeardDropdown,
    BuildOrgTypeDropdown,
    BuildSectorDropdown, CompileUTMData,
    FetchData, FormatDateToString
} from "../../Helpers";
import Container from "react-bootstrap/Container";
import Cart from "../../components/Cart";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import CheckoutButton from "../../components/CheckoutButton";
import InvoiceButton from "../../components/InvoiceButton";
import CartContext from "../../contexts/CartContext";
import {useAlert} from "react-alert";
import Metadata, {MetadataObj} from "../../components/Metadata";
import Markdown from "../../components/Markdown";
import Button from "react-bootstrap/Button";
import InfoPopup from "../../components/InfoPopup";

export const CheckoutPage = () => {
    const chaCart = Cart.GetCart();
    const [error, setError] = useState(null);
    const [isCartLoaded, setIsCartLoaded] = useState(false);
    const [cartData, setCartData] = useState(false);
    const [invoicePaid, setInvoicePaid] = useState(false);
    const [invoiceSuccessMessage, setInvoiceSuccessMessage] = useState(false);
    const [validated, setValidated] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [oldSubmittedData, setOldSubmittedData] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [showTotal, setShowTotal] = useState(false);
    const [orderTotal, setOrderTotal] = useState(null);
    const [instanceArray, setInstanceArray] = useState([]);
    const [discount, setDiscount] = useState(false);
    const [validCoupon, setValidCoupon] = useState(null);
    const alert = useAlert();
    const [, updateCount] = useContext(CartContext);
    let metadata = useMemo(() => {
        let mD = MetadataObj();
        mD.PageTitle = 'Checkout';
        return mD;
    }, []);
    useEffect(() => {
        if (!invoicePaid) {
            const submittedDataString = JSON.stringify(submittedData);
            if (submittedDataString !== oldSubmittedData) {
                getCheckoutItems(chaCart, {setCartData}, {setIsCartLoaded},
                    {setError}, instanceArray, submittedData, {setOrderTotal});
                setOldSubmittedData(submittedDataString);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [chaCart, invoicePaid, instanceArray, submittedData, oldSubmittedData]);

    const orderItems = useMemo(() => cartData?.items?.map((item) => {
        if (item.startDate.selected) {
            const addIA = instanceArray.concat(item.startDate.instanceID);
            setInstanceArray(addIA)
        }
        return <div key={"ordered-item-div-" + item.courseID} className={"ordered-item-container"}>
            <CheckoutOrderItem item = {item} alert={alert} validCoupon = {{validCoupon, setValidCoupon}}
                               total={{showTotal, setShowTotal}} cartData = {{cartData, setCartData}}
                               updateCount={updateCount} orderTotal={{orderTotal, setOrderTotal}}
                               instances={{instanceArray, setInstanceArray}}
                               discount={{discount, setDiscount}} disabled={{disabled, setDisabled}}
                               subData={{submittedData, setSubmittedData}} />
            {item.availableCoachingPackages.length !== 0 &&
            <CoachingItem quantity = {item.quantity} courseID = {item.courseID} item = {item.coachingPackage}
                          subData={{submittedData, setSubmittedData}} availableItems={item.availableCoachingPackages}
                          image = {cartData.coachingImage} cartData = {{cartData, setCartData}}
                          disabled={{disabled, setDisabled}} updateCount={updateCount} alert={alert}
                          discount={{discount, setDiscount}} />
            }
            </div>
    }), [alert, cartData, disabled, discount, instanceArray, orderTotal, showTotal, submittedData, updateCount,
        validCoupon]) || null;

    const orderForm = useMemo(() => {
        return <OrderForm chaCart={chaCart} invoice={{invoicePaid, setInvoicePaid}}
                   subData={{submittedData, setSubmittedData}} disabled={{disabled, setDisabled}}
                   alert={alert} discount={{discount, setDiscount}} validCoupon = {{validCoupon, setValidCoupon}}
                   invoiceSuccessMessage={{invoiceSuccessMessage, setInvoiceSuccessMessage}} />
    }, [alert, chaCart, disabled, discount, invoicePaid, invoiceSuccessMessage, submittedData, validCoupon]);



    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isCartLoaded) {
        return <Loader style = {loaderStyle} />;
    } else if (invoicePaid) {
        return (
            <div className="main">
                <Markdown className={"payment-success container"} text={invoiceSuccessMessage} />
            </div>
        )
    } else if (cartData) {
        if (cartData.items === undefined || cartData.items.length === 0) {
            return (
                <div className="main">
                    <Metadata metadata={metadata} />
                    <Container className={"payment-success"}>
                        <h1>Cart is empty</h1>
                    </Container>
                </div>
            );
        }

        return (
            <div className="main">
                <Metadata metadata={metadata} />
                <Container className={"payment-success"}>
                    <Form noValidate validated={validated}
                          onChange={(e) => setTimeout(() => handleUpdate(e,
                            'formUpdate',
                            {disabled, setDisabled},
                            {showTotal, setShowTotal},
                            {orderTotal, setOrderTotal},
                            {instanceArray, setInstanceArray},
                            chaCart,
                            {cartData, setCartData},
                            {isCartLoaded, setIsCartLoaded},
                            {error, setError},
                            {submittedData, setSubmittedData},
                            {validated, setValidated},
                              null,
                              null,
                              null,
                              null,
                              null,
                              alert,
                              {discount, setDiscount},
                              {validCoupon, setValidCoupon}), 300)
                    }>
                        <h1>Checkout</h1>
                        <div className={"ordered-items"} key={"ordered-items-div"}>
                            <h2>Order details:</h2>
                            {orderItems}
                            <div className={"ordered-item-total"}>
                                {showTotal && <>Total cost: <span className={"detail"}>&#36;{orderTotal}</span></>}
                            </div>
                            <div className={"ordered-item-total"}>
                                {discount && <Discount discount={discount} total={orderTotal} orderItems={cartData} />}
                            </div>
                        </div>
                        {orderForm}
                    </Form>
                </Container>
            </div>
        );
    }
}

export const CheckoutOrderItem = (props) => {
    const [quantity, setQuantity] = useState(props.item.quantity !== undefined ? props.item.quantity : 1);
    const [cost, setCost] = useState(null);
    const itemCost = props.item.amountPaid !== undefined ? props.item.amountPaid : props.item.itemCost;
    const [startDate, setStartDate] = useState('');
    const [dateSelected, setDateSelected] = useState(false);
    useEffect(() => {
        setCost(quantity * itemCost);
    }, [quantity, itemCost]);
    let singleStartDate = false;
    const startDates = useMemo(() => props.item.startDate.map((sD) => {
        if (props.instances.instanceArray.indexOf(sD.instanceID) !== -1) {
            setStartDate(sD.instanceID);
            setDateSelected(true);
        }
        let display = 'On demand';
        if (sD.selfPaced !== true) {
            display = FormatDateToString(sD.startDate);
        }
        return <option key={sD.instanceID + '-' + sD.startDate} value={sD.instanceID}>
            {display}
        </option>
    }), [props.item.startDate, props.instances.instanceArray, setStartDate, setDateSelected]);

    if (props.item.startDate.length === 1) {
        const sD = props.item.startDate[0];
        let display = 'On demand';
        if (sD.selfPaced !== true) {
            display = FormatDateToString(sD.startDate);
        }
        singleStartDate = {display: display, value: sD.instanceID};
    }
    const learners = [];
    if (quantity > 1) {
        for (let i = 0; i < quantity; i ++) {
            learners.push(<LearnerDetails courseID={props.item.courseID} key={i} learnerID={i}
                                          learner={props.item.learners[i]} />)
        }
    }
    const handleFormUpdate = (e, action) => {
        return handleUpdate(e,
            action,
            props.disabled,
            props.total,
            props.orderTotal,
            props.instances,
            null,
            props.cartData,
            null,
            null,
            props.subData,
            null,
            {quantity, setQuantity},
            props.item,
            props.updateCount,
            {cost, setCost},
            itemCost,
            props.alert,
            props.discount,
            props.validCoupon)
    }
    return <>
        <div className={"ordered-item checkout-page-ordered-item"}>
            <div className={"ordered-item-name"}>
                <a href = {process.env.REACT_APP_EXTERNAL_PATH + '/courses/' + props.item.url}>{props.item.courseName}</a>
            </div>
            <div className={"ordered-item-top"}>
                <img src={process.env.REACT_APP_IMG_PATH + props.item.image.url} alt={props.item.courseName}/>
            </div>
            <div className={"ordered-item-bottom"}>
                <div className={"ordered-item-details"}>
                    {!singleStartDate &&
                    <div className={"start-date" + (dateSelected ? " date-selected" : "")}>Start Date: <span className={"detail"}>
                            <Form.Control required as="select" placeholder="Select start date"
                                          name={"startDate-" + props.item.courseID} defaultValue={startDate}
                                          onChange={(e) => {
                                              if (e.target.value === '') {
                                                  setDateSelected(false);
                                              }
                                          }}
                            >
                                <option value={""}>Select start date</option>
                                {startDates}
                            </Form.Control>
                        </span>
                    </div>
                    }
                    {singleStartDate &&
                    <div className={"start-date single-start-date"}>Start Date: <span className={"detail"}>
                        {singleStartDate.display}</span>
                        <Form.Control type="hidden" name={"startDate-" + props.item.courseID}
                                      defaultValue={singleStartDate.value} />
                    </div>
                    }
                    <div className={"no-learners"}>
                        Number of learners: <span className={"detail"}>
                            <button className={"quantity-update quantity-remove"} onClick={e => {
                                if (document.activeElement === e.target) {
                                    handleFormUpdate(e, 'removeItem')
                                } else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }
                            }>-</button>
                            {quantity}
                            <input type={"hidden"} value={quantity} name={"quantity-" + props.item.courseID} />
                            <button className={"quantity-update quantity-add"}
                                    onClick={e => handleFormUpdate(e, 'addItem')
                                    }>+</button>
                        </span>
                    </div>
                    <div className={"duration"}>Duration: <span className={"detail"}>{props.item.duration}</span></div>
                    {dateSelected && <div className={"cost"}>Cost: <span className={"detail"}>&#36;{cost}</span></div>}
                </div>
            </div>
        </div>
        {quantity > 1 && <div className={"learner-list"}>
            <div className={"rubric"}>
                Please add details of each learner for <em>{props.item.courseName}</em> below. If learner details are unknown at this stage, please <a href="/contact-us">contact us</a>.
            </div>
            {learners}</div>
        }
    </>
}

const CoachingItem = (props) => {
    const items = props.availableItems.length? props.availableItems : props.cartData.cartData.coachingPackages;
    const image = props.cartData.cartData.coachingImage;
    const [showCoaching, setShowCoaching] = useState(props.item !== null);
    const quantity = props.quantity !== undefined ? props.quantity : 1;
    const [coachingPackage, setCoachingPackage] = useState(props.item !== null ? props.item.id : '');
    const [packageSelected, setPackageSelected] = useState(false);
    const [packageCost, setPackageCost] = useState(0);
    const alert = useAlert();
    const coachingPackages = useMemo(() => items.map((cP) => {
        if (cP.id === coachingPackage) {
            setPackageSelected(true);
            setPackageCost(cP.cost);
        }
        return <option key={cP.id} value={cP.id}>
            {cP.name}
        </option>
    }), [items, coachingPackage, setPackageSelected, setPackageCost]);
    const cost = packageCost * quantity;
    return <div className={"ordered-item checkout-page-ordered-item"} key = {'coaching-item-' + props.courseID}>
        {!showCoaching &&
        <div className={"add-coaching-button ordered-item-details"}>
            <Button onClick={() => {
                props.disabled.setDisabled(true);
                setShowCoaching(true);
            }}>Add coaching</Button>
            <i className={'fa fa-info-circle'}
               onClick={e => InfoPopup(e, "ordered-item-container", alert, items[0].type)}>
                &nbsp;
            </i>
        </div>
        }
        {showCoaching &&
            <div className={"coaching-checkout-item"}>
                <div className={"ordered-item-name"}>
                    Coaching package
                </div>
                <div className={"ordered-item-top"}>
                    <img src={process.env.REACT_APP_IMG_PATH + image.url} alt="Coaching package" />
                </div>
                <div className={"ordered-item-bottom"}>
                    <div className={"ordered-item-details"}>
                        <div className={"start-date" + (packageSelected ? " date-selected" : "")}>Tier: <span className={"detail"}>
                                <Form.Control required as = "select" placeholder="Select coaching package"
                                              name ={"coaching-" + props.courseID} defaultValue={coachingPackage}
                                              onChange={(e) => {
                                                  if (e.target.value === '') {

                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      setShowCoaching(false);
                                                      setCoachingPackage('');
                                                      setPackageSelected(false);
                                                      handleUpdate(e, 'updateCoachingPackage-' + props.courseID, props.disabled, null,
                                                          null, null, null, props.cartData, null, null, props.subData, null,
                                                          null, props.item, props.updateCount, null, null, props.alert, props.discount);
                                                  } else {
                                                      setCoachingPackage(parseInt(e.target.value));
                                                      Cart.AddToCart(props.courseID + '-' + e.target.value,
                                                          props.updateCount)
                                                  }
                                              }
                                              }>
                                    <option value={""}>Select coaching package</option>
                                    {coachingPackages}
                                </Form.Control>
                            </span>
                        </div>
                        {packageSelected && <div className={"cost"}>Cost: (&#36;{packageCost} x {quantity}) = <span className={"detail"}>&#36;{cost}</span></div>}
                    </div>
                    <div className={"remove-coaching-button ordered-item-details"}>
                        <button onClick={(e) => {
                            setShowCoaching(false);
                            setCoachingPackage('');
                            setPackageSelected(false);
                            handleUpdate(e, 'updateCoachingPackage-' + props.courseID, props.disabled, null,
                                null, null, null, props.cartData, null, null, props.subData, null,
                                null, props.item, props.updateCount, null, null, props.alert, props.discount);
                        }}>Remove coaching</button>
                    </div>
                </div>
            </div>
        }
    </div>
}

export const Discount = (props) => {
    let discountDollars = 0;
    let discountablePortion = 0;
    if (props.discount.type === 'amount') {
        discountDollars = parseFloat(props.discount.discount)
    } else if (!props.discount.validInstances.length && !props.discount.validPackages.length) {
        discountDollars = props.total * parseFloat(props.discount.discount) / 100;
    } else {
        for (let i = 0; i < props.orderItems.items.length; i ++) {
            const orderItem = props.orderItems.items[i];
            const startDates = orderItem.startDate;
            for (let j = 0; j < startDates.length; j ++) {
                const startDate = startDates[j];
                if (startDate.selected && props.discount.validInstances.indexOf(startDate.instanceID) !== -1) {
                    const silverPackageCost = orderItem.silver ? orderItem.silverPackageCost : 0;
                    discountablePortion += orderItem.cost - silverPackageCost;
                    discountDollars += (orderItem.cost - silverPackageCost) * parseFloat(props.discount.discount) / 100;
                }
            }
            const coachingPackage = orderItem.coachingPackage;
            if (coachingPackage !== null && props.discount.validPackages.indexOf(coachingPackage.id) !== -1) {
                discountDollars += coachingPackage.cost * parseFloat(props.discount.discount) / 100;
            }
        }
    }
    const discountedTotal = props.total - discountDollars
    return (
        <>
            {discountablePortion !== 0 &&
                <div>Total excluding extras: <span className={"detail"}>&#36;{discountablePortion}</span></div>
            }
            <div>Less discount: <span className={"order-discount"}>&#36;{discountDollars}</span></div>
            <div>Final total: <span className={"detail"}>&#36;{discountedTotal}</span></div>
        </>
    )
}

const getCheckoutItems = (chaCart, cD, cL, err, instanceArray, formData, oT) => {
    FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/cart/checkout/'
        + chaCart + '/' + JSON.stringify(instanceArray), 'POST', null, formData)
        .then(
            (result) => {
                cD.setCartData(result);
                oT.setOrderTotal(result.total);
                cL.setIsCartLoaded(true);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                err.setError(error);
                cL.setIsCartLoaded(true);
            }
        )
}

const getCoupon = async (coupon, formData) => {
    return await FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/coupon/'
        + coupon + '/', 'POST', null, formData);
}

const handleUpdate = async (e, action, d, sT, oT, iA, cart, cD, cL, err, sD, v, q, item, updateCount, c, itemCost,
                            alert, disc, validCoupon) => {
    if (action === 'addItem' || action === 'removeItem') {
        e.preventDefault();
        e.stopPropagation();
    }
    let coachingCourseID = null;
    if (action.indexOf('updateCoachingPackage-') === 0) {
        coachingCourseID = action.substr(22);
        action = 'updateCoachingPackage';
    }
    const form = e.target.form;
    const fD = new FormData(form);
    const formData = Object.fromEntries(fD.entries());
    let quantity = null;
    let couponValid;
    let checkValidity = true;

    for (const [key, value] of Object.entries(formData)) {
        if ((key !== 'purchaseOrder' && key !== 'coupon' && key !== 'utm_source' && key !== 'utm_campaign' &&
            key !== 'utm_content' && key !== 'utm_medium' && key !== 'utm_referrer' && key !== 'company' &&
            key.indexOf('position') !== 0 && key !== 'organisationType' && key !== 'industry' && key !== 'postcode' &&
            key !== 'country') && value === '') {
                checkValidity = false;
        }
    }
    switch (action) {
        case 'addItem':
            quantity = parseInt(q.quantity);
            if (quantity < process.env.REACT_APP_MAX_LEARNERS) {
                if (quantity === process.env.REACT_APP_LEARNER_DISCOUNT_NUMBER - 1) {
                    alert.error(
                        ['Quantities of five or more of the same course qualify for a special discount. Please ',
                            <a href ="/contact-us">contact us</a>,
                            ' to arrange it.']
                    );
                }
                c.setCost((quantity + 1) * itemCost);
                oT.setOrderTotal(oT.orderTotal + itemCost)
                q.setQuantity(quantity + 1);
                formData['quantity-' + item.courseID] = quantity + 1;
                d.setDisabled(false);
                couponValid = await verifyCoupon(formData, form, alert, disc, sD, checkValidity);
                validCoupon.setValidCoupon(couponValid);
                if (!couponValid || !form.checkValidity()) {
                    d.setDisabled(true);
                }
            } else {
                alert.error(
                    ['Quantities of five or more of the same course qualify for a special discount. Please ',
                        <a href ="/contact-us">contact us</a>,
                        ' to arrange it.']
                );
            }
            break;
        case 'removeItem':
            quantity = parseInt(q.quantity);
            if (quantity - 1 < 1) {
                for (let i = 0; i < cD.cartData.items.length; i++) {
                    let cartItem = cD.cartData.items[i];
                    if (cartItem.courseID === item.courseID) {
                        let courseID = cartItem.courseID;
                        if (cartItem.coachingPackage !== null) {
                            courseID += '-' + cartItem.coachingPackage.id;
                        }
                        Cart.RemoveFromCart(courseID, updateCount, cD);
                        delete cD.cartData.items[i];
                        sT.setShowTotal(updateShowTotal(e, item.courseID));
                    }
                }
            }
            c.setCost((quantity - 1) * itemCost)
            oT.setOrderTotal(oT.orderTotal - itemCost)
            q.setQuantity(quantity - 1);
            formData['quantity-' + item.courseID] = quantity - 1;
            d.setDisabled(false);
            couponValid = await verifyCoupon(formData, form, alert, disc, sD, checkValidity);
            validCoupon.setValidCoupon(couponValid);
            if (!couponValid || !form.checkValidity()) {
                d.setDisabled(true);
            }
            break;
        case 'updateCoachingPackage':
            // d.setDisabled(true);
            // formData.coupon = e.target.value;
            // couponValid = await VerifyCoupon(formData, form, alert, disc, sD, true);
            delete formData['coaching-' + coachingCourseID];
            sD.setSubmittedData(formData);
            if (item !== null) {
                Cart.RemoveFromCart('coaching-' + coachingCourseID + '-' + item.id, updateCount, cD);
            }
            couponValid = await verifyCoupon(formData, form, alert, disc, sD, checkValidity);
            if (!couponValid || !form.checkValidity()) {
                d.setDisabled(true);
            } else {
                d.setDisabled(false);
            }
            break;
        case 'updateCoupon':
            // d.setDisabled(true);
            // formData.coupon = e.target.value;
            couponValid = await verifyCoupon(formData, form, alert, disc, sD, true);
            validCoupon.setValidCoupon(couponValid);
            if (!couponValid || !form.checkValidity()) {
                d.setDisabled(true);
            } else {
                d.setDisabled(false);
            }
            break;
        default:
            d.setDisabled(true);
            let instanceArray = [];
            // let total = 0;
            sT.setShowTotal(true)
            for (const [key, value] of Object.entries(formData)) {
                if (key.indexOf('startDate-') === 0) {
                    if (value !== "") {
                        instanceArray.push(parseInt(value));
                    } else {
                        sT.setShowTotal(false);
                    }
                }
            }
            // oT.setOrderTotal(total);
            iA.setInstanceArray(instanceArray);
            // GetCheckoutItems(cart, cD, cL, err, instanceArray, formData, oT);
            if (checkValidity) {
                if (e.target.name !== 'coupon') {
                    disc.setDiscount(false);
                    couponValid = await verifyCoupon(formData, form, alert, disc, sD, checkValidity);
                    validCoupon.setValidCoupon(couponValid);
                    if (!couponValid || !form.checkValidity()) {
                        d.setDisabled(true);
                    } else {
                        d.setDisabled(false);
                    }
                } else if (!form.checkValidity()) {
                    d.setDisabled(true);
                } else {
                    d.setDisabled(false);
                }

                v.setValidated(true);
            } else {
                sD.setSubmittedData(formData);
            }
            break;
    }
};

const LearnerDetails = (props) => {
    let email = props.learner !== undefined ? props.learner.email : null;
    let firstName = props.learner !== undefined ? props.learner.firstName : null;
    let lastName = props.learner !== undefined ? props.learner.lastName : null;
    let position = props.learner !== undefined ? props.learner.position : null;
    return (
        <Form.Row>
            <Form.Group as={Col} md="6" lg="3">
                <Form.Control required type="text" placeholder="First name (required)" defaultValue={firstName}
                              name={"firstName-" + props.learnerID + "-course-" + props.courseID} />
            </Form.Group>
            <Form.Group as={Col} md="6" lg="3">
                <Form.Control required type="text" placeholder="Last name (required)" defaultValue={lastName}
                              name={"lastName-" + props.learnerID + "-course-" + props.courseID} />
            </Form.Group>
            <Form.Group as={Col} md="6" lg="3">
                <Form.Control type="email" placeholder="Email (required)" required
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" defaultValue={email}
                              name={"email-" + props.learnerID + "-course-" + props.courseID} />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" lg="3">
                <Form.Control type="text" placeholder="Position" defaultValue={position}
                              name={"position-" + props.learnerID + "-course-" + props.courseID} />
            </Form.Group>
            <hr className={"learner-list-divider"} />
        </Form.Row>
    )
}

const loaderStyle = {paddingTop: '76px'};

const OrderForm = (props) => {
    const [loadingInvoice, setLoadingInvoice] = useState(false);
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const chaCart = props.chaCart;
    const countries = BuildCountriesDropdown();
    const howHeard = BuildHowHeardDropdown();
    const organisationTypes = BuildOrgTypeDropdown();
    const sectors = BuildSectorDropdown();
    const utmData = CompileUTMData();
    return <>
        <Form.Control type="hidden" name = "utm_source" defaultValue={utmData.utmSource} />
        <Form.Control type="hidden" name = "utm_medium" defaultValue={utmData.utmMedium} />
        <Form.Control type="hidden" name = "utm_campaign" defaultValue={utmData.utmCampaign} />
        <Form.Control type="hidden" name = "utm_content" defaultValue={utmData.utmContent} />
        <Form.Control type="hidden" name = "utm_referrer" defaultValue={utmData.utmReferrer} />
        <h2>Your details</h2>
        <Form.Row>
            <Form.Group as={Col} md="6">
                <Form.Label>First name<span className={"required-field"}>*</span></Form.Label>
                <Form.Control required type="text" placeholder="Required" name = "firstName" />
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label>Last name<span className={"required-field"}>*</span></Form.Label>
                <Form.Control required type="text" placeholder="Required" name = "lastName" />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6">
                <Form.Label>Email<span className={"required-field"}>*</span></Form.Label>
                <Form.Control type="email" placeholder="Required" required
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "email" />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label>Phone<span className={"required-field"}>*</span></Form.Label>
                <Form.Control type="text" placeholder="Required" required name = "phone" />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid phone number.
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6">
                <Form.Label>Organisation name</Form.Label>
                <Form.Control type="text" placeholder="Required for invoice payments" name = "company"
                              className={"no-validation"} />
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label>What is your role in the organisation?</Form.Label>
                <Form.Control type="text" placeholder="Optional" name = "position" className={"no-validation"} />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6">
                <Form.Label>Organisation type</Form.Label>
                <Form.Control as = "select" placeholder="Optional" name = "organisationType"
                              className={"no-validation"} >
                    {organisationTypes}
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label>What sector do you primarily work in?</Form.Label>
                <Form.Control as = "select" placeholder="Optional" name = "industry" className={"no-validation"} >
                    {sectors}
                </Form.Control>
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6">
                <Form.Label>Postcode / Zip</Form.Label>
                <Form.Control type="text" placeholder="Optional" name = "postcode" className={"no-validation"} />
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label>Country</Form.Label>
                <Form.Control as = "select" placeholder="Optional" name = "country" className={"no-validation"} >
                    {countries}
                </Form.Control>
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6">
                {process.env.REACT_APP_ACCEPT_INVOICE_PAYMENTS &&
                    <>
                        <Form.Label>
                            Purchase order number <span className={"small-grey"}>(invoice only)</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Optional purchase order number for invoices"
                                      name = "purchaseOrder" className={"no-validation"} />
                    </>
                }
            </Form.Group>
            <Form.Group as={Col} md="6">
                        <Form.Label>
                            Coupon code <span className={"small-grey"}>(applicable to course fees only)</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Optional" name = "coupon" onBlur={e => handleUpdate(e,
                            'updateCoupon',
                            props.disabled,
                            null,
                            null,
                            null,
                            chaCart,
                            null,
                            null,
                            null,
                            props.subData,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            props.alert,
                            props.discount,
                            props.validCoupon)
                        } />
                        <Form.Control.Feedback type="invalid">
                            Coupon is invalid
                        </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="6" controlId="howHeardField">
                <Form.Label>Where did you first hear about the course?<span className={"required-field"}>*</span></Form.Label>
                <Form.Control required as = "select" placeholder="Select one" name = "howHeard">
                    {howHeard}
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="tAndCsCheckbox">
                <Form.Check type="checkbox" className={"tAndCs"} id={'t-and-c-checkbox'} name = 'tAndCs' required
                    label={
                        <span>I confirm I have read and agree to the Clear Horizon Academy <a href='/terms-and-conditions' target='_blank'>terms and conditions</a>.
                            <span className='required-field'>*</span></span>} />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} md="12" className={"text-right"}>
                <CheckoutButton disabled={props.disabled.disabled} chaCart={chaCart} formData={props.subData.submittedData}
                                loadingCheckout={{loadingCheckout, setLoadingCheckout}} discount={props.discount}
                                loadingInvoice={{loadingInvoice, setLoadingInvoice}}/>
                {process.env.REACT_APP_ACCEPT_INVOICE_PAYMENTS &&
                    <InvoiceButton disabled={props.disabled.disabled} chaCart={chaCart} formData={props.subData.submittedData}
                                   invoice={props.invoice} loadingCheckout={{loadingCheckout, setLoadingCheckout}}
                                   loadingInvoice={{loadingInvoice, setLoadingInvoice}} discount={props.discount}
                                   invoiceSuccessMessage={props.invoiceSuccessMessage} />}
            </Form.Group>
        </Form.Row>
    </>
}

const updateShowTotal = (e, courseID) => {
    const fD = new FormData(e.target.form);
    const formData = Object.fromEntries(fD.entries());
    let showTotal = true;
    for (const [key, value] of Object.entries(formData)) {
        if (key.indexOf('startDate-') === 0) {
            if (value === "" && parseInt(key.substr(10)) !== courseID) {
                showTotal = false;
            }
        }
    }
    return showTotal;
}

const verifyCoupon = async (formData, form, alert, disc, sD, checkValidity) => {
    let couponValid = true;
    form.coupon.setCustomValidity('');
    disc.setDiscount(false);
    sD.setSubmittedData(formData);
    if (formData.coupon.length) {
        const response = await getCoupon(formData.coupon, formData);
        if (response.error !== false || (!response.chaValid && !response.stripeValid)) {
            if (checkValidity && response.error !== false) {
                alert.error('Coupon error: ' + response.error);
            }
            form.coupon.setCustomValidity(response.error);
            couponValid = false;
        } else {
            form.coupon.setCustomValidity('');
            disc.setDiscount({
                cha: response.chaValid,
                stripe: response.stripeValid,
                discount: response.discount,
                sessionID: response.sessionID,
                type: response.type,
                validInstances: response.validInstances || [],
                validPackages: response.validPackages || []
            });
        }
    }
    return couponValid;
}
