import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container'
import {useParams} from "react-router-dom";
import Loader from './Loader';
import IconTextSection from './IconText';
import {FetchData, ProcessLink} from "../Helpers";
import NotFound from "../pages/NotFound";
import Markdown from "./Markdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Footer = (previewMode, previewID, secret) => {
    return (
        FooterComponent(previewMode, previewID, secret)
    )
}

const FooterComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isFooterLoaded, setIsFooterLoaded] = useState(false);
    const [footerItem, setFooterItem] = useState([]);
    const [footerNotFound, setFooterNotFound] = useState(false);

    let footerURL = process.env.REACT_APP_API_PATH + "/footers/findordered/";
    let { token } = useParams();
    if (previewMode === true) {
        footerURL = process.env.REACT_APP_API_PATH + "/footers/preview/" + previewID + '/' + secret;
    }
    useEffect(() => {
        FetchData(footerURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setFooterNotFound(true);
                    } else if (result.length !== undefined) {
                        setFooterItem(result[0]);
                    } else {
                        setFooterItem(result);
                    }
                    setIsFooterLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsFooterLoaded(true);
                }
            )
    }, [footerURL, token])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isFooterLoaded) {
        return <Loader />;
    } else if (footerNotFound) {
        return <NotFound />;
    } else {
        const topFooterItems = footerItem.topFooters.map((footerSection) =>
            {return <TopFooterSection header={footerSection.Header} items={footerSection.Items}
                                      key = {footerSection.id} siteInfo={footerItem.siteInfo} />}
        );

        const newsletterForm = (
            <div className={"top-footer"} key={"footer-form"}>
                <h3 className={"green"}>Sign up to our newsletter</h3>
                <Form>
                    <Button type="submit" className={"btn-footer-form"}
                            href={"https://info.clearhorizonacademy.com/ch-subscribe"} target={"_blank"}>
                        Subscribe
                    </Button>
                </Form>
            </div>
        );
        topFooterItems.push(newsletterForm);
        const bottomWidth = Math.round(100 / (footerItem.bottomFooters.length + 1)) + '%';
        const middleFooterItems = footerItem.middleFooters.map((footerSection) =>
            {return <MiddleFooterSection header={footerSection.Header} items={footerSection.Items}
                                         key = {footerSection.id} />}
        );
        const bottomFooterItems = footerItem.bottomFooters.map((footerSection) =>
            {return <BottomFooterSection items={footerSection.Items} width = {bottomWidth} key = {footerSection.id} />}
        );
        const year = new Date().getFullYear();
        const bottomStyle = {
            width: bottomWidth
        };
        return (
            <div className={"footer-container"}>
                <Container className={"top-footer-container"}>
                    {topFooterItems}
                </Container>
                <div className={"middle-footer-container"}>
                    {middleFooterItems}
                </div>
                <Container className={"bottom-footer-container"}>
                    <div className={"copyright-footer"} style={bottomStyle}>&copy; {year} - Clear Horizon Academy</div>
                    {bottomFooterItems}
                </Container>
            </div>
        );
    }
}

const ElementLinkSection = (props) => {
    const link = ProcessLink(props.link, props.text, props.external);
    return (
        <div className={"top-footer-section top-footer-link-section"}>
            <a href = {link.url} target={link.target}>{link.text}</a>
        </div>
    )
}

const ElementImageLinkSection = (props) => {
    const link = ProcessLink(props.link, null, props.external);
    return (
        <div className={"bottom-footer-section"}>
            <a href = {link.url} target={link.target}><i className={"fab fa-" + props.icon}>&nbsp;</i></a>
        </div>
    )
}

const IconLinkSection = (props) => {
    const link = ProcessLink(props.link.URL, props.link.LinkText, props.link.ExternalLink);
    return (
        <div className={"top-footer-section"}>
            <i className={"fa fa-" + props.icon}>&nbsp;</i><a href = {link.url} target={link.target}>{link.text}</a>
        </div>
    )
}

const BottomFooterSection = (props) => {
    const bottomFooterItems = props.items.map(
        (footerSection) => {
            switch (footerSection.__component) {
                case 'element.icon-text':
                    return <IconTextSection class = "top-footer-section" icon={footerSection.Icon.Icon}
                                            text={footerSection.Text} key = {footerSection.id} />;
                case 'element.icon-link':
                    return <IconLinkSection icon={footerSection.Icon.Icon} link={footerSection.link}
                                            key = {footerSection.id} />;
                case 'element.image-link':
                    return <ElementImageLinkSection icon={footerSection.Icon.Icon} link={footerSection.link}
                                                    external={footerSection.ExternalLink} key = {footerSection.id} />;
                default:
                    return <ElementLinkSection text={footerSection.LinkText} link={footerSection.URL}
                                               external={footerSection.ExternalLink} key = {footerSection.id} />;
            }
        }
    );
    const style = {
        width: props.width
    };
    return (
        <div className="bottom-footer" style={style}>
            {bottomFooterItems}
        </div>
    );
};

const MiddleFooterItem = (props) => {
    return (
        <div className={"middle-footer-section"}>
            <img src = {process.env.REACT_APP_IMG_PATH + props.img} alt={props.header} />
            <div className={"middle-footer-text"}>
                <Markdown renderers={{paragraph: 'span'}} text={props.text} />
            </div>
        </div>
    )
}

const MiddleFooterSection = (props) => {
    const topFooterItems = props.items.map(
        (footerSection) => {
            return <MiddleFooterItem header = {footerSection.header} text={footerSection.Text}
                                     img={footerSection.HeaderImage.url} key = {footerSection.id} />;
        }
    );
    return (
        <div className="middle-footer">
            {topFooterItems}
        </div>
    );
};

const TopFooterSection = (props) => {
    const topFooterItems = props.items.map(
        (footerSection) => {
            switch (footerSection.__component) {
                case 'element.icon-text':
                    return <IconTextSection class = "top-footer-section" icon={footerSection.Icon.Icon}
                                            text={footerSection.Text} siteInfo={props.siteInfo}
                                            key = {footerSection.__component + footerSection.id} />;
                case 'element.icon-link':
                    return <IconLinkSection icon={footerSection.Icon.Icon} link={footerSection.Link}
                                            key = {footerSection.id} />;
                case 'element.image-link':
                    return <ElementImageLinkSection icon={footerSection.Icon.Icon} link={footerSection.Link}
                                                    external={footerSection.ExternalLink} key = {footerSection.id} />;
                default:
                    return <ElementLinkSection text={footerSection.LinkText} link={footerSection.URL}
                                               external={footerSection.ExternalLink} key = {footerSection.id} />;
            }
        }
    );
    return (
        <div className="top-footer">
            <h3>{props.header}</h3>
            {topFooterItems}
        </div>
    );
};

export default Footer;