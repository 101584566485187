import React from 'react';
import Markdown from "./Markdown";

export const ImageHeaderTextSection = (props) => {
    let header = props.header !== null && props.header;
    let img = <img src={process.env.REACT_APP_IMG_PATH + props.src} alt={props.header} />
    if (props.link) {
        let target = '';
        if (props.external) {
            target = '_blank';
        }
        header = <a href={props.link} target={target}>{header}</a>
        img = <a href={props.link} target={target}>{img}</a>
    }
    const estilo = props.columns ? {
        width: Math.round(100 / props.columns) + '%'
    } : {};
    return (
        <div className={"image-section"} style={estilo}>
            {img}
            <span className={"image-section-header"}>
                <h4>{header}</h4>
            </span>
            {props.subHeader !== null && <Markdown renderers={{paragraph: 'div'}} text={props.subHeader}
                                                   className={"image-section-subheader"} />}
            <Markdown renderers={{paragraph: 'div'}} text={props.text} />
        </div>
    )
}

export default ImageHeaderTextSection;