import React, {useEffect, useState} from 'react';
import NotFound from "./NotFound";
import {
    CourseCarouselElement, MentorCarouselElement,
    OurApproachCarouselElement,
    PartnerCarouselElement,
    TestimonialCarouselElement
} from '../components/Carousel';
import Loader from '../components/Loader';
import {useParams} from "react-router-dom";
import {FetchData, OpenPage} from "../Helpers";
import Container from "react-bootstrap/Container";
import Markdown from "../components/Markdown";
import ImageHeaderTextSection from "../components/ImageHeaderText";
import Video from "../components/Video";
import Metadata from "../components/Metadata";

export const HomePage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {HomePageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const HomePageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [key, setKey] = useState(1);
    let hpURL = process.env.REACT_APP_API_PATH + "/home-pages/";
    const { token } = useParams();
    if (previewMode === true) {
        hpURL += "preview/" + previewID + '/' + secret;
    } else {
        hpURL += "finddeep";
    }

    useEffect(() => {
        FetchData(hpURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setItem(result[0]);
                    } else {
                        setItem(result);
                    }
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [hpURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        const headerSection = HeaderSectionContainer(item.HeaderSection);
        let courseExperience = CourseExperience(item.CourseExperience, {key, setKey} );
        let courseCarousel = null;
        let testimonialCarousel = null;
        let ourApproachCarousel = null;
        let partnerCarousel = null;
        let expertsCarousel = null;
        if (item.carousel_containers.length > 0) {
            for (let i = 0; i < item.carousel_containers.length; i ++) {
                const carousel = item.carousel_containers[i];
                switch (carousel.Type) {
                    case "Partners":
                        partnerCarousel = PartnerCarouselElement(carousel);
                        break;
                    case "Testimonials":
                        testimonialCarousel = TestimonialCarouselElement(carousel);
                        break;
                    case "OurApproach":
                        ourApproachCarousel = OurApproachCarouselElement(carousel);
                        break;
                    case "Mentors":
                        expertsCarousel = MentorCarouselElement(carousel);
                        break;
                    default:
                        courseCarousel = CourseCarouselElement(carousel);
                        break;
                }
            }
        }
        return (
            <>
                <Metadata metadata={item.Metadata} />
                {headerSection}
                <a id={"for-individuals"} href={"/"}
                   onClick={(e)=>{e.preventDefault();}} style={{cursor:'auto'}}>
                    {partnerCarousel}
                </a>
                {courseCarousel}
                {ourApproachCarousel}
                <div className={"approach-experience"} style={
                    {backgroundImage: `url("` + process.env.REACT_APP_EXTERNAL_PATH + `/rainbow-background.png")`}
                }>
                    <Container>
                        <h1>What our learners say</h1>
                        <div className={"row"}>
                            <div className={"col-12 col-lg-6"}>
                                {testimonialCarousel}
                            </div>
                            <div className={"col-12 col-lg-6"}>
                                {courseExperience}
                            </div>
                        </div>
                    </Container>
                </div>
                {expertsCarousel}
                <OurExpertise imageSections={item.OurExpertise.ImageHeaderText} />
                <CallToAction />
            </>
        );
    }
}

const CallToAction = () => {
    return (
        <div className={"orange-banner-section"}>
            <Container>
                <div className={"row"}>
                    <div className={"col-12 col-sm-6"}>
                        <h1>Individuals</h1>
                        <a className="btn white-btn" href = {"https://learn.clearhorizon.com.au/courses"}>Find The Right Course For You</a>
                    </div>
                    <div className={"col-12 col-sm-6"}>
                        <h1>Organisations</h1>
                        <a className="btn white-btn" href = {process.env.REACT_APP_EXTERNAL_PATH + "/contact-us"}>Contact Us To Build Your Team's Skills</a>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const CourseExperience = (item, key) => {
    let estilo = item.Image !== undefined ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + item.Image.url + `")`
    } : {};
    let video = (
        <div className={"course-experience-image"} style = {estilo}>
            <div className = "background-overlay">&nbsp;</div>
            <Markdown renderers={{paragraph: 'span'}} text={item.Text} className={"course-experience-testimonial"} />
        </div>
    )
    video = <Video url={item.VideoURL} videoKey={key} video={video} />;
    return (
            <div className={"course-experience-image-container"}>
                {video}
            </div>
    )
}

const HeaderSectionButton = (props) => {
    const colour = props.colour !== undefined ? " " + props.colour : "";
    return <div className={"col-12 col-sm-6"}>
        <div className={"header-btn-container" + colour}>
            <button className="header-btn" onClick={
                () => OpenPage(props.section.Link, props.section.OpenInNewTab)
            }>
                {props.section.Text}
            </button>
            <div className={"header-btn-blurb"}>{props.section.Blurb}</div>
        </div>
    </div>
}

const HeaderSectionContainer = (props) => {
    let estilo = props.HeaderBackground !== undefined ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.HeaderBackground.url + `")`
    } : {};
    return (
        <>
            <div className = "hero-header home-hero-header" style = {estilo} key = {props.id}>
                <div className = "background-overlay">&nbsp;</div>
            </div>
            <div className="row header-row">
                <HeaderSectionLeft header = {props.HeaderLeft.Header} subheader = {props.HeaderLeft.SubHeader}
                                   button = {props.HeaderLeft.HeaderButton} />
                <HeaderSectionRight header = {props.HeaderRight.Header} subheader = {props.HeaderRight.SubHeader}
                                    button = {props.HeaderRight.HeaderButton}
                                    backgroundimage = {props.HeaderRight.BackgroundImage} />
            </div>
        </>
    );
}

const HeaderSectionLeft = (props) => {
    let buttonColourArray = ['green', 'orange'];
    const buttons = props.button === null ? null : props.button.map((section, idx) => {
        return <HeaderSectionButton section={section} colour={buttonColourArray[idx]} key={section.id} />
    });
    return (
        <div className="col-12 col-lg-8">
            <div className="header-details text-left">
                <h1>{props.header}</h1>
                <div className={"sub-header"}>{props.subheader}</div>
                <div className={"row mt-3"}>
                    {buttons}
                </div>
            </div>
        </div>
    );
};

const HeaderSectionRight = (props) => {
    let estilo = props.backgroundimage !== undefined ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.backgroundimage.url + `")`,
        backgroundSize: `cover`
    } : {};
    return (
        <div className="col-12 col-lg-4 header-background-overlay" style={estilo}>
            <div className = "header-details text-left featured-course">
                <h3 className="green-background-header">{props.header}</h3>
                <h2>{props.subheader}</h2> {
                    props.button !== null &&
                        <button className="header-btn" onClick = {
                            () => OpenPage(props.button.Link, props.button.OpenInNewTab, props.button.ExternalLink)
                        }>{props.button.Text}</button>
                }
            </div>
        </div>
    );
};

const OurExpertise = (props) => {
    const colNo = Math.floor(12 / props.imageSections.length);
    const imageSections = props.imageSections.map((section) => {
        return <div className={"col-12 col-md-" + colNo} key = {"div" + section.id}>
            <ImageHeaderTextSection src={section.Image.url} text={section.Text} header={section.Header} key = {section.id} />
        </div>
    });
    return (
        <div className={"who-this-course-is-for our-expertise"}>
            <Container>
                <h1>Our Work</h1>
                <div className={"row image-section-container"}>
                    {imageSections}
                </div>
                <div className="button-container">
                    <button className="dark-font-button" onClick={
                        () => OpenPage('our-work', false)
                    }>
                        Our Work
                    </button>
                </div>
            </Container>
        </div>
    )
}
