import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AlertTemplate from "./components/AlertTemplate";
import { Provider as AlertProvider } from 'react-alert'
import {
    BrowserRouter as Router
} from "react-router-dom";

const AlertOptions = {
    position: 'top right',
    timeout: 0,
    offset: '10px',
    transition: 'fade'
}

ReactDOM.render(
  <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...AlertOptions}>
          <Router>
                <App />
          </Router>
      </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
