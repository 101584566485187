import React, {useEffect, useState} from 'react';
import NotFound from "../NotFound";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../../components/Loader';
import {FetchData} from "../../Helpers";
import Banner from "../../components/Banner";
import Markdown from "../../components/Markdown";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Metadata, {MetadataObj} from "../../components/Metadata";

const CommentFormURL = '/blog-comments/submitcomment';

export const Post = (previewMode, previewURL, secret) => {
    return (
        <div className="main">
            {BlogPostComponent(previewMode, previewURL, secret)}
        </div>
    );
};

const BlogPostComponent = (previewMode, previewURL, secret) => {
    const [error, setError] = useState(null);
    const [isPostLoaded, setPostLoaded] = useState(false);
    const [blogPost, setBlogPost] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let blogPostURL = process.env.REACT_APP_API_PATH + "/blog-posts/";
    let { token, slug } = useParams();
    if (previewMode === true) {
        blogPostURL += "preview/" + previewURL + '/' + secret;
    } else {
        blogPostURL += "findurl/" + slug;
    }
    useEffect(() => {
        FetchData(blogPostURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setBlogPost(result[0]);
                    } else {
                        setBlogPost(result);
                    }
                    setPostLoaded(true);
                },
                (error) => {
                    if (error.status === 404 || error.status === 500) {
                        setNotFound(true);
                    } else {
                        setError(error);
                    }
                    setPostLoaded(true);
                }
            )
    }, [blogPostURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isPostLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!blogPost.id) {
            return;
        }
        let heroProps = {
            HeroImage: blogPost.HeroHeader.HeroImage,
            Header: blogPost.HeroHeader.Header
        }
        let heroHeader = blogPost.HeroHeader.HeroImage && Banner(heroProps);
        const avatar = blogPost.Author.avatar !== null ? process.env.REACT_APP_IMG_PATH + blogPost.Author.avatar.url :
            process.env.REACT_APP_EXTERNAL_PATH + '/blank_profile.png';
        let categories = null;
        if (blogPost.blog_categories.length) {
            categories = blogPost.blog_categories.map((category) => {
                return <a href={process.env.REACT_APP_EXTERNAL_PATH + "/blog/category/" + encodeURI(category.Category)} key={category.id}>{category.Category}</a>
            }).reduce((prev, curr) => [prev, ', ', curr]);
        }
        let tags = null;
        if (blogPost.blog_tags.length) {
            tags = blogPost.blog_tags.map((tag) => {
                return <a href={process.env.REACT_APP_EXTERNAL_PATH + "/blog/tag/" + encodeURI(tag.Tag)} key={tag.id}>{tag.Tag}</a>
            }).reduce((prev, curr) => [prev, ', ', curr]);
        }
        let commentList = null;
        if (blogPost.comments.length) {
            commentList = blogPost.comments.map((comment, index) => {
                return <BlogComment name={comment.Name} comment={comment.Comment} date={comment.Date} key={index} />
            });
        }
        const comments = blogPost.CommentCount === 1 ? 'Comment' : 'Comments';
        let metadata = MetadataObj();
        metadata.PageTitle = 'Blog - ' + blogPost.Title;
        metadata.PageDescription = 'Blog entry from Clear Horizon Academy.';
        metadata.PageImage = blogPost.HeroHeader.HeroImage.url !== null ? blogPost.HeroHeader.HeroImage : "";
        return (
            <>
                <Metadata metadata={metadata} />
                {heroHeader}
                <Container>
                    <div className={"blog-header"}>
                        {blogPost.Title}
                    </div>
                    <div className={"blog-meta"}>
                        <div className={"blog-meta-left"}>
                            <img src={avatar}
                                 alt={blogPost.Author.firstname} />
                            <div className={"blog-meta-details"}>
                                <div className={"blog-meta-author"}>
                                    {blogPost.Author.firstname}
                                </div>
                                <div className={"blog-meta-date"}>
                                    {blogPost.Date}
                                </div>
                            </div>
                        </div>
                        <div className={"blog-meta-right"}>
                            <i className={"fa fa-comments"}>&nbsp;</i>
                            {blogPost.CommentCount} {comments}
                        </div>
                    </div>
                    <div className={"clearfix"}>&nbsp;</div>
                    <Markdown className={"blog-summary"} text={blogPost.Summary} />
                    <Markdown className={"blog-post"} text={blogPost.Post} />
                    <Markdown className={"blog-footer"} text={blogPost.Footer} />
                    <hr className={"blog-hr"} />
                    <div className={"blog-categories"}>
                        {categories !== null && <><i className={"fa fa-folder"}>&nbsp;</i> Categories: </>}
                        {categories}
                    </div>
                    <div className={"blog-tags"}>
                        {tags !== null && <><i className={"fa fa-tag"}>&nbsp;</i> Tagged: </>}
                        {tags}
                    </div>
                    <hr className={"blog-hr"} />
                    <BlogCommentForm PostID={blogPost.id}/>
                    <hr className={"blog-hr"} />
                    {blogPost.CommentCount > 0 && <h4>Comments</h4>}
                    {commentList}
                </Container>
            </>
        )
    }
}

const BlogComment = (props) => {
    return (
        <div className={"blog-comment-container"}>
            <div className={"blog-comment-name"}>
                <div className={"blog-comment-date"}>{props.date}</div>
                {props.name}
            </div>
            <div className={"blog-comment"}>{props.comment}</div>
        </div>
    )
}

const BlogCommentForm = (props) => {
    const [validated, setValidated] = useState(false);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries())
            const submitSuccess = await SubmitComment(formDataObj);
            if (submitSuccess.success) {
                setSubmitResponseStatus(2);
            } else {
                setErrorMessage(submitSuccess.error);
                setSubmitResponseStatus(3);
            }
        }
        setValidated(true);
    };
    if (submitResponseStatus === 1) {
        return <Loader />
    } else if (submitResponseStatus === 2) {
        return <div className={"form-submitted"}>
            Thanks for submitting your comment.  We will review it shortly.
        </div>
    } else if (submitResponseStatus === 3) {
        return <div className={"form-submitted"}>
            There was a problem submitting your comment.  Please try again later or
            <a href={"contact-us"}>contact us</a> directly with the following error message: {errorMessage}.</div>
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h4>Leave a reply</h4>
                <Form.Group>
                    <Form.Control type="hidden" name = "PostID" defaultValue ={props.PostID} />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} md="3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Name" name="Name"/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={4} required name="Comment"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <div className={"text-left comment-button"}>
                            <Button type="submit" className={"btn btn-primary"}>Submit comment</Button>
                        </div>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }
}

const SubmitComment = async (formDataObj) => {
    return await FetchData(process.env.REACT_APP_API_PATH + CommentFormURL, 'POST', null, formDataObj);
}
