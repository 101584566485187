import React, {useEffect, useState} from 'react';
import NotFound from "./NotFound";
import {
    CourseCarouselElement,
    OrganisationsMentorCarouselElement,
    OrganisationsPartnerCarouselElement,
    OrganisationsTestimonialCarouselElement,
} from '../components/Carousel';
import Loader from '../components/Loader';
import {useParams} from "react-router-dom";
import {FetchData, OpenPage} from "../Helpers";
import Container from "react-bootstrap/Container";
import Markdown from "../components/Markdown";
import Video from "../components/Video";
import Button from "react-bootstrap/Button";
import TextBlock from "../components/TextBlock";
import Metadata from "../components/Metadata";

export const OrganisationsPage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {OrganisationsPageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const OrganisationsPageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [key, setKey] = useState(1);
    let opURL = process.env.REACT_APP_API_PATH + "/organisations-pages/";
    const { token } = useParams();
    if (previewMode === true) {
        opURL += "preview/" + previewID + '/' + secret;
    } else {
        opURL += "finddeep";
    }

    useEffect(() => {
        FetchData(opURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setItem(result[0]);
                    } else {
                        setItem(result);
                    }
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [opURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        let courseCarousel = null;
        let testimonialCarousel = null;
        let expertsCarousel = null;
        let partnerCarousel = null;
        if (item.carousel_containers.length > 0) {
            for (let i = 0; i < item.carousel_containers.length; i ++) {
                const carousel = item.carousel_containers[i];
                switch (carousel.Type) {
                    case "Partners":
                        partnerCarousel = OrganisationsPartnerCarouselElement(carousel);
                        break;
                    case "Testimonials":
                        testimonialCarousel = OrganisationsTestimonialCarouselElement(carousel);
                        break;
                    case "Mentors":
                        expertsCarousel = OrganisationsMentorCarouselElement(carousel);
                        break;
                    default:
                        courseCarousel = CourseCarouselElement(carousel);
                        break;
                }
            }
        }
        return (
            <>
                <Metadata metadata={item.Metadata} />
                <OrganisationsHeaderSectionContainer item={item} videoKey={{key, setKey}} />
                <OrganisationsPackages packages={item.Packages} />
                {courseCarousel}
                <WhyUs whyUsLeft={item.WhyUsLeftBox} whyUsRight={item.WhyUsRightBox} mentors={expertsCarousel} />
                {testimonialCarousel}
                {partnerCarousel}
                <OrganisationsCallToAction />
            </>
        );
    }
}

const OrganisationsCallToAction = () => {
    return (
        <div className={"orange-banner-section"}>
            <Container>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <a className="btn white-btn mt-0" href = {process.env.REACT_APP_EXTERNAL_PATH + "/faqs"}>FAQs</a>
                    </div>
                    <div className={"col-6"}>
                        <a className="btn white-btn mt-0" href = {process.env.REACT_APP_EXTERNAL_PATH + "/contact-us"}>Contact Us</a>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const OrganisationsPackages = (props) => {
    let buttonColourArray = ['green', 'orange', 'blue'];
    const boxes = props.packages.Box.map((box, idx) => {
        return <Package package={box} key={box.id} colour={buttonColourArray[idx]} />;
    });
    return (
        <div className={"organisations-packages-container"}>
            <Container>
                <h1>{props.packages.Header}</h1>
                <h3>{props.packages.Text}</h3>
                {boxes}
            </Container>
        </div>
    )
}

const OrganisationsHeaderSectionContainer = (props) => {
    let estilo = props.item.HeaderBackground !== undefined && props.item.HeaderBackground !== null ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.item.HeaderBackground.url + `")`
    } : {};
    let video = <img className={"organisations-image"} src ={process.env.REACT_APP_IMG_PATH + props.item.HeaderRight.Image.url}
                     alt={props.item.HeaderLeft.Header} />;
    video = <Video url={props.item.HeaderRight.VideoURL} videoKey={props.videoKey} video={video} />;
    return (
        <>
            <div className = "hero-header organisations-hero-header" style = {estilo} key = {props.item.id}>
                <div className = "background-overlay">&nbsp;</div>
            </div>
            <div className="row header-row organisations-header-row">
                <div className="col-12 col-md-6 organisations-header-left">
                    <div className="header-details text-left">
                        <h2>{props.item.HeaderLeft.Header}</h2>
                        <TextBlock text={props.item.HeaderLeft.Text} />
                    </div>
                </div>
                <div className="col-12 col-md-6 organisations-header-right">
                    <div className={"organisations-top-right-image-container"}>
                        {video}
                    </div>
                </div>
            </div>
        </>
    );
}

const WhyUs = (props) => {
    return (
        <div className={"why-us"}>
            <Container>
                <h1>Why us</h1>
                <Package package={props.whyUsLeft} colour={"green"} />
                {props.mentors}
                <Package package={props.whyUsRight} colour={"blue"} />
            </Container>
        </div>
    )
}

export const Package = (props) => {
    return (
        <div className={"package-box"} key={props.package.id}>
            <div className={"header-btn-container " + props.colour}>
                <button className="header-btn" onClick={
                    () => OpenPage(props.package.Button.Link, props.package.Button.OpenInNewTab)
                }>
                    {props.package.Button.Text}
                </button>
                <Markdown renderers={{paragraph: 'span'}} className={"header-btn-blurb"} text={props.package.Text} />
            </div>
        </div>
    )
}

export const Box = (props) => {
    const target = props.package.Button.OpenInNewTab ? "_blank" : "";
    return (
        <div className={"package-box"} key={props.package.id}>
            <div className={"box-header"}>{props.package.Header}</div>
            <Markdown renderers={{paragraph: 'span'}} text={props.package.Text} />
            <div className={"package-button"}>
                <Button className={"btn btn-primary"} href={props.package.Button.Link} target={target}>{props.package.Button.Text}</Button>
            </div>
        </div>
    )
}
