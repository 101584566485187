import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Loader from '../components/Loader';
import {API} from '../api/API'
import Metadata, {MetadataObj} from "../components/Metadata";
import {
    BuildCountriesDropdown,
    BuildHowHeardDropdown,
    BuildOrgTypeDropdown,
    BuildSectorDropdown, CompileUTMData, DeleteUTMData
} from "../Helpers";
import {useAlert} from "react-alert";
import Markdown from "../components/Markdown";

const RegistrationForm = () => {
    let metadata = MetadataObj();
    metadata.PageTitle = 'Register your interest';
    metadata.PageDescription = 'Register your interest with Clear Horizon Academy.';

    return (
        <div className="main">
            <Metadata metadata={metadata} />
            <div className={"container"}>
                <header>
                    <h1>Register your interest</h1>
                </header>
                {FormComponent()}
            </div>
        </div>
    );
};

const FormComponent = () => {
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const alert = useAlert();

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries())
            const submitSuccess = await API.submitPreregistrationData(formDataObj);
            if (submitSuccess.success) {
                setSubmitResponseStatus(2);
                setSuccessMessage(submitSuccess.message);
                DeleteUTMData();
                window.scrollTo(0, 0);
            } else {
                setErrorMessage(submitSuccess.message);
                setSubmitResponseStatus(3);
                window.scrollTo(0, 0);
            }
        }
        setValidated(true);
    };

    const checkValid = async (e) => {
        const form = e.currentTarget;
        setDisabled(true);
        if (form.checkValidity() !== false) {
            setDisabled(false);
        }
    };

    const alertMissingDetails = () => {
        alert.error('Please ensure all required details are completed', {header: 'Error:'});
    }

    const courseArray = [
        {id: 'course_PBI', label: 'Data Analysis & Visualisation for Social Impact (Power BI)'},
        {id: 'course_MSC', label: 'Most Significant Change'},
        {id: 'course_ESCPBA', label: 'Evaluating Systems Change and Place-Based Approaches'},
        {id: 'course_TOCD', label: 'Theory of Change for Design'},
        {id: 'course_DE', label: 'Developmental Evaluation'},
        {id: 'course_MEL', label: 'Measurement, Evaluation & Learning (MEL full course)'},
        {id: 'course_IMEL', label: 'MEL Module 1 – Introduction to Measurement, Evaluation and Learning'},
        {id: 'course_MMEL', label: 'MEL Module 2 – Creating the "M" in MEL (Theory of Change and Measurement)'},
        {id: 'course_EMEL', label: 'MEL Module 3 – Putting the "E" in MEL (Evaluating your program)'},
        {id: 'course_LMEL', label: 'MEL Module 4 – Applying the "L" in MEL (Strategic Learning and Reporting)'},
    ];

    const countries = BuildCountriesDropdown();
    const courses = courseArray.map((course) => {
        return <Form.Check
            type="checkbox"
            id={course.id}
            label={course.label}
            key = {course.id}
            name = {course.id}
        />
    });
    const howHeard = BuildHowHeardDropdown();
    const organisationTypes = BuildOrgTypeDropdown();
    const sectors = BuildSectorDropdown();
    const utmData = CompileUTMData();
    if (submitResponseStatus === 1) {
        return <Loader />
    } else if (submitResponseStatus === 2) {
        return <Markdown className={"form-submitted"} text={successMessage} />
    } else if (submitResponseStatus === 3) {
        return <Markdown className={"form-submitted"} text={errorMessage} />
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit} onChange={checkValid}>
                <Form.Group>
                    <Form.Control type="hidden" name = "utm_source" defaultValue={utmData.utmSource} />
                    <Form.Control type="hidden" name = "utm_medium" defaultValue={utmData.utmMedium} />
                    <Form.Control type="hidden" name = "utm_campaign" defaultValue={utmData.utmCampaign} />
                    <Form.Control type="hidden" name = "utm_content" defaultValue={utmData.utmContent} />
                    <Form.Control type="hidden" name = "utm_referrer" defaultValue={utmData.utmReferrer} />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>First name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="First name" name = "firstName" />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Last name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="Last name" name = "lastName" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Email<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Email" required
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "email" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Phone<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Phone" required name = "phone" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid phone number.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Organisation name</Form.Label>
                        <Form.Control type="text" placeholder="Optional" name = "company" />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>What is your role in the organisation?</Form.Label>
                        <Form.Control type="text" placeholder="Optional" name = "position" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Organisation type</Form.Label>
                        <Form.Control as = "select" placeholder="Optional" name = "organisationType">
                            {organisationTypes}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>What sector do you primarily work in?</Form.Label>
                        <Form.Control as = "select" placeholder="Optional" name = "industry">
                            {sectors}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Postcode / Zip</Form.Label>
                        <Form.Control type="text" placeholder="Optional" name = "postcode" />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Country</Form.Label>
                        <Form.Control as = "select" placeholder="Optional" name = "country">
                            {countries}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="courseField" name = "course">
                        <Form.Label>Which courses are you interested in?</Form.Label>
                        {courses}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6" controlId="howHeardField">
                        <Form.Label>Where did you first hear about the course?<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required as = "select" placeholder="Select one" name = "howHeard">
                            {howHeard}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="messageField">
                        <Form.Label>Optional message to Clear Horizon Academy</Form.Label>
                        <Form.Control as="textarea" rows={4} name = "message" placeholder="Optional message" />
                    </Form.Group>
                </Form.Row>
                {!disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary"}>Submit form</Button>
                </div>
                }
                {disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary disabled"} onClick = {alertMissingDetails}>Submit
                        form</Button>
                </div>
                }
            </Form>
        );
    }
}

export default RegistrationForm;