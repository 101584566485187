import React, {useContext, useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {useParams} from "react-router-dom";
import Loader from './Loader.js';
import {FetchData, UpdateUTMData} from "../Helpers";
import NotFound from "../pages/NotFound";
import CartContext from "../contexts/CartContext";
import {Mdl, LaunchModal} from "./Modal";
import Form from "react-bootstrap/Form";
import {FormControl, NavDropdown} from "react-bootstrap";

export const Menu = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isMenuLoaded, setIsMenuLoaded] = useState(false);
    const [menuItem, setMenuItem] = useState([]);
    const [menuNotFound, setMenuNotFound] = useState(false);
    let menuURL = process.env.REACT_APP_API_PATH + "/menus/findurl/main-menu/";
    let { token } = useParams();
    if (previewMode === true) {
        menuURL = process.env.REACT_APP_API_PATH + "/menus/preview/" + previewID + '/' + secret;
    }
    const [showModal, setShowModal] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [cartData, setCartData] = useState({});
    const [, updateCount] = useContext(CartContext);
    const modal = <Mdl action={{showModal, setShowModal}} data={{cartData, setCartData}} count={updateCount} />
    UpdateUTMData();
    useEffect(() => {
        FetchData(menuURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setMenuNotFound(true);
                    } else if (result.length !== undefined) {
                        setMenuItem(result[0]);
                    } else {
                        setMenuItem(result);
                    }
                    setIsMenuLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsMenuLoaded(true);
                }
            )
    }, [menuURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isMenuLoaded) {
        return <Loader />;
    } else if (menuNotFound) {
        return <NotFound />;
    } else {
        const menuItems = menuItem.menu_items.map((navItem) => {
            if (navItem.children.length) {
                const navChildren = navItem.children.map((childItem) => {
                    const url = childItem.ExternalLink ? childItem.URL :
                        process.env.REACT_APP_EXTERNAL_PATH + '/' + childItem.URL;
                    return <NavDropdown.Item href={url}
                                             key={childItem.id}>
                        {childItem.Title}
                    </NavDropdown.Item>
                });
                return <NavDropdown title={navItem.Title} id={"nav-dropdown-" + navItem.id} key={navItem.id}>
                    {navChildren}
                </NavDropdown>
            } else {
                const url = navItem.ExternalLink ? navItem.URL :
                    process.env.REACT_APP_EXTERNAL_PATH + '/' + navItem.URL;
                return <Nav.Link href={url} key={navItem.id}>
                    {navItem.Title}
                </Nav.Link>
            }
        });
        menuItems.push(
            <Nav.Link href={process.env.REACT_APP_BRIGHTSPACE_PATH} target={"_blank"} className={"d-md-none"}
                      key={"ayl"}>
                Learning Access
            </Nav.Link>,
            <Nav.Link href={"/community"} className={"d-md-none"} key={"aoc"}>
                Community
            </Nav.Link>
        );
        const logoClass = showSearch ? " search-hide" : "";
        return (
            <div className={"navbar-outer"}>
                <div className={"navbar-container"}>
                    <Navbar expand="lg">
                        <MobileMenu items={menuItems} />
                        <Navbar.Brand href="/" className={"d-none d-xl-inline-block"}>
                            <img src={process.env.REACT_APP_IMG_PATH + menuItem.siteInfo.Logo.url} alt="Logo" />
                        </Navbar.Brand>
                        <Navbar.Brand href="/" className={"d-xl-none" + logoClass}>
                            <img src={process.env.REACT_APP_IMG_PATH + menuItem.siteInfo.SmallLogo.url} alt="Logo" />
                        </Navbar.Brand>
                        <div id="basic-navbar-nav">
                            <div className="navbar-nav d-none d-lg-flex">
                                {menuItems}
                            </div>
                        </div>
                        <SearchIcon action={{showSearch, setShowSearch}} />
                        <CartIcon action={{showModal, setShowModal}} cartData ={{cartData, setCartData}} />
                        {modal}
                    </Navbar>
                </div>
            </div>
        );
    }
}

const CartIcon = (props) => {
    const [count] = useContext(CartContext);
    let estilo = count === 0 ? { opacity: 0 } : { opacity: 1 };
    let itemRender = <div className={"cart-count"} style={estilo}>{count}</div>;
    return (
        <Nav.Link href={process.env.REACT_APP_EXTERNAL_PATH + '/cart'} className={"cart"} onClick={
            (e) => LaunchModal(e, props.action, props.cartData)
        }>
            <i className={"fa fa-shopping-cart header-icon"}>&nbsp;</i>{itemRender}
        </Nav.Link>
    )
};

const MobileMenu = (props) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const handleMobileMenuClick = () => {
        setShowMobileMenu(!showMobileMenu)
    }

    let estilo = showMobileMenu ? {
        transform: "translateY(0)", transition: "transform 0.25s"
    } : {
        transform: "translateY(-100%)", transition: "transform 0.25s"
    };
    let mobileMenuClass = showMobileMenu ? "" : " zero-height";
    return (
        <div className={"d-lg-none"}>
            <div className="navbar-toggler" onClick={handleMobileMenuClick}>
                <i className={"fa fa-bars"}>&nbsp;</i>
            </div>
            <div className={"mobile-menu" + mobileMenuClass} style={estilo}>
                {props.items}
            </div>
        </div>
    )
}

const SearchIcon = (props) => {
    const [query, setQuery] = useState([]);
    const handleSearchChange = (e) => {
        if (e.target.value === '') {
            setQuery([])
        } else {
            FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/search/' + e.target.value)
                .then((searchResults) => {
                        setQuery(searchResults)
                    }
                )
        }
    }
    const handleSearchClick = () => {
        props.action.setShowSearch(!props.action.showSearch)
    }

    let searchContainerClass = props.action.showSearch ? "" : " zero-width";
    let searchFormClass = props.action.showSearch ? " wide-search" : "";

    let estilo = props.action.showSearch ? {
        transform: "translateX(0)", transition: "transform 0.25s ease-in-out"
    } : {
        transform: "translateX(100%)", transition: "transform 0.25s ease-in-out"
    };
    let estiloIcon = props.action.showSearch ? {
        display: "none"
    } : {
        display: "inline-block"
    };
    return (
        <>
            <Form inline className={"search-form" + searchFormClass}>
                <div className={"search-container" + searchContainerClass}>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2 search-input" style={estilo} onChange={handleSearchChange}/>
                </div>
                <i className={"fa fa-search site-search"} onClick={handleSearchClick} style={estiloIcon}>&nbsp;</i>
            </Form>
            <Suggestions results={query} action={props.action} />
        </>
    )
};

const Suggestions = (props) => {
    let options = (<li className={"no-search-results"}>No results found</li>);
    let estilo = props.action.showSearch ? { transition: "opacity 0.25s ease-in-out", opacity: 1, zIndex: 'unset' } : { transition: "opacity 0.25s ease-in-out", opacity: 0, zIndex: -2 };
    useEffect(() => {
        function handleClickOutside(e) {
            if (typeof e.target.className === 'string' && e.target.className.indexOf('search-suggestions') === -1 && e.target.className.indexOf('search-input') === -1) {
                props.action.setShowSearch(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.action]);

    if (props.results.length) {
        options = props.results.map(r => {
            return (
                <a href = {r.url} key={r.type + r.id}>
                    <li className={r.type + "-suggestion"}>{r.title}</li>
                </a>
            )
        })
    }
    return <ul className={"search-suggestions"} style={estilo} onBlur={() => props.action.setShowSearch(false)}>{options}</ul>
}