const InfoPopup = (e, className, alert, type) => {
    let clickPosition = {width: "320px"};
    const bottom = e.clientY - 10;
    if (bottom < 350) {
        clickPosition.top = e.clientY - 66 + "px";
    } else {
        clickPosition.bottom = "-" + bottom + "px";
    }
    let node = e.currentTarget;
    while (node) {
        node = node.parentNode;
        if (node.classList.contains(className)) {
            const left = node.getBoundingClientRect().right - 320;
            clickPosition.left = left + "px";
            clickPosition.width = "300px";
            break;
        }
    }
    let alertText = 'Opting for the standard or premium package secures one-to-one time with Lee-Anne Molony, Partner and Director at Clear Horizon. This time is devoted to tackling the questions you have in applying your learning to your specific context and making sure your coursework sets you up for success.';
    if (type === 'SeniorConsultant') {
        alertText = 'Opting for the standard or premium package means you secure one-to-one time with one of our Digital Principal Consultants. This means uninterrupted time with a MEL expert, devoted to tackling the questions you have in applying your learning to your specific context and making sure your coursework sets you up for success.';
    }
    alert.info(alertText, {header: 'How will personalised support benefit my learning?', location: clickPosition});
}

export default InfoPopup;