import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Loader from '../components/Loader';
import {API} from '../api/API'
import Metadata, {MetadataObj} from "../components/Metadata";
import {useAlert} from "react-alert";

const UpdateDetailsForm = () => {
    let metadata = MetadataObj();
    metadata.PageTitle = 'Update your details';
    metadata.PageDescription = 'Update your details with Clear Horizon Academy.';

    return (
        <div className="main">
            <Metadata metadata={metadata} />
            <div className={"container"}>
                <header>
                    <h1>Update your details</h1>
                </header>
                {FormComponent()}
            </div>
        </div>
    );
};

const FormComponent = () => {
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const alert = useAlert();

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries());
            const submitSuccess = await API.submitUpdateDetailsForm(formDataObj);
            if (submitSuccess.success) {
                setSubmitResponseStatus(2);
                window.scrollTo(0, 0);
            } else {
                setErrorMessage(submitSuccess.error);
                setSubmitResponseStatus(3);
                window.scrollTo(0, 0);
            }
        }
        setValidated(true);
    };

    const checkValid = async (e) => {
        const form = e.currentTarget;
        setDisabled(true);
        if (form.checkValidity() !== false) {
            setDisabled(false);
        }
    };

    const alertMissingDetails = () => {
        alert.error('Please ensure all required details are completed', {header: 'Error:'});
    }

    if (submitResponseStatus === 1) {
        return <Loader />
    } else if (submitResponseStatus === 2) {
        return <div className={"form-submitted"}>
            Thanks for submitting your new details.  We will update them across our systems shortly.
        </div>
    } else if (submitResponseStatus === 3) {
        return <div className={"form-submitted"}>
            There was a problem updating your details.
            Please try again later or <a href={"contact-us"}>contact us</a> directly with the following error message: {errorMessage}.</div>
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit} onChange={checkValid}>
                <Form.Row>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>First name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="First name" name = "firstName" />
                    </Form.Group>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>Last name<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder="Last name" name = "lastName" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>Old email<span className={"required-field"}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Old email" required
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "oldEmail" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>New email</Form.Label>
                        <Form.Control type="email" placeholder="New email"
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "newEmail" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                {!disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary"}>Submit form</Button>
                </div>
                }
                {disabled &&
                <div className={"text-right"}>
                    <Button type="submit" className={"btn btn-primary disabled"} onClick = {alertMissingDetails}>Submit
                        form</Button>
                </div>
                }
            </Form>
        );
    }
}

export default UpdateDetailsForm;