import React, { useEffect, useState } from 'react';
import NotFound from "./NotFound";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../components/Loader';
import Banner from '../components/Banner';
import TextBlock from "../components/TextBlock";
import {FetchData} from "../Helpers";
import AnimateHeight from 'react-animate-height';
import Markdown from "../components/Markdown";
import Metadata from "../components/Metadata";

export const FAQsPage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {FAQsPageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const FAQsPageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [isFAQLoaded, setIsFAQLoaded] = useState(false);
    const [faqItem, setFAQItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let faqURL = process.env.REACT_APP_API_PATH + "/fa-qs-pages/";
    const { token } = useParams();
    if (previewMode === true) {
        faqURL += "preview/" + previewID + '/' + secret;
    } else {
        faqURL += "findall";
    }

    useEffect(() => {
        FetchData(faqURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setFAQItem(result[0]);
                    } else {
                        setFAQItem(result);
                    }
                    setIsFAQLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsFAQLoaded(true);
                }
            )
    }, [faqURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isFAQLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!faqItem.id) {
            return;
        }
        let heroHeader = faqItem.HeroHeader && Banner(faqItem.HeroHeader);
        let textBlock = faqItem.Text && <TextBlock subHeader = {faqItem.SubHeader} text = {faqItem.Text} />;
        let faqs = null;
        if (faqItem.FAQs.length > 0) {
            faqs = faqItem.FAQs.map((faqSection) => {
                return <FAQSection header = {faqSection.Header} subsection = {faqSection.Subsection}
                                   faqs = {faqSection.FAQ} key = {faqSection.id} />
            });
        }
        return (
            <>
                <Metadata metadata={faqItem.Metadata} />
                {heroHeader}
                <Container>
                    {textBlock}
                </Container>
                {faqs}
            </>
        );
    }
}

export const FAQSection = (props) => {
    const subsections = props.subsection !== undefined && props.subsection.map((subsection) => {
        return <FAQSubsection subheader = {subsection.SubHeader} faqs = {subsection.FAQ} key = {subsection.id} />
    });
    const faqs = props.faqs !== undefined && props.faqs.map((faq) => {
        return <FAQ question = {faq.Question} answer = {faq.Answer} key = {faq.id} id={faq.id} />
    });
    return (
        <Container className={"faq-section"}>
            <h4>{props.header}</h4>
            {subsections}
            {faqs}
        </Container>
    );
}

const FAQSubsection = (props) => {
    const faqs = props.faqs !== undefined && props.faqs.map((faq) => {
        return <FAQ question = {faq.Question} answer = {faq.Answer} key = {faq.id} id={faq.id} />
    });
    const tagID = props.subheader.replace(/ /g, '-')
    return (
        <div className={"faq-subsection"}>
            <a id={tagID} href={"/"}
               onClick={(e)=>{e.preventDefault();}} style={{cursor:'auto'}}>
                <h5>{props.subheader}</h5>
            </a>
            {faqs}
        </div>
    );
}

const FAQ = (props) => {
    const [height, setHeight] = useState(0);
    const toggle = (el) => {
        setHeight( height === 0 ? 'auto' : 0);
        let classList = el.target.classList;
        if (el.target.parentNode.className !== 'faq-question') {
            classList = el.target.children[0].classList;
        }
        if (height === 0) {
            classList.remove('fa-chevron-down');
            classList.add('fa-chevron-up');
        } else {
            classList.remove('fa-chevron-up');
            classList.add('fa-chevron-down');
        }
    };
    return (
        <div className={"faq"} id={props.id}>
            <div className={"faq-question"} onClick = {(el) => toggle(el)} id={props.id}
                 aria-expanded={ height !== 0 } aria-controls={"faq-answer" + props.id}>{props.question}
                 <i className={"fa fa-chevron-down"}>&nbsp;</i>
            </div>
            <AnimateHeight className={"faq-answer"} id={"faq-answer" + props.id}
                           duration={ 500 }
                           height={ height }>
                <Markdown className={"faq-answer-markdown"} renderers={{paragraph: 'span'}} text={props.answer} />
            </AnimateHeight>
        </div>
    );
}
