import React, {useEffect, useState} from 'react';
import NotFound from "../NotFound";
import {
    CoachingBenefitsCarouselElement, MentorCarouselElement,
} from '../../components/Carousel';
import Loader from '../../components/Loader';
import {useParams} from "react-router-dom";
import {FetchData} from "../../Helpers";
import Container from "react-bootstrap/Container";
import Markdown from "../../components/Markdown";
import Video from "../../components/Video";
import Button from "react-bootstrap/Button";
import TextBlock from "../../components/TextBlock";
import Metadata from "../../components/Metadata";
import {FAQSection} from "../FAQs";

export const CoachingPage = (previewMode) => {
    return (
        <div className="main">
            {CoachingPageComponent(previewMode)}
        </div>
    );
};

const CoachingPageComponent = (previewMode) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [key, setKey] = useState(1);
    let hpURL = process.env.REACT_APP_API_PATH + "/coaching-pages/";
    let { id, token } = useParams();
    if (previewMode === true) {
        hpURL += "preview/" + id;
    } else {
        hpURL += "finddeep";
    }

    useEffect(() => {
        FetchData(hpURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setItem(result[0]);
                    } else {
                        setItem(result);
                    }
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [hpURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        let expertsCarousel, benefitsCarousel = null;
        if (item.carousel_containers.length > 0) {
            for (let i = 0; i < item.carousel_containers.length; i ++) {
                const carousel = item.carousel_containers[i];
                switch (carousel.Type) {
                    case "OurApproach":
                        benefitsCarousel = CoachingBenefitsCarouselElement(carousel);
                        break;
                    default:
                        expertsCarousel = MentorCarouselElement(carousel);
                        break;
                }
            }
        }
        return (
            <>
                <Metadata metadata={item.Metadata} />
                <CoachingHeaderSectionContainer item={item} videoKey={{key, setKey}} />
                {benefitsCarousel}
                <Testimonial testimonial={item.Testimonial} />
                <CoachingOptions coachingOptions={item.CoachingOptions} />
                <CoachingPackages package={item.CoachingPackages} />
                <CallToAction callToAction={item.CallToAction} />
                {expertsCarousel}
                <div className={"coaching-faqs"}>
                    <FAQSection header = {item.FAQs.Header} subsection = {item.FAQs.Subsection}
                            faqs = {item.FAQs.FAQ} key = {item.FAQs.id} />
                </div>
            </>
        );
    }
}

export const CallToAction = (props) => {
    return (
        <div className={"coaching-call-to-action"}>
            <Container>
                <h1>{props.callToAction.Header}</h1>
                <div className="call-to-action">
                    <Markdown renderers={{paragraph: 'span'}} text={props.callToAction.Text} />
                </div>
                <div className={"coaching-register-interest"}>
                    <Button href = {"/register-your-interest-coaching"}>
                        Book my free discovery session
                    </Button>
                </div>
            </Container>
        </div>
    )
}

const CoachingOptions = (props) => {
    const numberOptions = props.coachingOptions.ImageHeaderText.length;
    const coachingOptions = props.coachingOptions.ImageHeaderText.map((coachingOption) => {
        return <CoachingOption coachingOption={coachingOption} key={coachingOption.id} numberOptions={numberOptions} />;
    });
    return (
        <div className={"coaching-options-container"}>
            <Container>
                <h1>Coaching Options</h1>
                <div className={"coaching-options"}>{props.coachingOptions.BodyText}</div>
                <div className={"row"}>{coachingOptions}</div>
            </Container>
            <div id={"coaching-packages"}>&nbsp;</div>
        </div>
    )
}

const CoachingOption = (props) => {
    const colWidth = Math.round(12 / props.numberOptions);
    return (
        <div className={"coaching-option col-md-" + colWidth}>
            <a href = {props.coachingOption.HeaderLink}>
                <img className={"coaching-option-image"} alt={props.coachingOption.Header}
                 src ={process.env.REACT_APP_IMG_PATH + props.coachingOption.Image.url} />
            </a>
            <div className={"coaching-option-text"}>
                <Button href = {props.coachingOption.HeaderLink}>{props.coachingOption.Header}</Button>
            </div>
        </div>
    )
}

const CoachingHeaderSectionContainer = (props) => {
    let estilo = props.item.HeaderBackground !== undefined && props.item.HeaderBackground !== null ? {
        backgroundImage: `url("` + process.env.REACT_APP_IMG_PATH + props.item.HeaderBackground.url + `")`
    } : {};
    let video = <img className={"organisations-image"} src ={process.env.REACT_APP_IMG_PATH + props.item.HeaderRight.Image.url}
                     alt={props.item.HeaderLeft.Header} />;
    video = <Video url={props.item.HeaderRight.VideoURL} videoKey={props.videoKey} video={video} />;
    return (
        <>
            <div className = "hero-header organisations-hero-header" style = {estilo} key = {props.item.id}>
                <div className = "background-overlay">&nbsp;</div>
            </div>
            <div className="row header-row organisations-header-row">
                <div className="col-12 col-md-6 organisations-header-left">
                    <div className="header-details text-left">
                        <h2>{props.item.HeaderLeft.Header}</h2>
                        <TextBlock text={props.item.HeaderLeft.Text} />
                    </div>
                </div>
                <div className="col-12 col-md-6 organisations-header-right">
                    <div className={"organisations-top-right-image-container"}>
                        {video}
                    </div>
                </div>
            </div>
        </>
    );
}

const Testimonial = (props) => {
    return (
        <div className={"coaching-testimonial"}>
            <Container>
                <div className="row">
                    <div className="col-md-9 testimonial-quotation">
                        {props.testimonial.Text}
                        <div className={"quotation-caption"}>
                            <em>{props.testimonial.SubHeader}</em>
                        </div>
                    </div>
                    <div className="col-md-3 coaching-testimonial-image-container">
                        <img className={"coaching-testimonial-image"} src ={process.env.REACT_APP_IMG_PATH + props.testimonial.Image.url}
                             alt={props.testimonial.SubHeader} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export const CoachingPackages = (props) => {
    return (
        <div className={"coaching-packages"}>
            <Container>
                <h1>{props.package.Header}</h1>
                <div className="coaching-package">
                    <Markdown renderers={{paragraph: 'span'}} text={props.package.Text} />
                </div>
            </Container>
        </div>
    )
}

export const Box = (props) => {
    const target = props.package.Button.OpenInNewTab ? "_blank" : "";
    return (
        <div className={"package-box"} key={props.package.id}>
            <div className={"box-header"}>{props.package.Header}</div>
            <Markdown renderers={{paragraph: 'span'}} text={props.package.Text} />
            <div className={"package-button"}>
                <Button className={"btn btn-primary"} href={props.package.Button.Link} target={target}>{props.package.Button.Text}</Button>
            </div>
        </div>
    )
}
