import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Loader from '../components/Loader';
import {API} from '../api/API'
import Banner from "../components/Banner";
import {useParams} from "react-router-dom";
import {FetchData} from "../Helpers";
import NotFound from "./NotFound";
import Container from "react-bootstrap/Container";
import IconText from "../components/IconText";
import Metadata from "../components/Metadata";
import Markdown from "../components/Markdown";

const ContactPage = (previewMode, previewID, secret) => {
    return (
        <div className="main">
            {ContactPageComponent(previewMode, previewID, secret)}
        </div>
    );
};

const ContactPageComponent = (previewMode, previewID, secret) => {
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [isContactLoaded, setIsContactLoaded] = useState(false);
    const [contactItem, setContactItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [validated, setValidated] = useState(false);
    const [submitResponseStatus, setSubmitResponseStatus] = useState(0);

    let cpURL = process.env.REACT_APP_API_PATH + "/contact-pages/";
    const { token } = useParams();
    if (previewMode === true) {
        cpURL += "preview/" + previewID + '/' + secret;
    } else {
        cpURL += "findall";
    }

    useEffect(() => {
        FetchData(cpURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setContactItem(result[0]);
                    } else {
                        setContactItem(result);
                    }
                    setIsContactLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsContactLoaded(true);
                }
            )
    }, [cpURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isContactLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!contactItem.id) {
            return;
        }
        let heroHeader = contactItem.HeroHeader && Banner(contactItem.HeroHeader);
        let introText = contactItem.IntroText &&
            <Markdown renderers={{paragraph: 'span'}} text={contactItem.IntroText} />;
        return (
            <>
                <Metadata metadata={contactItem.Metadata} />
                {heroHeader}
                <Container className={"contact-page"}>
                    {introText}
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <ContactFormComponent validation={[validated, setValidated]}
                                                  responseStatus={[submitResponseStatus, setSubmitResponseStatus]}
                                                  responseMessage={[responseMessage, setResponseMessage]} />
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"contact-details-container"}>
                                <div className={"map-container"}>
                                    <iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                            src="https://maps.google.com/maps?q=132B%20Gwynne%20St%2C%20Cremorne%20VIC%203121%2C%20Australia&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                                            aria-label={contactItem.siteInfo.PhysicalAddress}  width={"100%"}
                                            height={"260px"} title={"contact-us-map"}/>
                                </div>
                                <IconText icon={"map-marker-alt"} text={contactItem.siteInfo.PhysicalAddress} class={"contact-icon"} />
                                <IconText icon={"phone"} text={contactItem.siteInfo.PhoneNumber} class={"contact-icon"} />
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}

const ContactFormComponent = (props) => {
    const [validated, setValidated] = [...props.validation];
    const [submitResponseStatus, setSubmitResponseStatus] = [...props.responseStatus];
    const [responseMessage, setResponseMessage] = [...props.responseMessage];
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {
            setSubmitResponseStatus(1);
            const formData = new FormData(e.target);
            const formDataObj = Object.fromEntries(formData.entries());
            const submitSuccess = await API.submitContactForm(formDataObj);
            if (submitSuccess.success) {
                setResponseMessage(submitSuccess.message)
                setSubmitResponseStatus(2);
            } else {
                setResponseMessage(submitSuccess.message)
                setSubmitResponseStatus(3);
            }
        }
        setValidated(true);
    };

    const subjectArray = [
        'Get a quote for enrolling five or more learners in a public course',
        'Get a quote for in-house training',
        'Get a quote for one-to-one coaching',
        'Get support with a current course',
        'Enquire about an upcoming course',
        'Other'
    ];
    const subject = subjectArray.map((subject) => { return <option key = {subject}> {subject} </option> });

    if (submitResponseStatus === 1) {
        return <div className={"contact-form"}>
            <Loader />
        </div>
    } else if (submitResponseStatus === 2 || submitResponseStatus === 3) {
        return <Markdown className={"form-submitted contact-form"} text={responseMessage} />
    } else {
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={"contact-form"}>
                <Form.Group>
                    <Form.Control type="hidden" name = "utm_source" defaultValue = "" />
                    <Form.Control type="hidden" name = "utm_medium" defaultValue = "" />
                    <Form.Control type="hidden" name = "utm_campaign" defaultValue = "" />
                    <Form.Control type="hidden" name = "utm_content" defaultValue = "" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={"mobile-hide"}>
                        First name<span className={"required-field"}>*</span>
                    </Form.Label>
                    <Form.Control required type="text" placeholder="First name" name = "firstName" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={"mobile-hide"}>
                        Last name<span className={"required-field"}>*</span>
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Last name" name = "lastName" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={"mobile-hide"}>Email<span className={"required-field"}>*</span></Form.Label>
                    <Form.Control type="email" placeholder="Email" required
                                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" name = "email" />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email address.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>How can we help?<span className={"required-field"}>*</span></Form.Label>
                    <Form.Control required as = "select" placeholder="Select one" name = "subject">
                        <option value={""}>Select</option>
                        {subject}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Message<span className={"required-field"}>*</span></Form.Label>
                    <Form.Control as="textarea" rows={4} required name = "message" />
                </Form.Group>
                <div className={"contact-submit"}>
                    <Button type="submit" className={"btn btn-primary"}>Submit form</Button>
                </div>
            </Form>
        );
    }
}

export default ContactPage;