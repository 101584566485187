import React from 'react';
import ReactPlayer from "react-player/lazy";

const Video = (props) => {
    if (props.url !== null && props.url !== "") {
        const videoEnded = () => {
            props.setKey(props.videoKey.key + 1);
        }
        const height = props.height || "304px";
        return <ReactPlayer url={props.url} width={"100%"} height={height} controls={true}
                             onEnded={videoEnded} key={"video" + props.videoKey.key} />;
    }
    return props.video;
}

export default Video;