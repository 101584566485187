import React, { useEffect, useState } from 'react';
import NotFound from "./NotFound";
import {useParams, useLocation} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../components/Loader';
import Banner from '../components/Banner';
import TextBlock from "../components/TextBlock";
import {FetchData} from "../Helpers";
import ImageHeaderTextSection from "../components/ImageHeaderText";
import {
    OrganisationsTestimonialCarouselElement,
    PartnerCarouselElement
} from "../components/Carousel";
import Markdown from "../components/Markdown";
import Metadata from "../components/Metadata";
import ReadMoreImageHeaderTextSection from "../components/ReadMoreImageHeaderText";

export const InfoPage = (previewMode, previewURL, secret) => {
    return (
        <div className="main">
            {InfoPageComponent(previewMode, previewURL, secret)}
        </div>
    );
};

const InfoPageComponent = (previewMode, previewURL, secret) => {
    const [error, setError] = useState(null);
    const [isInfoPageLoaded, setIsInfoPageLoaded] = useState(false);
    const [infoItem, setInfoItem] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let infoURL = process.env.REACT_APP_API_PATH + "/info-pages/";
    const location = useLocation().pathname.substr(1);

    let { token } = useParams();
    if (previewMode === true) {
        infoURL += "preview/" + previewURL + '/' + secret;
    } else {
        infoURL += "findurl/" + location;
    }

    useEffect(() => {
        FetchData(infoURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else if (result.length !== undefined) {
                        setInfoItem(result[0]);
                    } else {
                        setInfoItem(result);
                    }
                    setIsInfoPageLoaded(true);
                },
                (error) => {
                    if (error.status === 404 || error.status === 500) {
                        setNotFound(true);
                    } else {
                        setError(error);
                    }
                    setIsInfoPageLoaded(true);
                }
            )
    }, [infoURL, token]);

    if (error) {
        return <div className={"error"}>Error: {error.message}</div>;
    } else if (!isInfoPageLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!infoItem.id) {
            return;
        }
        const heroHeader = infoItem.HeaderImage && Banner(infoItem.HeaderImage);
        const textBlock = infoItem.Text && <TextBlock text = {infoItem.Text} />;
        let highlightSection = null;
        if (infoItem.DynamicElements.length) {
            highlightSection = infoItem.DynamicElements.map((element) => {
                const iHTS = element.ImageHeaderText.map(
                    (section) => <ImageHeaderTextSection src={section.Image.url} text={section.Text}
                                                         header={section.Header} key = {section.id}
                                                         subHeader={section.SubHeader} columns={element.Columns}
                                                         link={section.HeaderLink} external={section.ExternalLink} />
                );
                const rMIHTS = element.ReadMoreImageHeaderText.map(
                    (section) => <ReadMoreImageHeaderTextSection src={section.Image.url} text={section.Text}
                                                         header={section.Header} key = {section.id}
                                                         introText={section.IntroText} columns={element.Columns}
                                                         link={section.HeaderLink} external={section.ExternalLink}
                                                         subHeader={section.SubHeader} />
                );
                return <div key={element.id}>
                    {element.Header !== null && <h4 className={"mt-4"}>{element.Header}</h4>}
                    {element.Text !== null && <Markdown renderers={{paragraph: 'div'}} text={element.Text} />}
                    <div className={"highlight-section-rows " + location}>
                            {iHTS}
                            {rMIHTS}
                    </div>
                </div>
            })
        }
        let testimonialCarousel = null;
        let partnerCarousel = null;
        if (infoItem.carousel_containers.length > 0) {
            for (let i = 0; i < infoItem.carousel_containers.length; i ++) {
                const carousel = infoItem.carousel_containers[i];
                switch (carousel.Type) {
                    case "Partners":
                        partnerCarousel = PartnerCarouselElement(carousel);
                        break;
                    case "Testimonials":
                        testimonialCarousel = OrganisationsTestimonialCarouselElement(carousel);
                        break;
                    default:
                        break;
                }
            }
        }
        return (
            <>
                <Metadata metadata={infoItem.Metadata} />
                {heroHeader}
                <Container className={"info-page"}>
                    {!heroHeader && <div className={"info-page-header"}>
                        <h1>{infoItem.Title}</h1>
                    </div>}
                    {textBlock}
                    {highlightSection}
                </Container>
                {partnerCarousel}
                {testimonialCarousel}
            </>
        );
    }
}