import React, { useEffect, useState } from 'react';
import NotFound from "../NotFound";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Loader from '../../components/Loader';
import {FetchData, FormatDateToDateMonString, MonthDiff} from "../../Helpers";
import useMeasure from "react-use-measure";

export const CourseCalendarPage = () => {
    return (
        <div className="main">
            {CourseCalendarPageComponent()}
        </div>
    );
};

const CourseCalendarPageComponent = () => {
    const [error, setError] = useState(null);
    const [isCalendarLoaded, setIsCalendarLoaded] = useState(false);
    const [courseCalendar, setCourseCalendar] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const calendarURL = process.env.REACT_APP_API_PATH + "/course-pages/course-calendar";
    const { token } = useParams();
    const [ganttRef, ganttBounds] = useMeasure();

    useEffect(() => {
        FetchData(calendarURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else {
                        setCourseCalendar(result);
                    }
                    setIsCalendarLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsCalendarLoaded(true);
                }
            )
    }, [calendarURL, token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isCalendarLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        if (!courseCalendar.courses.length) {
            return <Container>There are no upcoming courses.</Container>;
        }
        const months = GetMonths(courseCalendar.start, courseCalendar.end);
        const fullWidth = ganttBounds.width;
        const courseNameWidth = 170;
        let borderWidth = 10;
        let monthWidths = '';
        let minMonthWidthTotal = 0;
        const monthDays = 31
        for (let i = 0; i < months.length; i ++) {
            const minWidth = 65;
            monthWidths += ' minmax(' + minWidth + 'px, ' + fullWidth + 'px)';
            minMonthWidthTotal += minWidth;
        }
        const gridStyle = {gridTemplateColumns: courseNameWidth + 'px ' + monthWidths};
        return (
            <Container>
                <h1 className="course-calendar-header">Course Calendar</h1>
                <div className="course-calendar">
                    <div className="chart-row chart-period" style={gridStyle} ref={ganttRef}>
                        <div className="chart-row-item" />
                        {months.map((date, index) => {
                            return <span key={index}>{date.month}</span>
                        })}
                    </div>
                    <div className="chart-row chart-lines" style={gridStyle}>
                        <span />
                        {months.map((date, index) => {
                            return <span key={index} />
                        })}
                    </div>
                    {courseCalendar.courses.map((course, index) => {
                        const startDate = FormatDateToDateMonString(course.startDate);
                        const endDate = FormatDateToDateMonString(course.endDate);
                        const startDateJS = new Date(course.startDate);
                        const endDateJS = new Date(course.endDate);
                        const daysFromMonthStart = startDateJS.getDate();
                        const daysFromMonthEndStart = endDateJS.getDate();
                        const lastDayOfStartMonth = new Date(startDateJS.getFullYear(), startDateJS.getMonth() + 1, 0).getDate();
                        const monthDiff = MonthDiff(startDateJS, endDateJS);
                        const fullMonthDiff = MonthDiff(startDateJS, endDateJS, true);
                        const calendarStartDateJS = new Date(courseCalendar.start);
                        const startMonthDiff = MonthDiff(calendarStartDateJS, startDateJS);
                        let monthWidthAdjustment = (months.length - 1) * borderWidth;
                        const monthWidthUnit = (Math.max(minMonthWidthTotal + courseNameWidth, fullWidth) -
                            courseNameWidth - borderWidth - monthWidthAdjustment) / months.length;
                        const dayWidthUnit = monthWidthUnit / monthDays;
                        const ganttLeft = (startMonthDiff * monthWidthUnit) + (startMonthDiff * borderWidth) +
                            ((daysFromMonthStart - 1) / lastDayOfStartMonth * monthDays * dayWidthUnit) + courseNameWidth;
                        const ganttWidth = (fullMonthDiff * monthWidthUnit) + (monthDiff * borderWidth) +
                            (daysFromMonthEndStart * dayWidthUnit) + ((lastDayOfStartMonth - daysFromMonthStart + 1) / lastDayOfStartMonth * monthDays * dayWidthUnit);
                        let labelLeft = ganttLeft;
                        const ganttBarStyle = {
                            width: ganttWidth + 'px',
                            left: ganttLeft + 'px',
                        }
                        if (course.parent !== null) {
                            ganttBarStyle.borderLeft = 'none';
                            labelLeft += 15;
                        } else {
                            labelLeft += 30;
                        }
                        const ganttLabelStyle = {
                            left: labelLeft + 'px'
                        }
                        return (
                            <div className="chart-row" key={index}>
                                <div className="chart-row-item">
                                    <a href ={process.env.REACT_APP_EXTERNAL_PATH + "/courses/" + course.URL}>
                                        {course.courseName}
                                    </a>
                                </div>
                                <div className={"gantt-bar-container"}>
                                    <div className={"gantt-bar"} style={ganttBarStyle} />
                                    <div className={"gantt-label"} style={ganttLabelStyle}>{startDate} - {endDate}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        );
    }
}

const GetMonths = (startDate, endDate) => {
    const sD = new Date(startDate);
    const eD = new Date(endDate);
    let dateArray = [];
    let monthStartDate = new Date(sD.setDate(1));
    const monthEndDate = new Date(eD.setDate(28));
    do {
        const numberDaysMonth = monthStartDate.getMonth() === 12 ? 1 : monthStartDate.getMonth() + 1;
        const date = {
            year: new Date(monthStartDate).toLocaleDateString('en-AU', { year: 'numeric' }),
            month: new Date(monthStartDate).toLocaleDateString('en-AU', { month: 'short' }),
            numberDays: new Date(monthStartDate.getFullYear(), numberDaysMonth, 0).getDate()
        }
        dateArray.push(date);
        monthStartDate = new Date(monthStartDate.setMonth(monthStartDate.getMonth() + 1))
    } while (monthStartDate <= monthEndDate);
    return dateArray;
}