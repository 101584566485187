import React, {useEffect, useMemo, useState} from 'react';
import Metadata, {MetadataObj} from "../components/Metadata";
import {FetchData} from "../Helpers";
import {useParams} from "react-router-dom";
import Loader from "../components/Loader";
import NotFound from "./NotFound";
import Markdown from "../components/Markdown";

const ThankYou = () => {
    const [error, setError] = useState(null);
    const [isThankYouPageLoaded, setIsThankYouPageLoaded] = useState(false);
    const [thankYouData, setThankYouData] = useState([]);
    const [notFound, setNotFound] = useState(false);
    let metadata = useMemo(() => {
        let mD = MetadataObj();
        mD.PageTitle = 'Thank you';
        return mD;
    }, []);
    let thankYouURL = process.env.REACT_APP_CMS_PATH + '/api-integration/invoicesuccess';
    const { token } = useParams();
    useEffect(() => {
        FetchData(thankYouURL, 'GET', token)
            .then(
                (result) => {
                    if (result.statusCode !== undefined && result.statusCode !== 200) {
                        setNotFound(true);
                    } else {
                        setThankYouData(result.message);
                    }
                    setIsThankYouPageLoaded(true);
                },
                (error) => {
                    if (error.status === 404 || error.status === 500) {
                        setNotFound(true);
                    } else {
                        setError(error);
                    }
                    setIsThankYouPageLoaded(true);
                }
            )
    }, [thankYouURL, token]);
    if (error) {
        return <div className={"error"}>Error: {error.message}</div>;
    } else if (!isThankYouPageLoaded) {
        return <Loader />;
    } else if (notFound) {
        return <NotFound />;
    } else {
        return (
            <>
                <Metadata metadata={metadata} />
                <div className="main">
                    <Markdown className={"payment-success container"} text={thankYouData} />
                </div>
            </>
        );
    }
}

export default ThankYou;