import {FetchData} from "../Helpers";

const coachingRegistrationURL = '/api-integration/coachingregistration';
const contactFormURL = '/api-integration/contactus';
const newsletterSubscriptionURL = '/api-integration/newsletter';
const preregistrationURL = '/api-integration/preregistration';
const referralURL = '/api-integration/referral';
const updateDetailsURL = '/api-integration/updatedetails';
export const API = {
    submitCoachingRegistrationData: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + coachingRegistrationURL, 'POST', null, formDataObj);
    },
    submitContactForm: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + contactFormURL, 'POST', null, formDataObj);
    },
    submitNewsletterSubscriptionData: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + newsletterSubscriptionURL, 'POST', null, formDataObj);
    },
    submitPreregistrationData: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + preregistrationURL, 'POST', null, formDataObj);
    },
    submitReferralForm: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + referralURL, 'POST', null, formDataObj);
    },
    submitUpdateDetailsForm: async (formDataObj) => {
        return await FetchData(process.env.REACT_APP_CMS_PATH + updateDetailsURL, 'POST', null, formDataObj);
    }
}