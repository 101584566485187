import React from 'react';
import Markdown from "./Markdown";

const TextBlock = (props) => {
    let subHeader = props.subHeader !== undefined && props.subHeader !== null && props.subHeader;
    return (
        <div className={"text-block-container"}>
            <h5>{subHeader}</h5>
            <div className={"text-block"}>
                <Markdown renderers={{paragraph: 'span'}} text={props.text} />
            </div>
        </div>
    );
}

export default TextBlock;