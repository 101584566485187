import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FetchData} from "../Helpers";
import Cart from "./Cart";
import Button from "react-bootstrap/Button";

export const Mdl = (props) => {
    const mdl = {...props.action}
    const data = {...props.data}
    let cartBody = 'Cart is empty';
    let total = '';
    const [forceUpdate, setForceUpdate] = useState(Date.now());
    if (data.cartData.items !== undefined && data.cartData.items.length) {
        cartBody = data.cartData.items.map((item) => {
            return Cart.CartItem(item, props.count, data, setForceUpdate)
        });
        total = data.cartData.total;
    }
    return (
        <Modal show={mdl.showModal} onHide={() => mdl.setShowModal(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'none'}}>{forceUpdate}</div>
                {cartBody}
                {total !== '' &&
                    <>
                        <div className={"cart-total"}>
                            Total: &#36;{total}
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {data.cartData.items !== undefined && data.cartData.items.length > 0 &&
                <Button className="btn btn-primary" href={'/checkout'} role={"link"}>
                    Checkout
                </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export const LaunchModal = async (e, modalClick, cartData) => {
    e.preventDefault();
    e.stopPropagation();
    const cart = Cart.GetCart();
    if (cart !== null) {
        await FetchData(process.env.REACT_APP_CMS_PATH + "/api-integration/cart/" + cart, 'GET')
            .then(
                (result) => {
                    if (result.items.length !== undefined) {
                        cartData.setCartData(result);
                    } else {
                        cartData.setCartData({});
                    }
                    modalClick.setShowModal(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    } else {
        cartData.setCartData({});
        modalClick.setShowModal(true);
    }
}
