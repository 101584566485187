import React, {useContext, useState} from 'react';
import Button from "react-bootstrap/Button";
import {AlertMissingDetails, DeleteUTMData, FetchData, OpenPage} from "../Helpers";
import { useAlert } from 'react-alert'
import Loader from "./Loader";
import CartContext from "../contexts/CartContext";
import Cart from "./Cart";
import {Redirect} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const InvoiceButton = (props) => {
    const alert = useAlert();
    const [count, updateCount] = useContext(CartContext);
    const [discountCheck, setDiscountCheck] = useState(false);

    const confirmPO = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.formData.purchaseOrder !== "") {
            GenerateInvoice(props, alert, {count, updateCount}, {discountCheck, setDiscountCheck});
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='confirm-invoice-alert alert-popup error'>
                            <div className={"confirm-invoice-alert-header"}>
                                <h1>Missing Purchase Order number</h1>
                                <i className={"fa fa-exclamation-circle"} />
                            </div>
                            <div className={"confirm-invoice-alert-body"}>You have not entered a Purchase Order (PO) number.
                                If your accounts department requires a purchase order to be included on invoices,
                                please ensure this is entered.</div>
                            <div className={"confirm-invoice-alert-buttons"}>
                                <button className={"btn btn-primary-inverse"} onClick={() => {onClose()}}>
                                    Enter PO number
                                </button>
                                <button className={"btn btn-primary-inverse"} onClick={() => {
                                    onClose();
                                    GenerateInvoice(props, alert, {count, updateCount},
                                        {discountCheck, setDiscountCheck});
                                }}>Submit anyway</button>
                            </div>
                        </div>
                    );
                }
            });
        }
    }


    if (props.loadingInvoice.loadingInvoice) {
        return <Loader size={62}/>;
    } else if (discountCheck) {
        return (
            <Redirect
                to={{
                    pathname: "/discount-success",
                    state: {
                        discount: props.discount.discount,
                        formData: props.formData,
                        chaCart: props.chaCart
                    }
                }}
            />
        )
    } else if (!props.disabled && !props.loadingCheckout.loadingCheckout &&props.formData.company !== '') {
        return (
            <Button className={"btn btn-primary"}
                    onClick={(e) => confirmPO(e)}>Pay by
                invoice</Button>
        );
    } else {
        return (
            <Button className={"btn btn-primary disabled"} onClick={() => AlertMissingDetails(alert)}>Pay by invoice</Button>
        );
    }
}

const GenerateInvoice = async (props, alert, count, discountCheck) => {
    if (props.discount.discount.cha && parseInt(props.discount.discount.discount) === 100) {
        discountCheck.setDiscountCheck(true);
    } else {
        props.loadingInvoice.setLoadingInvoice(true);
        const result = await FetchData(process.env.REACT_APP_CMS_PATH + '/api-integration/cart/generateinvoice/'
            + props.chaCart, 'POST', null, props.formData);
        if (result.success) {
            Cart.EmptyCart(count.updateCount);
            DeleteUTMData();
            OpenPage('thank-you', false);
        } else {
            props.loadingInvoice.setLoadingInvoice(false);
            alert.error(result.error, {header: 'Error:'});
        }
    }
}

export default InvoiceButton;